import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "tradingTime/"

const tradeTime = {
    //校验当前时间是否开盘
    openingCheck(data) {
        let url = appUrl.getBaseUrl() + serviceName + 'openingCheck'
        return http.post(url,data)
    },
    //获取开盘配置有效期
    getValidityEnable(data) {
        let url = appUrl.getBaseUrl() + serviceName + 'getValidityEnable'
        return http.post(url,data)
    },
    //获取开盘配置
    getTradingOn(data) {
        let url = appUrl.getBaseUrl() + serviceName + 'getTradingOn'
        return http.post(url,data)
    }
}
export default tradeTime;
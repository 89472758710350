<template>
    <div class="market-table">
        <el-table
            :data="list"
            :height="setHeight"
            :max-height="setHeight"
            border
            ref="table"
            style="width: 100%"
            @row-contextmenu = "rightClick"
            >
            <!-- <el-table-column
            v-if="selectList.length > 0"
            prop="symbol"
            fixed
            label="交易品种"
            width="120"
            key="1"
            :resizable="false"
            :sortable="true"
            :sort-method="sortSymbol"
            >
            <template slot-scope="scope">
                <div class="symbol-name">{{ scope.row.symbol }}</div>
            </template>
            </el-table-column> -->
            <el-table-column
            prop="symbol"
            :label="$t('history.variety')"
            width="110"
            key="2"
            :resizable="false"
            :sortable="true"
            :sort-method="sortSymbol"
            >
            <template slot-scope="scope">
                <div class="symbol-name">{{ scope.row.symbol }}</div>
            </template>
            </el-table-column>
            <el-table-column
            prop="bid"
            :label="$t('quotation.sellingPrice')"
            width="110"
            key="3"
            :resizable="false"
            :sortable="true"
            :sort-method="(a,b)=>{return a.bid - b.bid}"
            >
            <template slot-scope="scope">
                <div :style="{'color':scope.row.changeBid <= 0 ? '#2568FF' : '#F42147'}">
                    {{ scope.row.bid }}
                </div>
            </template>
            </el-table-column>
            <el-table-column
            prop="ask"
            :label="$t('quotation.purchasePrice')"
            :width="widths['2']"
            min-width = "120"
            key="4"
            :resizable="false"
            :sortable="true"
            :sort-method="(a,b)=>{return a.ask - b.ask}">
            <template slot-scope="scope">
                <div :style="{'color':scope.row.changeAsk >= 0 ? '#2568FF' : '#F42147'}">
                    {{ scope.row.ask }}
                </div>
            </template>
            </el-table-column>
            <el-table-column
            prop="hprice"
            :label="$t('quotation.highestPrice')"
            :resizable="false"
            key="5"
            v-if="selectList.indexOf(3) > -1"
            :width="widths['3']"
            min-width = "130"
            :sortable="true"
            :sort-method="(a,b)=>{return a.hprice - b.hprice}"
            >
            </el-table-column>
            <el-table-column
            prop="spread"
            :label="$t('quotation.spread')"
            :resizable="false"
            key="6"
            :width="widths['4']"
            min-width = "115"
            v-if="selectList.indexOf(4) > -1"
            :sortable="true"
            :sort-method="(a,b)=>{return a.spread - b.spread}"
            >
            </el-table-column>
            <el-table-column
            prop="lprice"
            :label="$t('quotation.lowestPrice')"
            :width="widths['5']"
            min-width = "130"
            key="7"
            :resizable="false"
            v-if="selectList.indexOf(5) > -1"
            :sortable="true"
            :sort-method="(a,b)=>{return a.lprice - b.lprice}"
            >
            </el-table-column>
            <el-table-column
            prop="last_traded_price"
            :label="$t('quotation.finalPrice')"
            :width="widths['6']"
            min-width = "136"
            :resizable="false"
            key="8"
            v-if="selectList.indexOf(6) > -1"
            :sortable="true"
            :sort-method="(a,b)=>{return a.last_traded_price - b.last_traded_price}"
            >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'MarketTable',
    data(){
        return {
            widths:{
                '2':'120',
                '3':'130',
                '4':'115',
                '5':'130',
                '6':'136'
            }
        }
    },
    props: {
        list: {
            type: Array,
            default: ()=>[]
        },
        firstLoading:{
            type:Boolean,
            default:true
        },
        selectList:{
            type: Array,
            default: ()=>[]
        },
        setHeight:{
            type: Number,
            default: 550  
        }
    },
    watch:{
        selectList: {
            handler(val) {
                let formatObj = {
                    '2':'120',
                    '3':'130',
                    '4':'115',
                    '5':'130',
                    '6':'136'
                }
                //初始化掉整个数组
                for(let x in formatObj) {
                    this.$set(this.widths,x,formatObj[x]);
                }
                if(val.length == 0) {
                    this.$set(this.widths,'2',"auto");
                }else {
                    let arrs = JSON.parse(JSON.stringify(val));
                    arrs = arrs.sort();
                    let lastItem = arrs[arrs.length-1] + '';
                    this.$set(this.widths,lastItem,"auto");
                }
            },
            immediate: true
        }
    },
    methods:{
        sortSymbol(a,b) {
            let getFirstA = a.symbol.substring(0,1).toUpperCase();
            let getFirstB = b.symbol.substring(0,1).toUpperCase();
            if(getFirstA < getFirstB) {
                return -1
            }else {
                return 1
            }
        },
        initTable() {
            let that = this;
            this.$nextTick(()=>{
                if(!that.firstLoading) {
                    that.$refs.table.doLayout();
                }   
            })
        },
        rightClick(row, column, event) {
            let that = this;
            this.$contextmenu({
                items: [
                    {
                        label: this.$t('index.popup.newOrder'),
                        onClick: () => {
                            that.$emit('tradeShow', row);
                        }
                    },
                    {
                        label: this.$t('tab.charts'),
                        onClick: () => {
                            that.$emit('symbolClick',row);
                        }
                    },
                    {
                        label: this.$t('index.popup.detailed'),
                        onClick: () => {
                            that.$emit('symbolInfo',row);
                        }
                    },
                ],
                event,
                customClass: "symbol_menu",
                zIndex: 3,
                minWidth: 88
            });
            return false;
        }
    }
}
</script>

<style>
.symbol_menu  {
    padding: 0 0 !important;
}
.symbol_menu .menu_item {
    line-height: 39px !important;
}
</style>
<style lang="scss" scoped>
.symbol-name {
    font-weight: 600;
    color: #000000;
}
::v-deep .el-table__header-wrapper thead tr th:first-child {
    padding-left: 6px !important;
}
::v-deep  .el-table__body tbody tr td:first-child {
    padding-left: 6px !important;
}
::v-deep .el-table th.el-table__cell>.cell {
    display: flex;
    align-items: center;
}
::v-deep .el-table .cell{
  height:23px!important;
}
/* 给固定列设置下边距 */
::v-deep .el-table .el-table__fixed {    
        height: auto !important;
        bottom: 8px !important; /* 具体值是多少根据你横向滚动条的高度进行设置 */
    }
    /* 去掉固定列下方的横线 */
    ::v-deep .el-table__fixed::before, .el-table__fixed-right::before {     
        display:none;
    }
    ::v-deep .el-table__fixed .el-table__fixed-body-wrapper .el-table__body {
        padding-bottom: 8px !important;
    }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    height: 8px; 
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #CFD5E4;
    border-radius: 8px;
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }
  ::v-deep .el-table--border, .el-table--group {
    border:none
  }
  ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
    background-color: #FFFFFF !important;
  }
  ::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: none;
  }
  ::v-deep .el-table__header .cell {
    color: #8996B4;
    font-size: 13px;
  }
  ::v-deep .el-table__body .cell {
    color:#000000
  }
  ::v-deep .hover-row td{
    background-color: #F2F8FF !important;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #F2F8FF !important;
  }
  ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  } 
</style>
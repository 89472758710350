<template>
	<div class="filterDialog">
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" width="640px" :show-close="false">
			<div class="top-model">
				<span>{{$t('history.filter')}}</span>
				<img @dragstart.prevent @click="closeDialog()" class="close-img" src="@/static/image/closeWhite.png" />
			</div>
			<div class="body-model">
				<div class="input-back">
					<div class="form-name">{{$t('history.variety')}}</div>
					<el-select class="form-value" v-model="symbolValue" :placeholder="$t('history.choose')">
						<div style="display: flex;align-items: center;margin: 0 16px;border-bottom: 1px solid #CFD5E4;">
							<img @dragstart.prevent style="width: 16px;height: 16px;"  src="@/static/image/search.png"/>
							<input @input="handleSearch" style="height: 40px;width: 100%;border: 0px;outline: none;padding: 0 9px;" :placeholder="$t('history.placeholder')" v-model="inputValue"/>
						</div>
					    <el-option
					      v-for="item in filteredBreedList"
					      :label="item.symbol"
					      :value="item.symbol"
						  :key="item.symbol">
					    </el-option>
					  </el-select>
				</div>
				<div class="input-back" style="margin-top: 12px;">
					<div class="form-name">{{$t('quotation.time')}}</div>
					<el-select class="form-value"  v-model="timeValue" :placeholder="$t('history.choose')">
					   <el-option
					      v-for="(item,index) in timeList"
					      :label="item.label"
					      :value="item.value"
						  :key="index">
					    </el-option>
					</el-select>
					<div v-if="timeValue != $t('history.customize')" @click="openTimeCustomize()" class="customize">{{$t('history.customize')}}</div>
					<div v-if="timeValue == $t('history.customize')" @click="closeTimeCustomize()" class="customize cancel">{{$t('index.popup.cancel')}}</div>
				</div>
				<div v-if="timeValue == $t('history.customize')" class="input-back" style="margin-top: 12px;">
					<div class="form-name"></div>
					<el-date-picker
					  class="form-value"
					  v-model="customizeTimeValue"
					  :picker-options="pickerOptions"
					  type="daterange"
					  :range-separator="$t('order.to')"
					  :start-placeholder="$t('history.startDate')"
					  :end-placeholder="$t('order.endDate')"
					  value-format="timestamp">
					</el-date-picker>
				</div>
				<div class="flex" style="justify-content: flex-end;">
					<div @click="submitFilter()" class="login-btn" :class="(timeValue != $t('history.customize') || (timeValue == $t('history.customize') && customizeTimeValue && customizeTimeValue.length > 1)) ? '' : 'err-btn' ">{{$t('setting.confirm')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { 
		lastWeekTime,
		lastMonthTime,
		beforeThreeMonth,
		dateFomat} from "@/utils/utils"
	export default{
		data() {
			return{
				dialogVisible:false,
				inputValue:'',
				breedList: [], // 原始数据
				filteredBreedList: [], // 过滤后的数据
				symbolValue:this.$t('history.allVariety'),
				timeValue:0,
				customizeTimeValue:'',
				timeList:[
					{
						label:this.$t('history.default'),
						value:0
					},
					{
						label:this.$t('history.today'),
						value:1
					},
					{
						label:this.$t('history.week'),
						value:2
					},
					{
						label:this.$t('history.month'),
						value:4
					},
					{
						label:this.$t('history.threeMonths'),
						value:5
					},
					{
						label:this.$t('history.sixMonths'),
						value:6
					},
					{
						label:this.$t('history.year'),
						value:7
					}
				], //时间下拉数组
				pickerOptions: {   //限制自定义日期选择不能超过今天
					disabledDate(time) {
					  return time.getTime() > Date.now();
					},
				},
			}
		},
		mounted() {
			if(this.$global.varietyList && this.$global.varietyList.length > 0){
				this.breedList = JSON.parse(JSON.stringify(this.$global.varietyList));
				this.filteredBreedList = JSON.parse(JSON.stringify(this.$global.varietyList));
				this.breedList.unshift({
					symbol:this.$t('history.allVariety')
				});
				this.filteredBreedList.unshift({
					symbol:this.$t('history.allVariety')
				});
			}else{
				this.getBreedList();
			}
		},
		methods:{
			//提交筛选到父组件
			submitFilter(){
				if(this.timeValue != this.$t('history.customize') || (this.timeValue == this.$t('history.customize') && this.customizeTimeValue && this.customizeTimeValue.length > 1)){
					let filterDate = {}
					if(this.timeValue != this.$t('history.customize')){
						filterDate = this.chooseTime(this.timeValue)
					}else{
						if(this.customizeTimeValue && this.customizeTimeValue.length > 1){
							filterDate.closeTimeStart = this.customizeTimeValue[0]/1000
							filterDate.closeTimeSEnd = this.customizeTimeValue[1]/1000
						}
					}
					if(this.symbolValue != this.$t('history.allVariety')){
						filterDate.symbolValue = this.symbolValue
					}else{
						filterDate.symbolValue = ''
					}
					this.$emit('changeFilter',filterDate)
					this.closeDialog()
				}else{
					return
				}
			},
			//时间选择
			chooseTime(index) {
				let _that = this;
				let filterParams = {
					closeTimeStart: 0,
					closeTimeSEnd: 0
				}
				switch (index) {
					case 1:
						//今天
						filterParams.closeTimeStart = parseInt(new Date(new Date().setHours(0, 0, 0, 0)).getTime() /
							1000); //当天0点0分0秒的时间戳
						filterParams.closeTimeSEnd = parseInt(new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 *
							60 * 1000 - 1).getTime() / 1000); //获取当天23:59:59的时间
						break;
					case 2:
						//本周
						filterParams.closeTimeStart = lastWeekTime()[0]
						filterParams.closeTimeSEnd = lastWeekTime()[1]
						break;
					case 4:
						//本月
						filterParams.closeTimeStart = lastMonthTime()[0]
						filterParams.closeTimeSEnd = lastMonthTime()[1]
						break;
					case 5:
						//最近三个月
						filterParams.closeTimeStart = beforeThreeMonth()[0]
						filterParams.closeTimeSEnd = beforeThreeMonth()[1]
						break;
					case 6:
						//最近六个月
						filterParams.closeTimeStart = beforeThreeMonth(6)[0]
						filterParams.closeTimeSEnd = beforeThreeMonth(6)[1]
						break;
					case 7:
						//最近一年
						filterParams.closeTimeStart = beforeThreeMonth(12)[0]
						filterParams.closeTimeSEnd = beforeThreeMonth(12)[1]
						break;
					default:
						//默认
						filterParams.closeTimeStart =0
						filterParams.closeTimeSEnd = 0
				}
				
				return filterParams;
			},
			//打开筛选弹窗
			openDialog(){
				this.dialogVisible = true
			},
			//关闭筛选弹窗
			closeDialog(){
				this.dialogVisible = false
			},
			//查询交易品种列表
			getBreedList(){
				this.$api.variety.getVarietyList().then(res =>{
					if (res && res.code == 200) {
						this.breedList = res.data;
						this.filteredBreedList = res.data;
						this.breedList.unshift({
							symbol:this.$t('history.allVariety')
						});
					}
				})
			},
			//交易品种模糊搜索
			handleSearch() {
			  const searchKeyword = this.inputValue.trim().toLowerCase(); // 获取搜索关键词并转换为小写
			  if (searchKeyword === '') {
			    // 如果搜索关键词为空，则显示全部内容
			    this.filteredBreedList = this.breedList;
			  } else {
			    // 根据搜索关键词过滤列表数据（不区分大小写）
			    this.filteredBreedList = this.breedList.filter(item => {
			      const symbol = item.symbol.toLowerCase(); // 将数据中的内容转换为小写
			      return symbol.includes(searchKeyword);
			    });
				this.filteredBreedList.unshift({
					symbol:this.$t('history.allVariety')
				});
			  }
			},
			//时间筛选切换成自定义
			openTimeCustomize(){
				this.timeValue = this.$t('history.customize')
			},
			//关闭时间筛选自定义
			closeTimeCustomize(){
				this.timeValue = 0
			}
		}
	}
</script>

<style scoped>
	 .el-select-dropdown__item{
		height: 40px;
		line-height: 40px;
		border-bottom: 1px dashed #CFD5E4;
		width: 424px;
		padding: 0;
		margin: auto;
	}
</style>
<style lang="scss" scoped>
	.filterDialog{
		::v-deep .el-dialog{
			border-radius: 10px;
		}
		
		::v-deep .el-dialog__header{
			display: none;
		}
		
		::v-deep .el-dialog__body{
			padding: 0;
			color: #000;
		}
		
		.top-model{
			height: 50px;
			background-color: #2568FF;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 16px;
			color: #fff;
			font-size: 16px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.close-img{
			width: 12px;
			height: 12px;
			cursor: pointer;
		}
		
		.body-model{
			background-color: #fff;
			border-radius: 0px 0px 8px 8px;
			padding: 24px 16px 18px;
		}
		
		.input-back{
			display: flex;
			align-items: center;
			height: 40px;
			
			.form-name{
				width: 70px;
				font-size: 14px;
				text-align: left;
			}
			
			::v-deep .el-input__inner{
				border-radius: 10px;
				border-color:#ECEFFB;
			}
			
			.form-value{
				width: 458px;
				height: 40px;
			}
			
			.customize{
				margin-left: 12px;
				font-size: 14px;
				color: #2568FF;
				cursor: pointer;
			}
			
			.cancel{
				color: #DF3E52;
			}
		}
		
		.login-btn{
			margin-top: 23px;
			width: 138px;
			height: 44px;
			background-color: #2568FF;
			border-radius: 10px;
			font-size: 14px;
			color: #fff;
			line-height: 44px;
			text-align: center;
			cursor: pointer;
		}
		
		.err-btn{
			background-color: #CFD5E4;
		}
	}
</style>
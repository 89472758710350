//引入相关api管理模块
import application from "@/service/api/application.js";
import variety from "@/service/api/variety.js";
import historical from "@/service/api/historical";
import order from "@/service/api/order";
import user from "@/service/api/user";
import orderHistory from "@/service/api/orderHistory";
import orderHang from "@/service/api/orderHang";
import userWallet from "@/service/api/userWallet";
import tradeTime from "@/service/api/tradeTime.js";

//进行统一导出
export default {
    application,
    variety,
    historical,
	order,
    user,
	orderHistory,
	orderHang,
	userWallet,
    tradeTime
}
<template>
  <div class="optionalList">
	<div class="optionalModel">
		<div v-for="item in 60">BTC{{item}}</div>
	</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.optionalList {
	
	.optionalModel {
	   padding-bottom: 40px;
	}
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'normal',//主题
    defaultService: null,//默认服务商
    userInfo: null,//用户信息
    symbols: [],//行情信息
    orderArr: [],//持有订单
    upDataMsg: "",//用户数据及订单更新
    reStartApp: "",//重新渲染应用
    upCollectList: [], //用户收藏品类更新
    upOrderList: "",//upOrderList
    language: "zh-Hans",//语言
  },
  getters: {
  },
  mutations: {
    CHANGE_THEME(state, theme) {
      state.theme = theme
    },
    //更新用户信息
    upUserInfo(state, data) {
      state.userInfo = data;
    },
    //更新用户收藏的品类
    setCollectList(state, data) {
      state.upCollectList = data;
    },
    //更新行情信息
    upSymbols(state, arr) {
      state.symbols = arr;
    },
    //设置默认服务商
    setDefaultService(state, data) {
      state.defaultService = data;
    },
    //更新持有订单
    upOrderArr(state, arr) {
      state.orderArr = arr;
    },
    //更新消息
    setUpDataMsg(state, msg) {
      state.upDataMsg = msg;
    },
    //重新渲染应用
    setReStartApp(state, data) {
      state.reStartApp = data;
    },
    //更新订单
    setUpOrderList(state, data) {
      state.upOrderList = data;
    },
    //设置语言
    setLanguage(state, data) {
      state.language = data;
    },
  },
  actions: {
    changeTheme({
      commit
    }, theme) {
      commit('CHANGE_THEME', theme)
    }
  },
  modules: {
  }
})

import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "user/"

const user = {
    //登录
    login(urls,data={}) {
        let url = appUrl.getBaseUrlByUrls(urls) + serviceName + "login"
        return http.post(url, data);
    },
    //登录
    register(urls,data={}) {
        let url = appUrl.getBaseUrlByUrls(urls) + serviceName + "register"
        return http.post(url, data);
    },
    //获取用户信息
    getUserInfo() {
        let url = appUrl.getBaseUrl() + serviceName + "getUserInfo"
        return http.get(url);
    },
    //获取用户信息
    getUserInfoByUrl(urls) {
        let url = appUrl.getBaseUrlByUrls(urls) + serviceName + "getUserInfo"
        return http.get(url);
    },
    //获取服务商信息
    getAccountInfo(urls=[],data={}) {
        let url = appUrl.getBaseUrlByUrls(urls) + serviceName + "getAccountInfo"
        return http.post(url,data);
    },
    //修改密码
    changePassWord(data={}) {
        let url =  appUrl.getBaseUrl() + serviceName + "changePassWord"
        return http.post(url,data);
    },
}

export default user;
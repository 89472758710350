import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/storage'
import axios from 'axios'
Vue.prototype.axios = axios
import api from "./service/api.js";
import appMixin from './utils/appMixin.js'
import global from './utils/global.js'
import appTaskUtil from './utils/appTaskUtils.js' //引入websocket文件
// import './utils/rem';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Contextmenu from "vue-contextmenujs"
//将封装的接口挂载到vue原型上
Vue.prototype.$api = api;
Vue.prototype.$storage = storage;
Vue.prototype.$store = store
Vue.prototype.$message = ElementUI.Message
Vue.prototype.$debounce = global.debounce // 防止多次点击事件
Vue.config.productionTip = false
import i18n from "./locale"

//开启任务
let appTask = new appTaskUtil()
//挂载到全局
Vue.prototype.$appTask = appTask

import db from './db/db.js'
/* 其它代码 */
Vue.prototype.$db = db
//全局变量
Vue.prototype.$global = {
  varietyList: [],//品类列表
  symbols: [],//品类行情
  userInfo:null
}
Vue.mixin(appMixin)
Vue.use(Contextmenu)
Vue.use(ElementUI)
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"

let serviceName = "order/"

const order = {
    //新增订单/订单开仓
    openOrder(data) {
        let url = appUrl.getBaseUrl() + serviceName + "openOrder"
        return http.post(url,data);
    },
	
	//关闭订单/订单平仓
	closeOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "closeOrder"
	    return http.post(url,data);
	},
	
	//订单列表总
	listOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listOrder"
	    return http.post(url,data);
	},
	
	//订单列表
	listFrontPage(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listFrontPage"
	    return http.post(url,data);
	},
	
	//订单详情
	detailFront(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "detailFront"
	    return http.post(url,data);
	},
	
	//持仓订单修改
	updatePositionOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "updatePositionOrder"
	    return http.post(url,data);
	},
	
	//以市价关闭订单
	closeOrderByMarketPrice(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "closeOrderByMarketPrice"
	    return http.post(url,data);
	}
}

export default order;

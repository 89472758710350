<template>
    <div class="account-box">
        <div v-if="accountType == 0">
            <div class="service-info">
                <h3>{{ selectAccount.appService.name }}</h3>
                <span>{{ selectAccount.appService.des }}</span>
            </div>
            <div class="account-info">
                <div class="account-info-item">
                    <span class="item-label">{{ $t('login.login') }}</span>
                    <span class="item-value">{{ selectAccount.userAccount }}</span>
                </div>
                <div class="account-info-item">
                    <span class="item-label">{{ $t('login.mail') }}</span>
                    <span class="item-value">{{ selectAccount.userName }}</span>
                </div>
                <div class="account-info-item" v-if="userInfo && selectAccount.id == userInfo.id">
                    <span class="item-label">{{ $t('me.account.changePwd') }}</span>
                    <div class="item-value">
                        <el-button class="btn-samll" type="primary" plain @click="accountType = 1">{{ $t('me.changeMaster')
                        }}</el-button>
                        <el-button class="btn-samll" type="primary" plain @click="accountType = 2">{{
                            $t('me.changeReadonly') }}</el-button>
                    </div>
                </div>
            </div>
            <div class="action-bar" v-if="userInfo && selectAccount.id == userInfo.id">
                <el-button type="danger" @click="logout()">{{ $t('me.logout') }}</el-button>
            </div>
            <div class="action-bar" v-else>
                <el-button type="danger" :isLoading="isLoading" @click="delAccount()">{{ $t('me.delete') }}</el-button>
                <el-button type="primary" :isLoading="isLoading" @click="login()">{{ $t('order.loginAccount') }}</el-button>
            </div>
        </div>
        <div v-if="accountType == 1">
            <div class="login-box-header">
                <h3 class="form-title">{{ $t('me.changeMaster') }}</h3>
                <span class="link-back" @click="accountType = 0">{{ $t('order.goBack') }}</span>
            </div>
            <el-form :model="changeMainParam" ref="changeMainParam">
                <el-form-item prop="oldPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.currently') }}</div>
                        <el-input class="form-value" clearable :placeholder="$t('setting.masterPassword')"
                            v-model="changeMainParam.oldPassword"></el-input>
                    </div>

                </el-form-item>
                <el-form-item prop="newPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.new') }}</div>
                        <el-input class="form-value" clearable
                            :placeholder="$t('setting.pleasEnter') + $t('setting.masterPassword')"
                            v-model="changeMainParam.newPassword"></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="newPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.confirm') }}</div>
                        <el-input class="form-value" clearable
                            :placeholder="$t('setting.confirmNew') + $t('setting.masterPassword')"
                            v-model="changeMainParam.newPassword2"></el-input>
                    </div>
                </el-form-item>
                <div class="tip-box"><span>{{ $t('setting.passwordTip') }}</span></div>
                <el-button class="login-btn" :loading="isLoading" @click="upMainPwdFn()">{{ isLoading ? '' :
                    $t('setting.confirm')
                }}</el-button>

            </el-form>
        </div>
        <div v-if="accountType == 2">
            <div class="login-box-header">
                <h3 class="form-title">{{ $t('me.changeReadonly') }}</h3>
                <span class="link-back" @click="accountType = 0">{{ $t('order.goBack') }}</span>
            </div>
            <el-form :model="changeReadParam" ref="changeReadParam">
                <el-form-item prop="oldPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.currently') }}</div>
                        <el-input class="form-value" clearable :placeholder="$t('setting.masterPassword')"
                            v-model="changeReadParam.oldPassword"></el-input>
                    </div>

                </el-form-item>
                <el-form-item prop="newPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.new') }}</div>
                        <el-input class="form-value" clearable
                            :placeholder="$t('setting.pleasEnter') + $t('setting.masterPassword')"
                            v-model="changeReadParam.newPassword"></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="newPassword">
                    <div class="input-back">
                        <div class="form-name">{{ $t('setting.confirm') }}</div>
                        <el-input class="form-value" clearable
                            :placeholder="$t('setting.confirmNew') + $t('setting.masterPassword')"
                            v-model="changeReadParam.newPassword2"></el-input>
                    </div>
                </el-form-item>
                <div class="tip-box"><span>{{ $t('setting.passwordTip') }}</span></div>
                <el-button class="login-btn" :loading="isLoading" @click="upReadPwdFn()">{{ isLoading ? '' :
                    $t('setting.confirm')
                }}</el-button>

            </el-form>
        </div>
    </div>
</template>
<script>
import { delAccount, upUserData } from "@/utils/userUtils"
export default {
    name: 'AccountInfo',
    components: {

    },
    watch: {
    },
    props: {
        selectAccount: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            accountType: 0,
            isLoading: false,
            rules: [],
            changeMainParam: {
                oldPassword: '',
                newPassword: '',
                newPassword2: '',
            },
            readRules: [],
            changeReadParam: {
                oldPassword: '',
                newPassword: '',
                newPassword2: '',
            },
            param: {}
        }
    },
    mounted() {
    },
    methods: {
        //登录
        login() {
            if(this.selectAccount && this.selectAccount.textPwd){
                this.isLoading = true
                let loginParam = {}
                loginParam.userAccount = this.selectAccount.userAccount
                loginParam.password = this.selectAccount.textPwd
                this.$api.user.login(this.selectAccount.appService.url, loginParam).then(res => {
                    this.isLoading = false
                    if (res && res.data) {
                        this.$emit('loginBack', res.data)
                    }
                })
            }else{
                this.$emit('loginErrBack',this.selectAccount )
            }
            
        },
        //退出登录
        logout() {
            this.$confirm(this.$t('order.currentAccount'), this.$t('api.message'), {
                confirmButtonText: this.$t('login.sure'),
                cancelButtonText: this.$t('index.popup.cancel'),
                type: 'warning'
            }).then(() => {
                this.$storage.setCache('userInfo', null)
                this.$store.commit('upUserInfo', null)
                this.$emit('logoutBack')
            }).catch(() => {

            });

        },
        //删除账户
        delAccount() {
            this.$confirm(this.$t('me.deleteAccount'), this.$t('api.message'), {
                confirmButtonText: this.$t('login.sure'),
                cancelButtonText: this.$t('index.popup.cancel'),
                type: 'warning'
            }).then(() => {
                delAccount(this, this.selectAccount, res => {
                    this.$emit('delAccountBack')
                })
            }).catch(() => {

            });

        },
        //修改主密码
        upMainPwdFn() {
            
            if(this.changeMainParam.oldPassword=='' || this.changeMainParam.newPassword=='' || this.changeMainParam.newPassword2==''){
                this.$message.error(this.$t('tip.password-empty'))
                return
            }
            if(this.changeMainParam.newPassword!=this.changeMainParam.newPassword2){
                this.$message.error(this.$t('tip.password-empty'))
                return
            }
            this.param = this.changeMainParam
            this.param.passwordType = 1
            this.upForApi()
        },
        //修改只读密码
        upReadPwdFn() {
            if(this.changeReadParam.oldPassword=='' || this.changeReadParam.newPassword=='' || this.changeReadParam.newPassword2==''){
                this.$message.error(this.$t('tip.password-empty'))
                return
            }
            if(this.changeReadParam.newPassword!=this.changeReadParam.newPassword2){
                this.$message.error(this.$t('tip.password-empty'))
                return
            }
            this.param = this.changeReadParam
            this.param.passwordType = 0
            this.upForApi()
        },
        //提交修改
        upForApi() {
            
            this.$api.user.changePassWord(this.param).then(res => {
                if (res && res.code == 200) {
                    if(this.param.passwordType==1){
                        this.selectAccount.textPwd = this.param.newPassword
                    }else{
                        this.selectAccount.textReadPwd = this.param.newPassword
                    }
                    //更新数据库
                    upUserData(this,this.selectAccount,back=>{
                         this.$emit('upAccountPwdBack')
                    })
                   
                }
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.account-box {
    width: 528px;
    padding: 16px;
}

.service-info {
    text-align: left;
    margin-bottom: 12px;

    h3 {
        font-size: 16px;
        color: #000000;
        margin-bottom: 4px;
    }

    span {
        color: #8996B4;
        font-size: 13px;
    }
}

.account-info {
    .account-info-item {
        flex: 1;
        display: flex;
        height: 44px;
        align-items: center;
        border-bottom: 1px solid #ECEFFB;

        .item-label {
            width: 80px;
            text-align: left;
        }

        .item-value {
            flex: 1;
            text-align: left;
        }
    }

}

.action-bar {
    text-align: right;
    margin-top: 16px;
}

.btn-samll {
    height: 30px;
    padding: 0 12px;
}


.login-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .form-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
    }

    .link-back {
        color: #2568FF;
        cursor: pointer;
    }
}

.input-back {
    display: flex;
    align-items: center;
    height: 40px;

    .form-name {
        width: 70px;
        font-size: 14px;
        text-align: left;
    }

    ::v-deep .el-input__inner {
        border-radius: 10px;
        border-color: #ECEFFB;
    }

    .form-value {
        width: 222px;
        height: 40px;
    }
}

.tip-box {
    margin-bottom: 12px;
    text-align: left;
}

.login-btn {
    padding: 0;
    width: 138px;
    height: 40px;
    background-color: #2568FF;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    float: right;
    border: none
}
</style>
  
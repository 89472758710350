class cache {
	//存储在本地缓存指定的name中
	setCache(name, data) {
		try {
			localStorage.setItem(name, JSON.stringify(data) );
		} catch (e) {
			console.log(e)
		}
	}
 
	//从本地缓存中读取置顶name对应的内容
	getCache(name) {
		let data;
		try {
			data = localStorage.getItem(name);
            data = JSON.parse(data)
		} catch (e) {
			console.log(e);
		}
		return data;
	}

    //从本地缓存中移出指定key
	removeCache(name) {
		try {
			localStorage.removeItem(name);
		} catch (e) {
			//TODO handle the exception
			console.log(e);
		}
	}

    //清除本地数据缓存
	clearCache(){
		try{
			localStorage.clear();
		}catch(e){
			console.log(e)
		}
	}

}

let cacheFunc = new cache();
export default cacheFunc;
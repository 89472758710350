import { render, staticRenderFns } from "./numBox.vue?vue&type=template&id=4a50b46e&scoped=true"
import script from "./numBox.vue?vue&type=script&lang=js"
export * from "./numBox.vue?vue&type=script&lang=js"
import style0 from "./numBox.vue?vue&type=style&index=0&id=4a50b46e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a50b46e",
  null
  
)

export default component.exports
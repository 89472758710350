import {
    mapState
} from 'vuex'
import {dateFomat} from "@/utils/utils.js"
export default {
    data() {
        return {
            themeStyle:''
        }
    },
    computed: {
        ...mapState(['symbols', 'userInfo', 'defaultService']),
    },
    methods: {
		//简单时间格式化
		mixinDateFomat(time) {
            if(!time){
                return ''
            }
			return dateFomat(time)
		},
		//获取点差
		mixinGetSpread(ask,bid,decimalPlaces){
			//点差 =( ask * 10 的 品类小数长度 幂) - ( bid * 10 的 品类小数长度 幂)
			return Math.round(( parseFloat(ask) * Math.pow(10, decimalPlaces)) - (parseFloat(bid) * Math.pow(10, decimalPlaces)))
		},
    }
}
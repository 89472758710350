import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "variety/"

const variety = {
    //获取品类列表
    getVarietyList() {
        let url = appUrl.getBaseUrl() + serviceName + "list"
        return http.get(url);
    }
}
export default variety;
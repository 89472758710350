import axios from 'axios'    //引入axios
import storage from "@/utils/storage"
import store from '@/store';
import { Message } from 'element-ui';
//创建axios实例，请求超时时间为30秒，因为项目中有多个域名，所以对应的也要创建多个axios实例
const instance = axios.create({
  timeout: 30000,
});

//请求和响应拦截可以根据实际项目需求进行编写
// 请求发起前拦截
instance.interceptors.request.use((config) => {
  //这里可以对接口请求头进行操作 如：config.headers['X-Token'] = token
  config.headers["Content-Type"] = 'application/json;charset=utf-8';

  //用户token
  let userInfo = store.state.userInfo
  //先取vuex 取不到再取 cache
  if (!userInfo) {
    userInfo = storage.getCache('userInfo')
  }
  if (userInfo) {
    config.headers['token'] = userInfo.token;
  }

  //获取本地语言设置，默认为英文
  let language = store.state.language ? store.state.language : "zh-Hans"
  //接口语言
  config.headers["Accept-Language"] = language;

  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error)
})

// 响应拦截（请求返回后拦截）
instance.interceptors.response.use(res => {
  if(!res){
    return
  }
  let message = '';
  if (res.status == 200) {
    if (res.data.code == 552) {
      message = res.data.msg
      Message.error(message)

      //消息传到index，业务在那边执行
      store.commit('setReStartApp', new Date().getTime())
      return;
    }
    if (res.data.code == 500) {
      message = res.data.msg
      Message.error(message)
      return;
    } else {
      return res.data
    }
  }

  if (res.status == 400) {
    message = '请求错误';
  }

  if (res.status == 401) {
    message = '未授权，请登录';
  }

  if (res.status == 403) {
    message = '拒绝访问';
  }

  if (res.status == 404) {
    message = `请求地址出错`;
  }

  if (res.status == 408) {
    message = '请求超时';
  }

  if (res.status == 500) {
    message = '服务器内部错误';
  }

  if (res.status == 501) {
    message = '服务未实现';
  }

  if (res.status == 502) {
    message = '网关错误';
  }

  if (res.status == 503) {
    message = '服务不可用';
  }

  if (res.status == 504) {
    message = '网关超时';
  }

  if (res.status == 505) {
    message = 'HTTP版本不受支持';
  }
  //统一处理接口异常
  console.log(message)
  if(!res || !res.data){
    return {data:{},code:999}
  }
  return res.data;
}, error => {
  console.log('catch', error)
})


//按照请求类型对axios进行封装
const http = {
  get(url, data) {
    return instance.get(url, { params: data })
  },
  post(url, data) {
    return instance.post(url, data)
  },
}
export { http }
<template>
  <div id="app" :class="themeClass">
    <KeepAlive>
      <router-view v-if="$route.meta.keepAlive" />
    </KeepAlive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>

import config from './config/config.js'
export default {
  name: 'App',
  components: {

  },
  data() {
    return {

    }
  },
  watch: {
    themeClass() {
      localStorage.theme = this.$store.state.theme
    }
  },
  created() {
    //应用主题
    if (localStorage.theme) {
      this.$store.dispatch('changeTheme', localStorage.theme)
    } else {
      localStorage.theme = this.$store.state.theme
    }

    //获取外部跳转进来的参数
    let query = this.$route.query
    if (query && query.data) {
      this.$router.replace({
        path: '/loading',
        query: query
      })
    } else {
      this.$router.replace('/loading')
    }



  },
  computed: {
    themeClass() {
      return `theme-${this.$store.state.theme}`
    }
  },

  mounted() {

    document.title = config.appSet.appName;
  },
  methods: {

  }
}
</script>
<style lang="scss">
@import "./style/theme_normal.scss";
@import "./style/theme_dark.scss";
@import "./style/common.scss";

.v-modal {
  background: rgba(0, 0, 0, 0.1) !important;
}

.form .el-input__inner {
  font-weight: 600;
  color: #000;
}
</style>

<template>
	<div class="statement-page">
		<el-dialog :close-on-click-modal="true" :visible.sync="dialogVisible" :show-close="false">
			<div class="top-model">
				<span>{{$t('index.popup.welcome')}}:{{appName}}</span>
				<img @dragstart.prevent class="close-img" src="@/assets/img/ico_close.png" @click="closeStatement()" />
			</div>
			<div class="body-model">
				<div class="introduce-text">
					<span style="font-weight: bold;">{{appName}}</span> 
					{{$t('index.popup.introduce')}}
				</div>
				<div class="introduce-text" style="margin-top: 14px;">
					{{$t('index.popup.agree')}}
				</div>
				<div class="introduce-text">
					<a target="_blank" class="blue-text" :href="getHref(0)">{{$t('index.popup.terms')}}</a> 
					，
					<a target="_blank" class="blue-text" :href="getHref(1)">{{$t('me.privacy')}}</a> 
					<span>{{$t('index.popup.and')}}</span>
					<span>{{$t('index.popup.disclaimer')}}</span>
				</div>
				<div @click="acceptFn()" class="accept-btn">
					{{$t('index.popup.accept')}}
				</div>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="true" :visible.sync="dialogVisibleAccount" :show-close="false">
			<div class="top-model">
				<span>{{$t('index.popup.openDemo')}}</span>
				<img @dragstart.prevent class="close-img" src="@/assets/img/ico_close.png" @click="closeAccount()" />
			</div>
			<div class="body-model">
				<div class="introduce-text">
					{{$t('index.popup.policy1')}}
					,
				</div>
				<div class="introduce-text">
					{{$t('index.popup.policy2')}}
				</div>
				<div @click="openAccountDemo()" class="accept-btn" style="margin-top: 48px;">
					{{$t('index.popup.openDemo')}}
				</div>
				<div class="introduce-text2">
					{{$t('index.popup.apply')}}
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/config.js'
	import { getAccounts } from "@/utils/userUtils"
	export default{
		data() {
			return {
				dialogVisible:false,
				dialogVisibleAccount: false,
				appName: config.appSet.appName
			}
		},
		mounted() {
			this.isShowAppTip();
		},
		methods:{
			//关闭应用介绍
			closeStatement(){
				this.dialogVisible = false
			},
			//关闭模拟账户弹窗
			closeAccount(){
				this.dialogVisibleAccount = false
			},
			//接受
			acceptFn() {
				this.$storage.setCache("isShowAppTip", 1)
				this.dialogVisible = false
				this.dialogVisibleAccount = true
			},
			//是否弹窗显示应用介绍
			isShowAppTip() {
				let isShowAppTip = this.$storage.getCache("isShowAppTip")
				if (!isShowAppTip) {
					this.dialogVisible = true
				} else {
					getAccounts(this, res => {
						if(!res){
							this.dialogVisibleAccount = true
						}
					})
				}
			
			},
			//打开网页
			getHref(type){
				if(type==0){
					//条款
					return config.useUrl.termUrl + "?language="+ this.$store.state.language
				}else if(type==1){
					//隐私
					return config.useUrl.agreementUrl + "?language="+ this.$store.state.language
				}
				return ""
			},
			openAccountDemo(){
				this.dialogVisibleAccount = false
				this.$emit('userFn',1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.statement-page{
		::v-deep .el-dialog {
			border-radius: 8px !important;
			box-shadow: 0px 4px 20px 0px rgba(34, 78, 169, 0.3) !important;
			background: #ffffff !important;
			margin-top: 25vh !important;
			width: 420px !important;
		}
		
		::v-deep .el-dialog__header {
			display: none;
		}
		
		
		
		::v-deep .el-dialog__body {
			padding: 0;
			// color: rgba(0,0,0,0.1);
		}
		
		a{text-decoration:none};
		
		.top-model {
			height: 50px;
			background-color: #2568FF;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 16px;
			color: #fff;
			font-size: 16px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.close-img {
			width: 13px;
			height: 13px;
			background: none;
			cursor: pointer;
		}
		
		.body-model{
			padding: 18px 0;
			
			.introduce-text{
				max-width: 300px;
				margin: auto;
				font-size: 14px;
				color: #111214;
				line-height: 24px;
			}
			
			.introduce-text2{
				font-size: 12px;
				color: #8996B4;
				line-height: 18px;
				width: 336px;
				margin: auto;
				margin-top: 10px;
				text-align: left;
			}
			
			.blue-text{
				color: #2568FF;
				cursor: pointer;
			}
			
			.accept-btn{
				margin: auto;
				margin-top: 40px;
				width: 336px;
				height: 44px;
				background-color: #2568FF;
				border-radius: 10px 10px 10px 10px;
				text-align: center;
				line-height: 44px;
				cursor: pointer;
				font-size: 14px;
				color: #fff;
			}
		}
	}
</style>
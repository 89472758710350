<!-- 账户弹窗 -->
<template>
	<div class="loginAndRegister" v-if="userInfo">
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :show-close="false">
			<div class="custom-pop" >
				<div class="top-model">
					<span>{{ $t('order.pleaseConfirm') }}</span>
					<img @dragstart.prevent class="close-img" src="@/assets/img/ico_close.png" @click="close()" />
				</div>
				<div class="body-model">
					<div class="register-info">
						<div class="success-title">{{$t('account.accountCreated')}}</div>
						<div class="flex ju-sp">
							<div class="form-back">
								<div class="form-title">{{$t('account.surname')}}</div>
								<div>{{userInfo.lastName}}</div>
							</div>
							<div class="form-back">
								<div class="form-title">{{$t('account.name')}}</div>
								<div>{{userInfo.firstName}}</div>
							</div>
						</div>
						<div class="flex ju-sp">
							<div class="form-back">
								<div class="form-title">{{$t('history.type')}}</div>
								<div>{{userInfo.accountType}}</div>
							</div>
							<div class="form-back">
								<div class="form-title">{{$t('trade.deposit')}}</div>
								<div>{{userInfo.depositMoney}}</div>
							</div>
						</div>
						<div class="flex ju-sp">
							<div class="form-back">
								<div class="form-title">{{$t('order.loginName')}}</div>
								<div>{{userInfo.userAccount}}</div>
							</div>
							<div class="form-back">
								<div class="form-title">{{$t('login.password')}}</div>
								<div>{{userInfo.textPwd}}</div>
							</div>
						</div>
						<div class="flex ju-sp">
							<div class="form-back">
								<div class="form-title">{{$t('account.readOnlyPassword')}}</div>
								<div>{{userInfo.textReadPwd}}</div>
							</div>
							<div class="form-back">
								<div class="form-title">{{$t('account.server')}}</div>
								<div>{{userInfo.serverType}}</div>
							</div>
						</div>
						<div class="enter-btn" @click="close()">{{$t('account.enter')}}{{appName}}</div>
					</div>
				</div>
			</div>

		</el-dialog>
	</div>
</template>

<script>
import config from '@/config/config.js'
export default {
	components: {
		
	},
	props: {
		
	},
	data() {
		return {
			dialogVisible: false,
			appName: config.appSet.appName
		}
	},
	watch: {
		dialogVisible(val) {
			
		}
	},
	mounted() {
		// console.log(this.userInfo)
	},
	methods: {
		
		//显示
		show() {
			this.dialogVisible = true
		},
		//隐藏
		close() {
			this.dialogVisible = false
		},
		
	}
}
</script>

<style lang="scss" scoped>
.loginAndRegister {
	::v-deep .el-dialog {
		border-radius: 8px !important;
		box-shadow: 0px 4px 20px 0px rgba(34, 78, 169, 0.3) !important;
		background: #ffffff !important;
		margin-top: 25vh !important;
		width: 560px !important;
		height: 354px;
	}

	::v-deep .el-dialog__header {
		display: none;
	}



	::v-deep .el-dialog__body {
		padding: 0;
		// color: rgba(0,0,0,0.1);
	}
	
	.flex{
		display: flex;
		margin-top: 4px;
	}
	
	.ju-sp{
		justify-content: space-between;
	}
	
	.form-back{
		width: 256px;
		height: 40px;
		border-bottom: 1px solid #ECEFFB;
		display: flex;
		align-items: center;
		font-size: 14px;
		
		.form-title{
			color: #8996B4;
			width: 74px;
			text-align: left;
		}
	}
	
	.top-model {
		height: 50px;
		background-color: #2568FF;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px;
		color: #fff;
		font-size: 16px;
		border-radius: 8px 8px 0px 0px;
	}

	.close-img {
		width: 13px;
		height: 13px;
		background: none;
		cursor: pointer;
	}

	.body-model {
		background-color: #fff;
		display: flex;
		border-radius: 0px 0px 8px 8px;

		.menu-model {
			width: 159px;
			border-right: 1px solid #ECEFFB;
			height: 370px;

			.menu-btn {
				padding-left: 16px;
				display: flex;
				align-items: center;
				height: 48px;
				font-size: 14px;
				font-weight: 500;

				.menu-img {
					width: 20px;
					height: 20px;
					margin-right: 9px;
				}
			}

			.account-menu {
				border-bottom: 1px solid #ECEFFB;
				position: relative;
				z-index: 0;
				cursor: default;

				.img-dot {
					width: 15px;
					height: 6px;
					position: absolute;
					left: 62px;
					margin-left: 8px;
					bottom: -1px;
					z-index: 1;
				}
			}

			.choose-btn {
				background-color: #F2F8FF;
			}
		}


	}
}
.register-info{
	padding: 18px 16px 20px;
	width: 100%;
	
	.success-title{
		font-size: 16px;
		font-weight: bold;
		color: #000;
		line-height: 28px;
		text-align: left;
		margin-bottom: 7px;
	}
	
	.infos{
		width: 100%;
		.info-item{
			line-height: 24px;
			display: flex;
			flex-direction: row;
			.label{
				width: 100px;
				text-align: right;
				margin-right: 16px;
			}
			.value{}
		}
	}
}

.enter-btn{
	float: right;
	cursor: pointer;
	margin-top: 10px;
	width: max-content;
	padding: 14px 22px;
	background-color: #2568FF;
	border-radius: 10px;
	line-height: 16px;
	font-size: 14px;
	color: #fff;
}
</style>
<template>
	<div class="table-body" @contextmenu.prevent = "openMenu">
		<img v-if="warningWin" class="warning-img" src="@/static/image/warningGreen.png"/>
		<img v-if="warningLoss" class="warning-img" src="@/static/image/warningRed.png"/>
		<img v-if="warningTwo" class="warning-img" src="@/static/image/warningOrange.png"/>
		<div class="body-text" style="font-weight: bold;">{{orderItem.symbol}}</div>
		<div class="body-text">{{orderItem.openingPrice}}</div>
		<div class="body-text" style="width: 200px;">{{mixinDateFomat(orderItem.openTime)}}</div>
		<div class="body-text" :class="orderItem.direction==-1? 'sell-color':'buy-color'">{{orderItem.direction==-1?'sell':'buy'}}</div>
		<div class="body-text">{{orderItem.volume}}</div>
		<div class="body-text">{{orderItem.openingPrice}}</div>
		<div class="body-text">{{orderItem.loss?orderItem.loss:'-'}}</div>
		<div class="body-text">{{orderItem.profit?orderItem.profit:'-'}}</div>
		<div class="body-text">{{orderItem.direction==-1?orderItem.currentAsk:orderItem.currentBid}}</div>
		<div class="body-text">{{orderItem.storageFee?orderItem.storageFee:0}}</div>
		<div class="body-text">{{orderItem.openingFee?orderItem.openingFee:0}}</div>
		<div class="body-text" :class="orderItem.change < 0 ? 'sell-color':'buy-color'">{{orderItem.change?orderItem.change.toFixed(2):0}}</div>
		<div class="body-text action-text" :title="$t('index.popup.closePosition')" @click="$debounce(closeOrderFn)"><i class="el-icon-circle-close"></i></div>
	</div>
</template>

<script>
	import config from "@/config/config.js"
	export default{
		data() {
			return {
				showMore: false,
				isLongPress: false,
				upCount:0,
				oldPrice:0,
				isSubmiting:false,
				warningWin: false, //止盈预警
				warningLoss: false, //止损预警
				warningTwo: false //止盈止损预警
			}
		},
		props: {
			orderItem: {
				type: Object,
				default: () => {}
			},
		},
		watch: {
			orderItem(newVal) {
				if (newVal) {
					//判断止损止盈触发点，更新订单
		
					//-1卖 1买
					let type = parseFloat(newVal.direction)
					//当前最新价
					let currentPrice = type==-1?parseFloat(newVal.currentAsk):parseFloat(newVal.currentBid)
					
					if(currentPrice==this.oldPrice){
						//价格没更新不用判断
						return
					}
					
					this.oldPrice = currentPrice
					let profit = parseFloat(newVal.profit)
					let loss = parseFloat(newVal.loss)
					let earlyWarningValue = config.appSet.earlyWarningValue
					
					
					//有止盈
					if (profit) {
						if (type == 1) {
							//买入 当价格>=止盈 价
							if (currentPrice >= profit) {
								console.log('监听到止盈符合触发：买入方向')
								this.upOrdersTask()
							}
							//买入止盈预警  止盈价-当前价 <= 预警点
							let buyWarnProfitFlag = this.mixinGetSpread(profit,currentPrice,newVal.decimalPlaces) <= earlyWarningValue
							if (buyWarnProfitFlag) {
								console.log('监听到止盈预警：买入方向')
								this.warningWin = true
							}else{
								this.warningWin = false
							}
						    
						}
						if (type == -1) {
							//卖出 当价格<=止盈 价
							if (currentPrice <= profit) {
								console.log('监听到止盈符合触发：卖出方向')
								this.upOrdersTask()
							}
							//卖出止盈预警  当前价-止盈价 <= 预警点
							let sellWarnProfitFlag = this.mixinGetSpread(currentPrice,profit,newVal.decimalPlaces) <= earlyWarningValue
							if (sellWarnProfitFlag) {
								console.log('监听到止盈预警：卖出方向')
								this.warningWin = true
							}else{
								this.warningWin = false
							}
						}
					}
					//有止损
					if (loss) {
						if (type == -1) {
							//卖出 当价格>=止损 价
							if (currentPrice >= loss) {
								console.log('监听到止损符合触发：卖出方向')
								this.upOrdersTask()
							}
							//卖出止损预警  止损价-当前价 <= 预警点
							let sellWarnLossFlag = this.mixinGetSpread(loss,currentPrice,newVal.decimalPlaces) <= earlyWarningValue
							if (sellWarnLossFlag) {
								console.log('监听到止损预警：卖出方向')
								this.warningLoss = true
							}else{
								this.warningLoss = false
							}
						}
						if (type == 1) {
							//买入 当价格<=止损 价
							if (currentPrice <= loss) {
								console.log('监听到止损符合触发：买入方向')
								this.upOrdersTask()
							}
							//买入止损预警  当前价-止损价 <= 预警点
							let buyWarnLossFlag = this.mixinGetSpread(currentPrice,loss,newVal.decimalPlaces) <= earlyWarningValue
							if (buyWarnLossFlag) {
								console.log('监听到止损预警：买入方向')
								this.warningLoss = true
							}else{
								this.warningLoss = false
							}
						}
					}
					//有止盈止损
					if(profit && loss){
						if (type == 1) {
		
							//买入止盈预警  止盈价-当前价 <= 预警点 && 当前价-止损价 <= 预警点
							let buyTwoProfitFlag = this.mixinGetSpread(profit,currentPrice,newVal.decimalPlaces) <= earlyWarningValue
							let buyTwoLossFlag = this.mixinGetSpread(currentPrice,loss,newVal.decimalPlaces) <= earlyWarningValue
							if (buyTwoProfitFlag && buyTwoLossFlag) {
								this.warningTwo = true
							}else{
								this.warningTwo = false
							}
						    
						}
						
						if (type == -1) {
							//卖出止盈预警  当前价-止盈价 <= 预警点 && 止损价-当前价 <= 预警点
							let sellTwoProfitFlag = this.mixinGetSpread(currentPrice,profit,newVal.decimalPlaces) <= earlyWarningValue
							let sellTwoLossFlag = this.mixinGetSpread(loss,currentPrice,newVal.decimalPlaces) <= earlyWarningValue
							if (sellTwoProfitFlag && sellTwoLossFlag) {
								this.warningTwo = true
							}else{
								this.warningTwo = false
							}
						}
					}
					
				}
			}
		},
		methods:{
			//更新外部数据
			upOrdersTask() {
				let data = {
					type:0,
					orderId:this.orderItem.id
				}
				// this.$store.commit('setUpOrderList', data);
				// this.$appTask.addTask(this.orderItem)
			},
			//右键点击弹窗
			openMenu() {
			    let that = this;
			    this.$contextmenu({
			        items: [
						{
						    label: this.$t("index.popup.closePosition"),
						    onClick: () => {
								that.closeOrderFn();
						    }
						},
			            {
			                label: this.$t("index.popup.newOrder"),
			                onClick: () => {
			                    that.$emit('tradeShow', this.orderItem);
			                }
			            },
			            {
			                label: this.$t("index.popup.chart"),
			                onClick: () => {
			                    that.$emit('symbolClick',this.orderItem);
			                }
			            },
						{
						    label: this.$t("index.popup.changeOrder"),
						    onClick: () => {
								that.$emit('updateOrder',this.orderItem);
						    }
						},
			        ],
			        event,
			        customClass: "symbol_menu",
			        zIndex: 999,
			        minWidth: 88
			    });
			    return false;
			},
			//平仓接口
			closeOrderFn() {	
				this.$emit('closeOrderFn',this.orderItem)
			},
		}
	}
</script>
<style>
.symbol_menu  {
    padding: 0 0 !important;
}
.symbol_menu .menu_item {
    line-height: 39px !important;
}
</style>
<style lang="scss" scoped>
	.table-body{
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		position: relative;
		
		.warning-img{
			width: 5px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 7px;
		}
		
		.body-text{
			text-align: left;
			width: 9.09%;
			font-size: 14px;
		}
		.action-text{
			width: 3%;
			text-align: center;
			cursor: pointer;
			i{
				&:hover{
					opacity: 0.6;
				}
			}
		}
		.buy-color{
			color: #2568FF;
		}
		
		.sell-color{
			color: #DD4F4F;
		}
	}
</style>
<!-- 下单：类型1-6（挂单） -->
<template>
	<div class="submit-expected">

		<div class="lots-item" v-if="tradeType > 4">
			<div class="lots-title">{{$t('trade.price')}}</div>
			<num-price-box :tradeType="tradeType" :orderItem="orderItem" :type="tradeType % 2 == 1 ? 1 : -1"
				ref="breakPrice" :priceType="2" :data="tradeItem" @changePrice="changeBreakPriceFn"></num-price-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{ tradeType > 4 ? $t('trade.limitPrice') : $t('trade.price') }}</div>
			<num-price-box :tradeType="tradeType" :orderItem="orderItem" :type="tradeType % 2 == 1 ? 1 : -1" ref="price"
				:priceType="1" :data="tradeItem" @changePrice="changePriceFn"></num-price-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.stopLoss')}}</div>
			<num-box ref="loss" :data="tradeItem" :orderItem="orderItem" :type="-1" @change="changBack"></num-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.profit')}}</div>
			<num-box ref="profit" :data="tradeItem" :orderItem="orderItem" :type="1" @change="changBack"></num-box>
		</div>
		<div class="price-box">
			<div>{{ (data.bid ? parseFloat(data.bid) : 0).toFixed(data.decimalPlaces) }}</div>
			<div>{{ (data.ask ? parseFloat(data.ask) : 0).toFixed(data.decimalPlaces) }}</div>
		</div>
		<div class="action-box">
			<button class="blue-back" :class="{ 'disable-back': !(breakPrice > 0 && price > 0) }" v-if="tradeType > 4"
				@click="submitFn()" :disabled="!(breakPrice > 0 && price > 0)">{{$t('trade.revise')}}</button>
			<button class="blue-back" :class="{ 'disable-back': !(price > 0) }" v-else @click="submitFn()"
				:disabled="!(price > 0)">{{$t('trade.revise')}}</button>
		</div>
	</div>
</template>

<script>
import numBox from "./numBox.vue"
import numLotsBox from "./numLotsBox.vue"
import numPriceBox from "./numPriceBox.vue"
export default {
	components: {
		numBox,
		numLotsBox,
		numPriceBox
	},
	data() {
		return {
			notSell: false,
			notBuy: false,
			isSubmiting: false,
			data: {},
			loss: 0,
			profit: 0,
			breakPrice: 0,
			price: 0,
			stepValue: 1,
			num: 1
		}
	},
	props: {
		tradeItem: {
			type: Object,
			default: () => { }
		},
		tradeType: {
			type: Number,
			default: 1
		},
		orderItem: {
			type: Object,
			default: () => null
		}
	},
	watch: {
		tradeItem(data) {
			this.upData(data)
		},

	},
	mounted() {
		//更新
		this.upData(this.tradeItem)
		if (this.orderItem) {
			this.loss = this.orderItem.loss
			this.profit = this.orderItem.profit
			this.breakPrice = this.orderItem.breakPrice
			this.price = this.orderItem.openingPrice
		}
	},
	methods: {
		//更新
		upData(data) {
			this.data = data
			this.stepValue = data.stepValue
			this.$forceUpdate()
		},

		//
		getDirection() {
			//1买入 -1卖出
			let direction = this.tradeType % 2 != 0 ? 1 : -1
			return direction
		},

		//提交订单
		submitFn() {
			let param = {
				"id": this.orderItem.id,
				"newProfit": this.$refs.profit.getValue(),
				"newLoss": this.$refs.loss.getValue(),
				"direction": this.orderItem.direction,
				"openingPrice": this.$refs.price.getValue()
			}

			//突破价
			if (this.tradeType == 5 || this.tradeType == 6) {
				param.breakPrice = this.$refs.breakPrice.getValue()
			}

			//提交接口
			this.$api.orderHang.updateHangOrder(param).then(res => {
				if (res && res.code == 200) {
					this.$message.success(this.$t('order.submittedSuccessfully'))
					this.$emit('updateHangOrderBack', res.data)
				}
			})
		},
		//进步器回调,校验是否符合买卖
		changBack(type, value) {
			if (type == 1) {
				//止盈
				this.profit = value
			} else if (type == -1) {
				//止损
				this.loss = value
			}
		},
		//突破价
		changeBreakPriceFn(e) {
			this.breakPrice = e
		},
		//挂单价
		changePriceFn(e) {
			this.price = e
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number__decrease {
	width: 44px;
	height: 44px;
	background-color: transparent;
	border: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

::v-deep .el-input-number__increase {
	width: 44px;
	height: 44px;
	background-color: transparent;
	border: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.number-back {
	width: 288px;
	margin-bottom: 5px;
}

.price-box {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #DD4F4F;
	line-height: 28px;
	text-align: center;

	div {
		width: 100%;
	}
}

.action-box {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;

	button {
		cursor: pointer;
		width: 100%;
		height: 44px;
		border-radius: 8px 8px 8px 8px;
		border: 0px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		line-height: 42px;
	}

	.red-back {
		background-color: #DF3E52;

		&:hover {
			background-color: #f76475;
		}
	}

	.blue-back {
		background-color: #2568FF;

		&:hover {
			background-color: #5487fd;
		}
	}

	.disable-back {
		background-color: #D9DFEF;
		color: #A2ABB9;

		&:hover {
			background-color: #D9DFEF;
		}
	}
}

.submit-expected {
	padding: 7px 16px;
}

.lots-title {
	text-align: left;
	font-size: 13px;
	color: #8996B4;
	line-height: 28px;
}</style>
import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "historical/"

const historical = {
    //获取最新一个日K线
    getDayList(data) {
        let url = appUrl.getBaseUrl() + serviceName + "getDayList"
        return http.post(url, data);
    },
    //获取历史k线数据
    getKlineHistory(data) {
        let url = appUrl.getBaseUrl() + serviceName + "list"
        return http.post(url,data);
    }
}
export default historical;

import store from '@/store/index.js'
//全局任务队列
export default class appTaskUtils {

	constructor() {
		//任务计时器
		this.timer = null
		this.tasks = []
		this.upTasks = []
	}

	//添加任务
	addTask(order) {
		let orderId = order.id
		this.tasks.push(orderId)
		this.startTask()
	}

	//开始
	startTask() {
		if (this.timer) {
			// console.log("排队号：",this.tasks)
			// console.log("任务已经在执行，请等待更新")
			//已经有任务队列
			return
		}
		
		this.timer = setInterval(e => {
			// console.log("任务器执行")
			// this.upTasks = JSON.parse(JSON.stringify(this.tasks)) 
			// this.tasks = []
			if (this.tasks && this.tasks.length > 0) {
				// console.log("任务号：",this.tasks)
				// console.log("队列有数据，请求更新")
				this.tasks = []
				store.commit('setUpDataMsg', new Date().getTime());
			} else{
				// console.log("没有任务了，关闭任务器")
				this.clearTask()
			}
		}, 3000)

	}

	//清除
	clearTask() {
		this.tasks = []
		if (this.timer) clearInterval(this.timer)
		this.timer = null
	}
}

import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "userWallet/"

const userWallet = {
	//获取用户钱包信息
	userWallet() {
		let url = appUrl.getBaseUrl() + serviceName
		return http.get(url);
	}
}

export default userWallet;
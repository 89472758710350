
import config from "@/config/config.js"
const appUrl = {
	getUrlObj(){
		return config.useUrl;
	},
	setHttpBase(url) {
		let httpHead = `http://${url}/`;
		let wsHead = `ws://${url}/ws_quotes/${new Date().getTime()}`
		let useUrl = appUrl.getUrlObj();
		if(useUrl.type == 2) {
			httpHead = `https://${url}/`;
			wsHead = `wss://${url}/ws_quotes/${new Date().getTime()}`
		}
		return {httpHead:httpHead,wsHead:wsHead};
	},
	//设置应用baseUrl
	setBaseUrl(url,obj,token){
		let httpBase = appUrl.setHttpBase(url).httpHead;
		//更新config.useUrl
		config.useUrl.baseUrl = httpBase
	},
	getDefaultUrl(){
		return appUrl.getUrlObj().defaultUrl + "app/"
	},
	getBaseUrl(){
		let baseUrl = appUrl.getUrlObj()
		return baseUrl.baseUrl + "app/"
	},
	getBaseUrlByUrls(urls){
		let url = appUrl.getUrlForUrls(urls)
		let httpBase = appUrl.setHttpBase(url).httpHead;
		return httpBase + "app/"
	},
	getImgBaseUrl(){
		return appUrl.getUrlObj().baseUrl
	},
	getUrlForUrls(urls){
		if(!urls || urls.length<1){
			return ''
		}
		if(urls.length<2){
			return urls[0]
		}
		//大于1个地址取随机值
		let n = Math.floor(Math.random() * urls.length + 1) -1
		let url = urls[n]
		// console.log(url)
		return url
	},
}

export { appUrl }

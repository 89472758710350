<!-- 下单：类型0（即时） -->
<template>
	<div class="submit-now">
		<div class="lots-item">
			<div class="lots-title">{{$t('order.tradingVolume')}}</div>
			
			<num-lots-box ref="lots" :data="tradeItem"></num-lots-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.stopLoss')}}</div>
			
			<num-box ref="loss" :data="tradeItem" :type="-1" @change="changBack"></num-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.profit')}}</div>

			<num-box ref="profit" :data="tradeItem" :type="1" @change="changBack"></num-box>
		</div>
		<div class="price-box">
			<div>{{ (data.bid ? parseFloat(data.bid) : 0).toFixed(data.decimalPlaces) }}</div>
			<div>{{ (data.ask ? parseFloat(data.ask) : 0).toFixed(data.decimalPlaces) }}</div>
		</div>
		<div class="action-box">
			<button class="red-back" @click="submitSell()" :disabled="notSell || isSubmiting">sell</button>
			<button class="blue-back" @click="submitBuy()" :disabled="notBuy || isSubmiting">buy</button>
		</div>
	</div>
</template>

<script>
import numBox from "./numBox.vue"
import numLotsBox from "./numLotsBox.vue"
export default {
	components: {
		numBox,
		numLotsBox
	},
	data() {
		return {
			notSell: false,
			notBuy: false,
			isSubmiting: false,
			data: {},
			loss: 0,
			profit: 0,
			num:0.01
		}
	},
	props: {
		tradeItem: {
			type: Object,
			default: () => { }
		},
	},
	watch: {
		tradeItem(data) {
			this.upData(data)
		},

	},
	mounted() {
		//更新
		this.upData(this.tradeItem)
	},
	methods: {
		//更新
		upData(data) {
			this.data = data
			this.$forceUpdate()
		},
		//sell
		submitSell() {
			if (this.notSell || this.isSubmiting) {
				return
			}
			this.submitFn(-1)
		},
		//buy
		submitBuy() {
			if (this.notBuy || this.isSubmiting) {
				return
			}
			this.submitFn(1)
		},
		//提交订单
		submitFn(type) {
			let leverageRatio = this.userInfo.userGroup.leverage ? this.userInfo.userGroup.leverage : 50
			let param = {
				"direction": type, //	方向类型：1买或者-1卖
				"symbol": this.data.symbol, //交易品种
				"volume": this.$refs.lots.getValue(), //手数
				"openingPrice": type == -1 ? this.data.bid : this.data.ask, //开仓价格
				"leverageRatio": leverageRatio, //杠杆倍数
				"tradeType": 0
			}
			//止损
			let loss = this.$refs.loss.getValue()
			if (loss > 0) {
				//使用止损
				param.loss = loss //止损
			}

			//止盈
			let profit = this.$refs.profit.getValue()
			if (profit > 0) {
				//使用止盈
				param.profit = profit //止盈
			}
			this.isSubmiting = true
			//提交接口
			this.$api.order.openOrder(param).then(res => {
				this.isSubmiting = false
				if (res && res.code == 200) {
					this.$message.success(this.$t('order.submittedSuccessfully'))
					let result = res.data.order
					this.$emit('openOrderBack', result)
				}
			})
		},
		//进步器回调,校验是否符合买卖
		changBack(type, value) {
			if (type == 1) {
				//止盈
				this.profit = value
			} else if (type == -1) {
				//止损
				this.loss = value
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	::v-deep .el-input-number__decrease{
		width: 44px;
		height: 44px;
		background-color: transparent;
		border: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	::v-deep .el-input-number__increase{
		width: 44px;
		height: 44px;
		background-color: transparent;
		border: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.number-back{
		width: 288px;
		margin-bottom: 5px;
	}
	
.price-box {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #DD4F4F;
	line-height: 28px;
	text-align: center;
	
	div{
		width:100%;
	}
}
.action-box {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	
	button {
		cursor: pointer;
		width: 138px;
		height: 44px;
		border-radius: 8px 8px 8px 8px;
		border: 0px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		line-height: 42px;
	}
	
	.red-back {
		background-color: #DF3E52;
		&:hover{
			background-color: #f76475;
		}
	}

	.blue-back {
		background-color: #2568FF;
		&:hover{
			background-color: #5487fd;
		}
	}
	.disable-back{
		background-color: #D9DFEF;
		color: #A2ABB9;
		&:hover{
			background-color: #D9DFEF;
		}
	}
}

.submit-now{
	padding: 7px 16px;
}

.lots-title{
	text-align: left;
	font-size: 13px;
	color: #8996B4;
	line-height: 28px;
}
</style>
<!-- 注册 -->
<template>
    <div class="form-model">

        <!-- 选择交易商 -->
        <Traders type="2" v-if="loginType == 0" :traders="traders" @selectTraderBack="selectTraderBack"></Traders>

        <!-- 注册 -->
        <div class="login-type" v-if="loginType == 1">
            <div class="login-box-header">
                <h3 class="form-title">{{$t('order.registerAccount')}}</h3>
                <span class="link-back" @click="loginType = 0">{{$t('order.goBack')}}</span>
            </div>
            <li class="select-trader">
                <img class="trader-logo" src="@/assets/logo.png" />
                <p class="trader-info">
                    <span class="trader-name">{{ selectTrader.name }}</span>
                    <span class="trader-desc">{{ selectTrader.des }}</span>
                </p>
            </li>
            <el-form :model="registerParam" :rules="rules" ref="registerParam">

                <div class="form-cell">
                    <el-form-item prop="lastName">
                        <div class="input-back">
                            <div class="form-name">{{$t('account.surname')}}</div>
                            <el-input class="form-value" :placeholder="$t('setting.pleasEnter')" v-model="registerParam.lastName"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="firstName">
                        <div class="input-back">
                            <div class="form-name">{{$t('account.name')}}</div>
                            <el-input class="form-value" :placeholder="$t('setting.pleasEnter')" v-model="registerParam.firstName"></el-input>
                        </div>
                    </el-form-item>
                </div>

                <div class="form-cell">
                    <el-form-item prop="phone">
                        <div class="input-back">
                            <div class="form-name">{{$t('account.telephone')}}</div>
                            <el-input class="form-value" :placeholder="$t('setting.pleasEnter')" type="number"
                                v-model="registerParam.phone"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="userName">
                        <div class="input-back">
                            <div class="form-name">{{$t('account.mail')}}</div>
                            <el-input class="form-value" :placeholder="$t('setting.pleasEnter')" v-model="registerParam.userName"></el-input>
                        </div>
                    </el-form-item>
                </div>

                <div class="form-cell">
                    <el-form-item>
                        <div class="input-back">
                            <div class="form-name">{{$t('trade.deposit')}}</div>
                            <el-select class="form-value" v-model="registerParam.depositMoney" :placeholder="$t('history.choose')">
                                <el-option v-for="item in depositMoney" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="input-back">
                            <div class="form-name">{{$t('history.type')}}</div>
                            <el-select class="form-value" v-model="registerParam.accountType" :placeholder="$t('history.choose')">
                                <el-option v-for="item in accountType" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>

                </div>
                <div class="form-cell">
                    <div class="input-back">
                        <div class="form-name">{{$t('trade.lever')}}</div>
                        <el-input class="form-value" :disabled="true" :placeholder="$t('setting.pleasEnter')"
                            v-model="registerParam.lever"></el-input>
                    </div>
                    <el-button class="login-btn pointer" :loading="isLoading" @click="registerFn()">{{ isLoading ? '' : $t('login.register')
                    }}</el-button>
                </div>

            </el-form>



        </div>
    </div>
</template>
<script>
import { upUserData } from "@/utils/userUtils";
import Traders from "./Traders.vue"
export default {
    name: 'Register',
    components: {
        Traders
    },
    watch: {
    },
    props: {
        traders: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loginType: 0,
            registerParam: {
                lastName: '',//
                firstName: '',//
                phone: '',//
                userName: '',//
                serverType:'',
                accountType: '',//
                lever: '',//
                depositMoney: '',//
            },
            selectTrader: null,
            rules: {
                lastName: [
                    { required: true, message: this.$t('account.surnameTip'), trigger: ['blur', 'change'] },
                ],
                firstName: [
                    { required: true, message: this.$t('account.nameTip'), trigger: ['blur', 'change'] },
                ],
                phone: [
                    { required: true, message: this.$t('order.phoneEmpty'), trigger: ['blur', 'change'] },
                ],
                userName: [
                    { required: true, message: this.$t('account.mailTip'), trigger: ['blur', 'change'] },
                    { type: 'email', message: this.$t('order.emailAddress'), trigger: 'blur' }
                ],
            },
            accountInfo: null, //服务商详情
            depositMoney: [],
            accountType: [],
            isLoading: false
        }
    },
    mounted() {

    },
    methods: {
        //获取服务商详情
        getAccountInfo() {
            this.$api.user.getAccountInfo(this.selectTrader.url).then(res => {
                if (res && res.data) {
                    this.accountInfo = res.data
                    this.depositMoney = res.data.depositMoney
                    this.accountType = res.data.userGroup
                    this.registerParam.lever = res.data.leverage
                    this.registerParam.depositMoney = res.data.depositMoney[0]
                    this.registerParam.accountType = res.data.userGroup[0]
                    this.registerParam.serverType = res.data.serverType
                }
            })
        },
        //更新数据
        setUserData(res) {
            let userInfo = res.data
            //更新cache cahe只放简单的信息
            let userCache = {
                id: userInfo.id,
                groupId: userInfo.groupId,
                token: userInfo.token,
                userAccount: userInfo.userAccount
            }
            this.$storage.setCache('userInfo', userCache)
            //服务器信息
            userInfo.appService = this.selectTrader
            //更新db
            upUserData(this, userInfo, () => {
                //更新vuex
                this.$store.commit('upUserInfo', userInfo)
                //回调
                this.$emit('registerBack', userInfo)
            })
        },

        //注册
        registerFn() {
            this.$refs.registerParam.validate((valid) => {
                if (valid) {
                    this.isLoading = true
                    let urls = this.selectTrader.url
                    this.$api.user.register(urls, this.registerParam).then(res => {
                        this.isLoading = false
                        if (res && res.data) {
                            let data = res.data
                            data.appService = this.selectTrader
                            this.$emit('registerBack', data)
                            this.$global.userInfo = data
                        }
                    })
                } else {
                    return false;
                }
            });

        },

        //选择交易商
        selectTraderBack(item) {
            this.selectTrader = item
            this.loginType = 1
            this.getAccountInfo()
        }
    }
}
</script>
<style lang="scss" scoped>
.select-trader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 232px;
    height: 36px;
    border-radius: 8px;
    margin-bottom: 12px;

    .trader-logo {
        width: 36px;
        height: 36px;
        margin-right: 8px;
    }

    .trader-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
    }
}

.login-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.form-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
}

.form-model {
    padding: 16px;
    width: calc(560px - 32px);

    .form-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
    }

    .input-back {
        display: flex;
        width: 258px;
        align-items: center;
        height: 40px;
        margin-right: 12px;
        justify-content: space-between;

        .form-name {
            width: 50px;
            font-size: 14px;
            text-align: left;
        }

        ::v-deep .el-input__inner {
            border-radius: 10px;
            border-color: #ECEFFB;
        }

        .form-value {
            flex: 1;
            height: 40px;
        }
    }

    .login-btn {
        // margin-top: 12px;
        padding: 0;
        width: 138px;
        height: 40px;
        background-color: #2568FF;
        border-radius: 10px;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        float: right;
        border: none
    }

    .search-dealers {
        width: 528px;
        height: 48px;
        margin-top: 12px;

        ::v-deep .el-input__inner {
            height: 48px;
        }

        ::v-deep .el-input__icon {
            line-height: 48px;
            font-size: 16px;
        }
    }

    .dealers-back {
        display: flex;
        flex-wrap: wrap;
    }
}

.link-back {
    color: #2568FF;
    cursor: pointer;
}

.form-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>
  
<!-- 下单：类型0（即时） -->
<template>
	<div class="submit-now">
		<div class="order-info">
			<div>
				<span class="direction-text">{{ orderItem.direction == 1 ? 'buy' : 'sell' }}</span>
				<span class="direction-text">{{ orderItem.volume }}</span>
			</div>
			<div class="orderNo-text">
				<span>#{{ orderItem.id }}</span>
			</div>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.stopLoss')}}</div>
			<num-box ref="loss" :data="tradeItem" :orderItem="orderItem" :type="-1" @change="changBack"></num-box>
		</div>
		<div class="lots-item">
			<div class="lots-title">{{$t('index.popup.profit')}}</div>
			<num-box ref="profit" :data="tradeItem" :orderItem="orderItem" :type="1" @change="changBack"></num-box>
		</div>
		<div class="price-box">
			<div>{{ (data.bid ? parseFloat(data.bid) : 0).toFixed(data.decimalPlaces) }}</div>
			<div>{{ (data.ask ? parseFloat(data.ask) : 0).toFixed(data.decimalPlaces) }}</div>
		</div>

		<div class="action-box">
			<button class="blue-back" @click="updateFn()">{{$t('trade.revise')}}</button>
		</div>
	</div>
</template>

<script>

import numBox from "./numBox.vue"
import numLotsBox from "./numLotsBox.vue"
export default {
	components: {
		numBox,
		numLotsBox,
	},
	data() {
		return {
			notSell: false,
			notBuy: false,
			isSubmiting: false,
			data: {},
			loss: 0,
			profit: 0,
			num: 0.01
		}
	},
	props: {
		tradeItem: {
			type: Object,
			default: () => { }
		},
		orderItem: {
			type: Object,
			default: () => { }
		}
	},
	watch: {
		tradeItem(data) {
			this.upData(data)
		},

	},
	mounted() {
		//更新
		this.upData(this.tradeItem)
		if(this.orderItem){
			this.loss = this.orderItem.loss
			this.profit = this.orderItem.profit
		}
	},
	methods: {
		//更新
		upData(data) {
			this.data = data
			this.$forceUpdate()
		},

		//提交订单
		updateFn() {
			let param = {
				"id": this.orderItem.id,
				"newProfit": this.$refs.profit.getValue(),
				"newLoss": this.$refs.loss.getValue(),
				"direction": this.orderItem.direction
			}

			//提交接口
			this.$api.order.updatePositionOrder(param).then(res => {
				if (res && res.code == 200) {
					this.$message.success(this.$t('order.submittedSuccessfully'))
					this.$emit('updateOrderBack',res.data)
				}
			})
		},
		//进步器回调,校验是否符合买卖
		changBack(type, value) {
			if (type == 1) {
				//止盈
				this.profit = value
			} else if (type == -1) {
				//止损
				this.loss = value
			}
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number__decrease {
	width: 44px;
	height: 44px;
	background-color: transparent;
	border: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

::v-deep .el-input-number__increase {
	width: 44px;
	height: 44px;
	background-color: transparent;
	border: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.number-back {
	width: 288px;
	margin-bottom: 5px;
}

.price-box {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	color: #DD4F4F;
	line-height: 28px;
	text-align: center;

	div {
		width: 100%;
	}
}

.action-box {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;

	button {
		cursor: pointer;
		width: 100%;
		height: 44px;
		border-radius: 8px 8px 8px 8px;
		border: 0px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		line-height: 42px;
	}

	.red-back {
		background-color: #DF3E52;
		&:hover{
			background-color: #f76475;
		}
	}

	.blue-back {
		background-color: #2568FF;
		&:hover{
			background-color: #5487fd;
		}
	}
	.disable-back{
		background-color: #D9DFEF;
		color: #A2ABB9;
		&:hover{
			background-color: #D9DFEF;
		}
	}
}

.submit-now {
	padding: 7px 16px;
}

.lots-title {
	text-align: left;
	font-size: 13px;
	color: #8996B4;
	line-height: 28px;
}

.order-info {
	display: flex;
	flex-direction: row;
	padding: 12px 0;

	.direction-text {
		font-size: 14px;
		color: #000;
		font-weight: 600;
		margin-right: 8px;
	}

	.orderNo-text {
		font-size: 14px;
		font-weight: 600;
		color: #8996B4;
	}
}</style>
<!-- 进步器（止盈止损） -->
<template>
	<el-input-number class="number-back" v-model="value" @change="changeValue" :step="stepValue"></el-input-number>
</template>

<script>
import { appUtils } from "@/utils/appUtils";
export default {
	data() {
		return {
			value: 0,
			stepValue: 1,
			oldValue: 0
		}
	},
	props: {
		data: {
			type: Object,
			default: {}
		},
		type: {
			type: Number,
			default: 0
		},
		orderItem:{
			type:Object,
			default:()=>null
		}
	},
	watch: {

	},
	mounted() {
		//止损止盈进步值
		this.stepValue = 1 / Math.pow(10, this.data.decimalPlaces)
		if(this.orderItem){
			if(this.type==-1){
				this.value = this.orderItem.loss
			}else{
				this.value = this.orderItem.profit
			}
			
		}
	},
	methods: {
		//获取当前值
		getValue() {
			return this.value
		},
		//+ - 改变
		changeValue(e) {
			if (!e) {
				this.$nextTick(e=>{
					this.value = 0
					this.oldValue = 0
				})
			} else {
				if (this.oldValue <= 0 &&( e < 0 || e==this.stepValue)) {
					this.value = appUtils.getPendingLossSpread(parseFloat(this.data.bid), this.data.decimalPlaces, this.
						data.pendingLossSpread, this.type)
					this.value = this.value.toFixed(this.data.decimalPlaces)
				}
				this.oldValue = this.value
			}

		},
	}
}
</script>

<style lang="scss" scoped>
.num-box {
	.input-num {
		width: 100px;
	}
}
</style>
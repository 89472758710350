<template>
	<div class="closePosition">
		<el-dialog :close-on-click-modal="true" :visible.sync="dialogVisible" :show-close="false">
			<div class="top-model">
				<span>{{$t('order.pleaseConfirm')}}</span>
				<img @dragstart.prevent class="close-img" src="@/static/image/closeWhite.png" @click="closeDialogVis()" />
			</div>
			<div class="body-model">
				<img @dragstart.prevent class="record-img" src="@/assets/record.png"/>
				<div class="tip-text">{{$t('index.popup.whether')}}</div>
				<div class="btn-back">
					<div class="cancel-btn" @click="closeDialogVis()">{{$t('index.popup.reject')}}</div>
					<div class="acc-btn" @click="submitPosition()">{{$t('index.popup.accept')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				dialogVisible:false
			}
		},
		methods:{
			openDialogVis(){
				this.dialogVisible = true
			},
			closeDialogVis(){
				this.dialogVisible = false
			},
			submitPosition(){
				this.$emit('closePosition')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.closePosition{
		::v-deep .el-dialog {
			border-radius: 8px !important;
			box-shadow: 0px 4px 20px 0px rgba(34, 78, 169, 0.3) !important;
			background: #ffffff !important;
			margin-top: 25vh !important;
			width: 420px !important;
			height: 280px;
		}
		
		::v-deep .el-dialog__header {
			display: none;
		}
		
		::v-deep .el-dialog__body {
			padding: 0;
		}
		
		.top-model {
			height: 50px;
			background-color: #2568FF;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 16px;
			color: #fff;
			font-size: 16px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.close-img {
			width: 12px;
			height: 12px;
			cursor: pointer;
		}
		
		.body-model{
			padding-top: 30px;
			
			.record-img{
				width: 66px;
				height: 66px;
			}
			
			.tip-text{
				margin-top: 18px;
				font-size: 16px;
				line-height: 14px;
			}
			
			.btn-back{
				margin-top: 30px;
				display:flex;
				align-items: center;
				justify-content: center;
				
				.cancel-btn{
					box-sizing: border-box;
					cursor: pointer;
					width: 138px;
					height: 44px;
					background: #fff;
					border-radius: 8px;
					border: 1px solid #ECEFFB;
					font-size: 14px;
					line-height: 44px;
					text-align: center;
					margin-right: 12px;
				}
				
				.acc-btn{
					cursor: pointer;
					box-sizing: border-box;
					width: 138px;
					height: 44px;
					background-color: #2568FF;
					border-radius: 8px 8px 8px 8px;
					font-size: 14px;
					line-height: 44px;
					text-align: center;
					color: #fff;
				}
			}
		}
	}
</style>
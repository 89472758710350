<template>
	<div class="table-body" @contextmenu.prevent = "openMenu">
		<div class="body-text" style="font-weight: bold;">{{orderItem.symbol}}</div>
		<div class="body-text">{{orderItem.openingPrice}}</div>
		<div class="body-text" style="width: 200px;">{{mixinDateFomat(orderItem.openTime)}}</div>
		<div class="body-text" :class="orderItem.direction==-1? 'sell-color':'buy-color'">{{orderItem.direction==-1?'sell':'buy'}}</div>
		<div class="body-text">{{orderItem.volume}}</div>
		<div class="body-text">{{orderItem.openingPrice}}</div>
		<div class="body-text">{{orderItem.loss?orderItem.loss:'-'}}</div>
		<div class="body-text">{{orderItem.profit?orderItem.profit:'-'}}</div>
		<div class="body-text">{{orderItem.direction==-1?orderItem.currentAsk:orderItem.currentBid}}</div>
		<div class="body-text">{{orderItem.storageFee?orderItem.storageFee:0}}</div>
		<div class="body-text pending-color">Pending</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{}
		},
		props:{
			orderItem: {
				type: Object,
				default: () => {}
			}
		},
		watch: {
			orderItem(newVal) {
				if (newVal) {
					//判断挂单价触发点，更新订单
					//挂单模式下不用考虑止盈止损触发，止盈止损触发一定是在开仓的情况下触发
					
					//订单状态不为待处理的不执行触发
					// if(this.orderItem.status!=0){
					// 	return
					// }
					
					
					//-1卖 1买
					let type = parseFloat(newVal.direction)
					//当前最新价(买 卖)
					let currentPrice = type==1?parseFloat(newVal.currentAsk):parseFloat(newVal.currentBid)
					
					if(currentPrice==this.oldPrice){
						//价格没更新不用判断
						return
					}
					
					this.oldPrice = currentPrice
					
					//挂单价
					let price = parseFloat(newVal.openingPrice)
					//突破价
					let breakPrice = parseFloat(newVal.breakPrice)
					if (type == -1) {
						//卖出
						if (price <= currentPrice) {
							if(this.orderItem.tradeType ==2){
								this.upOrdersTask()
							}else if(this.orderItem.tradeType ==6 && this.breakStatus == 1){
								this.upOrdersTask()
							}
						}
						if(price>=currentPrice && this.orderItem.tradeType ==4){
							this.upOrdersTask()
						}
						//突破到位
						if (currentPrice <= breakPrice && this.breakStatus==0) {
							this.breakStatus = 1
							this.orderItem.breakStatus = 1
						}
					}
					if (type == 1) {
						//买入
						if (price >= currentPrice) {
							if(this.orderItem.tradeType ==1){
								this.upOrdersTask()
							}else if(this.orderItem.tradeType ==5 && this.breakStatus == 1){
								this.upOrdersTask()
							}
						}
						if(currentPrice>=price && this.orderItem.tradeType ==3){
							this.upOrdersTask()
						}
						//突破到位
						if (currentPrice >= breakPrice && this.breakStatus==0) {
							this.breakStatus = 1
							this.orderItem.breakStatus = 1
						}
					}
				}
			}
		},
		data() {
			return {
				showMore: false,
				isLongPress: false,
				oldPrice: 0,
				isSubmiting:false,
				breakStatus:0
			}
		},
		methods:{
			//取消订单
			restoredOrderFn() {
				this.$emit('restoredOrderFn',this.orderItem)
			},
			//更新外部数据
			upOrdersTask() {
				let data = {
					type:1,
					orderId:this.orderItem.id
				}
				// this.$store.commit('setUpOrderList', data);
				// this.$appTask.addTask(this.orderItem)
			},
			//右键点击弹窗
			openMenu() {
			    let that = this;
			    this.$contextmenu({
			        items: [
						{
						    label: this.$t("index.popup.cancel"),
						    onClick: () => {
								that.restoredOrderFn();
						    }
						},
			            {
			                label: this.$t("index.popup.newOrder"),
			                onClick: () => {
			                    that.$emit('tradeShow', this.orderItem);
			                }
			            },
			            {
			                label: this.$t("index.popup.chart"),
			                onClick: () => {
			                    that.$emit('symbolClick',this.orderItem);
			                }
			            },
						{
						    label: this.$t("index.popup.changeOrder"),
						    onClick: () => {
								that.$emit('updateHangOrder',this.orderItem);
						    }
						},
			        ],
			        event,
			        customClass: "symbol_menu",
			        zIndex: 999,
			        minWidth: 88
			    });
			    return false;
			},
		}
	}
</script>
<style>
.symbol_menu  {
    padding: 0 0 !important;
}
.symbol_menu .menu_item {
    line-height: 39px !important;
}
</style>
<style lang="scss" scoped>
	.table-body{
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		position: relative;
		
		.warning-img{
			width: 5px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 7px;
		}
		
		.body-text{
			text-align: left;
			width: 9.09%;
			font-size: 14px;
		}
		
		.pending-color{
			color: #2251bb;
		}
		
		.buy-color{
			color: #2568FF;
		}
		
		.sell-color{
			color: #DD4F4F;
		}
	}
</style>
<template>
  <div class="page" @click="closeHead">
    <div class="page-top">
      <LoginPopover @userFn="userFn" @changeLanguage="changeLang"></LoginPopover>
      <div class="menu-left">
        <div class="chart-control">
          <div class="chart-item" @click="changeKlineType(0)">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/amekline.png" class="chart-item-icon" :alt="$t('image.histogram')"
                :title="$t('image.histogram')" v-show="chartType != 0">
              <img @dragstart.prevent src="@/assets/amekline_active.png" class="chart-item-icon"
                :alt="$t('image.histogram')" :title="$t('image.histogram')" v-show="chartType == 0">
            </div>
          </div>
          <div class="chart-item" @click="changeKlineType(1)">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/kline.png" class="chart-item-icon" :alt="$t('image.candlestick')"
                :title="$t('image.candlestick')" v-show="chartType != 1">
              <img @dragstart.prevent src="@/assets/kline_active.png" class="chart-item-icon"
                :alt="$t('image.candlestick')" :title="$t('image.candlestick')" v-show="chartType == 1">
            </div>
          </div>
          <div class="chart-item" @click="changeKlineType(3)">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/area.png" class="chart-item-icon" :alt="$t('image.areaChart')"
                :title="$t('image.areaChart')" v-show="chartType != 3">
              <img @dragstart.prevent src="@/assets/area_active.png" class="chart-item-icon" :alt="$t('image.areaChart')"
                :title="$t('image.areaChart')" v-show="chartType == 3">
            </div>
          </div>
          <div class="chart-persiod">
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '1' }" :title="$t('image.oneMinute')"
              @click="changeKlinePeriod('1')">
              M1
            </div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '5' }" :title="$t('image.fiveMinute')"
              @click="changeKlinePeriod('5')">
              M5
            </div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '15' }"
              :title="$t('image.fifteenMinutes')" @click="changeKlinePeriod('15')">
              M15</div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '30' }"
              :title="$t('image.thirtyMinute')" @click="changeKlinePeriod('30')">
              M30</div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '60' }" :title="$t('image.oneHour')"
              @click="changeKlinePeriod('60')">
              H1</div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '240' }" :title="$t('image.fourHour')"
              @click="changeKlinePeriod('240')">H4</div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '1D' }" :title="$t('image.oneDay')"
              @click="changeKlinePeriod('1D')">D1
            </div>

            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '1W' }" :title="$t('image.week')"
              @click="changeKlinePeriod('1W')">W1</div>
            <div class="persiod-item no-select" :class="{ 'active': chartPeriod == '1M' }" :title="$t('image.oneMonth')"
              @click="changeKlinePeriod('1M')">MN</div>
          </div>
          <div class="chart-item" @click="studyHandle">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/study_active.png" class="chart-item-icon"
                :alt="$t('image.addIndicator')" :title="$t('image.addIndicator')">
              <img @dragstart.prevent src="@/assets/study.png" class="chart-item-icon" :alt="$t('image.addIndicator')"
                :title="$t('image.addIndicator')" v-show="false">
            </div>
          </div>
          <div class="chart-item" v-if="false">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/order_hide.png" class="chart-item-icon"
                :alt="$t('image.tradingPanel')" :title="$t('image.tradingPanel')">
              <img @dragstart.prevent src="@/assets/order_show.png" class="chart-item-icon"
                :alt="$t('image.tradingPanel')" :title="$t('image.tradingPanel')" v-show="false">
            </div>
          </div>
          <div class="chart-item" :style="{ 'width': tradeWidth.pWidth + 'px' }" @click="tradeShow()">
            <div class="chart-item-bg" :style="{ 'width': tradeWidth.cWidth + 'px' }">
              <div class="chart-init-item" :class="{ 'active': showSubmitOrder }" :title="$t('image.transactionTable')">
                <div class="left-icon">
                  <img @dragstart.prevent src="@/assets/order_btn.png" class="chart-item-icon"
                    :alt="$t('image.transactionTable')" v-show="!showSubmitOrder">
                  <img @dragstart.prevent src="@/assets/order_btna.png" class="chart-item-icon"
                    :alt="$t('image.transactionTable')" v-show="showSubmitOrder">
                </div>
                <div class="right-icon no-select">
                  {{ $t('index.popup.newOrder') }}
                </div>
              </div>
            </div>
          </div>
          <div class="chart-item" @click="showSymbolList">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/order_list.png" class="chart-item-icon"
                :alt="$t('image.marketQuotes')" :title="$t('image.marketQuotes')" v-show="!symbolListShow">
              <img @dragstart.prevent src="@/assets/order_lista.png" class="chart-item-icon"
                :alt="$t('image.marketQuotes')" :title="$t('image.marketQuotes')" v-show="symbolListShow">
            </div>
          </div>
        </div>
        <div class="chart-control-rt" v-if="false">
          <div class="chart-item" @click="ShotHandle">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/params.png" class="chart-item-icon" :alt="$t('image.saveDiagram')"
                :title="$t('image.saveDiagram')">
            </div>
          </div>
          <div class="chart-item" @click="fullScreenHandle">
            <div class="chart-item-bg">
              <img @dragstart.prevent src="@/assets/full_screen.png" class="chart-item-icon" :alt="$t('image.fullScreen')"
                :title="$t('image.fullScreen')">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <!-- 上半部分 -->
      <div class="page-main-center">
        <Splitpanes class="default-theme splitpanesBack"  :dbl-click-splitter="false">
          <pane class="pane" :size="symbolListShow?'82':'100'"> <!-- 图表 -->
            <div class="chart-box" style="border-radius: 0 8px 8px 0;overflow: hidden;">
              <div style="height: 540px;" v-if="showChart">
                <!-- showChart -->
                <TradeView ref="klineChart" :tradeItem="tradeItem" :chartResetLoaing="chartResetLoaing"
                  @loadingChart="loadingChart" @tradeShow="tradeShow" @chartResetHandle="chartResetHandle"
                  :hangOrders="klineHanders" :orders="klineOrders" @chartFailSend="chartFailSend">
                </TradeView>
              </div>
              <div class="chart-loading-box" v-if="chartLoaing">
                <div class="chart-loaing"></div>
              </div>
            </div>

            <!-- 交易 -->
            <div class="trade-box" v-if="showSubmitOrder">
              <submit-trade v-if="tradeItem && tradeItem.minVolume" :tradeItem="tradeItem" @closeBack="closeBack"
                @openOrderBack="openOrderBack" @openHangOrderBack="openHangOrderBack"></submit-trade>
            </div>

            <!-- 修改订单 -->
            <div class="trade-box" v-if="showUpdateOrder">
              <update-trade :tradeItem="tradeItem" :orderItem="orderItem" @closeUpdateBack="closeUpdateBack"
                @updateOrderBack="updateOrderBack" @updateHangOrderBack="updateHangOrderBack"></update-trade>
            </div>
          </pane>
          <pane  class="pane" :size="symbolListShow?'18':'0'">
            <!-- 品类选择 -->
            <div class="symbols-box" v-show="symbolListShow" style="width: 100%;">
              <MarketModule :collectList="collectList" :allOrderSymbol="allOrderSymbol" :list="showVarietyList"
                :firstLoading="firstLoading" @tradeShow="tradeShow" @symbolClick="symbolClick" @symbolInfo="symbolInfo"
                @goLogin="goLogin" ref="marketModule"></MarketModule>
            </div>
          </pane>
        </Splitpanes>




      </div>

      <!-- 下半部分 -->
      <div class="page-main-bottom">
        <FilterTab @changeFilterTabIndex="changeFilterTabIndex" :FilterTabIndex="FilterTabIndex"></FilterTab>
        <FilterList @closeOrderFn="closeOrderFn" @tradeShow="tradeShow" @symbolClick="symbolClick"
          v-if="FilterTabIndex == 0" :orders="orders" @updateOrder="updateOrder"></FilterList>
        <BookList @restoredOrderFn="restoredOrderFn" @tradeShow="tradeShow" @symbolClick="symbolClick"
          v-if="FilterTabIndex == 1" :hangOrders="hangOrders" @updateHangOrder="updateOrder"></BookList>
        <HistoryOrder @tradeShow="tradeShow" @symbolClick="symbolClick" @changeFilter="changeFilter"
          :filterParams="filterParams" v-if="FilterTabIndex == 2" @orderTotal="getOrderTotal"
          @tabsIndex="changeTabsIndex"></HistoryOrder>
      </div>

      <!-- 底栏 -->
      <FilterBalance :profitTotal="profitTotal" v-if="FilterTabIndex == 0 || FilterTabIndex == 1"></FilterBalance>
      <HistoryBalance ref="HistoryBalance" :HistoryOrderTotal="HistoryOrderTotal" :historyTabIndex="historyTabIndex"
        :filterParams="filterParams" v-if="FilterTabIndex == 2"></HistoryBalance>

      <!-- 注册登录 -->
      <LoginAndRegister ref="loginPop" :traders="traders"></LoginAndRegister>

      <!-- 注册信息 -->
      <RegisterInfo ref="registerInfoPop"></RegisterInfo>

      <!-- 品种详情 -->
      <MarketInfo ref="marketInfo"></MarketInfo>

      <!-- 当前价格平仓弹窗 -->
      <ClosePosition ref="closePosition" @closePosition="okChange"></ClosePosition>

      <!-- 首次登录协议提示 -->
      <Statement @userFn="userFn"></Statement>
    </div>
  </div>
</template>
<script>
import { upUserData, readDb } from "@/utils/userUtils"
import { appUrl } from "@/utils/appUrls";
import MarketInfo from '@/components/market/MarketInfo.vue'
import MarketModule from '@/components/market/MarketModule.vue'
import TopSidebar from '@/components/topSidebar/topSidebar.vue'
import OptionalTab from '@/components/optionalList/optionalTab.vue'
import OptionalList from '@/components/optionalList/optionalList.vue'
import FilterTab from '@/components/filterList/filterTab.vue'
import FilterList from '@/components/filterList/filterList.vue'
import HistoryOrder from '@/components/historyOrder/historyOrder.vue'

import { coppyArray, asignObj } from "@/utils/utils"
import TradeView from '@/components/TradeView'
import FilterBalance from '@/components/filterList/filterBalance.vue'
import SubmitTrade from "@/components/trade/submit.vue"
import UpdateTrade from "@/components/trade/update.vue"
import HistoryBalance from '@/components/historyOrder/historyBalance.vue'
import BookList from '@/components/filterList/bookList.vue'
import LoginAndRegister from '@/components/login/loginAndRegister.vue'
import RegisterInfo from '@/components/login/RegisterInfo.vue'
import ClosePosition from '@/components/filterList/closePosition.vue'
import LoginPopover from '@/components/login/loginPopover.vue'
import Statement from '@/components/statement/statement.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {
  appUtils,
} from "@/utils/appUtils.js"

export default {
  name: 'IndextView',
  components: {
    MarketModule,
    MarketInfo,
    OptionalList,
    FilterList,
    OptionalTab,
    FilterTab,
    TopSidebar,
    TradeView,
    HistoryOrder,
    FilterBalance,
    SubmitTrade,
    UpdateTrade,
    HistoryBalance,
    BookList,
    LoginAndRegister,
    RegisterInfo,
    ClosePosition,
    LoginPopover,
    Statement,
    Splitpanes,
    Pane,
  },
  data() {
    return {
      ws: null,
      tradeMsg: '',
      items: [],
      varietyList: [],
      showVarietyList: [],
      defaultShowList: [],
      showSymbols: [],
      allSymbolNameArr: [],
      loadOk: false,
      showUser: false,
      showSubmitOrder: false,
      showUpdateOrder: false,
      isShowRight: true,
      isLogin: false,
      sendMsg: "",
      serviceUrl: '',
      rightIndex: 0, //右侧导航栏选中下标
      FilterSize: '30', //左下部分显示大小
      showChart: false, //图表加载
      tradeViewHeight: 670,
      tradeItem: null,
      orderItem: null,
      profitAndLossTotal: 0,
      profitTotal: 0,
      hangOrders: [],
      orders: [],
      klineOrders: [], //k线图持仓
      klineHanders: [], //k线图挂单
      heartbeatInterval: null,
      health: false,
      unHealthyNum: 0,
      varietyData: {},
      FilterTabIndex: 0,
      loadIndex: 0,
      closeOrderItem: {},
      isSubmiting: false,
      chartLoaing: true, //图表样式插入完成
      chartType: 1, //图表类型
      chartPeriod: '1', //图表周期
      firstLoading: true, //品种模块加载
      filterParams: {
        symbol: this.$t('history.allVariety'),
        searchSort: '',
        searchType: '',
        createTimeStart: 0,
        createTimeEnd: 0,
        num: 1,
        size: 20
      },
      historyTabIndex: 0,
      HistoryOrderTotal: 0,
      traders: [], //服务商
      collectList: [], //用户收藏的品种
      symbolListShow: true, //是否显示品种列表
      chartResetLoaing: true, //图表切换品种或者切换周期时的加载状态 
      tradeWidth: { pWidth: 108, cWidth: 80 }, //头部打开新订单初始宽度值
      sendFailHeart: false,//判断图表内部接口是否异常
      reStartApping: false,
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   let myDivElement = this.$refs.modelBack;
    //   if (myDivElement) {
    //     // 获取高度
    //     let height = myDivElement.clientHeight;
    //     this.tradeViewHeight = height * 0.65
    //   }
    // })
    //初始化
    this.appInit()
  },
  watch: {
    '$store.state.upDataMsg'(newValue, oldValue) {
      this.upAppInfo()
    },
    '$store.state.upOrderList'(data) {
      if (!data) {
        return
      }
      let orderId = data.orderId
      let type = data.type
      if (type == 1) {
        //挂单
        this.removeHangOrderById(orderId)
      } else {
        //持仓
        this.removeOrderById(orderId)
      }
    },
    '$store.state.upCollectList'(arr) {
      this.collectList = arr; //更新收藏列表
      // console.log('首页收藏列表已经更新');
    },
    '$store.state.reStartApp'(data) {
      if (this.reStartApping) {
        return
      }
      this.reStartApping = true
      //断开ws
      if (this.ws) {
        this.closeWs()
      }
      //token过期 清除
      //清除caceh vuex
      this.$storage.setCache('userInfo', null)
      this.$store.commit('upUserInfo', null)

      setTimeout(() => {
        this.reStartApping = false
        this.$router.push('/loading')
      }, 500);

    }
  },
  computed: {
    allOrderSymbol() { //合并持仓与挂单品种名称
      let symbolArr = [];
      this.klineOrders.forEach(item => {
        if (symbolArr.indexOf(item.symbol) == -1) {
          symbolArr.push(item.symbol)
        }
      });
      this.klineHanders.forEach(item => {
        if (symbolArr.indexOf(item.symbol) == -1) {
          symbolArr.push(item.symbol)
        }
      });
      return symbolArr;
    }
  },
  //注销页面，断开ws
  destroyed() {
    if (this.ws) {
      this.closeWs()
    }
  },
  methods: {
    //关闭品种列表列设置弹窗
    closeHead() {
      if (this.$refs.marketModule) {
        this.$refs.marketModule.closeHead();
      }
    },
    //截图
    ShotHandle() {
      if (this.chartLoaing) { return false }
      this.$refs.klineChart.ShotHandle();
    },
    fullScreenHandle() {
      if (this.chartLoaing) { return false }
      this.$refs.klineChart.fullScreenHandle();
    },
    //添加指标
    studyHandle() {
      if (this.chartLoaing) { return false }
      this.$refs.klineChart.studyHandle();
    },
    //图表样式载入
    loadingChart(flag) {
      this.chartLoaing = flag;
      // console.log(this.chartLoaing,'图表加载状态完毕关闭弹窗');
    },
    //切换图表类型
    changeKlineType(index) {
      if (this.chartLoaing) { return false }
      this.chartType = index;
      this.$refs.klineChart.changeKlineType(index);
    },
    //切换图表周期
    changeKlinePeriod(period) {
      if (this.chartResetLoaing) { return false }
      if (this.sendFailHeart) { //图表内部接口异常直接重载
        this.resetChartApi();
        return false;
      }
      if (this.chartLoaing) { return false }
      this.chartPeriod = period;
      this.$refs.klineChart.changeKlinePeriod(period);
    },
    //图表切换品种切换周期重载状态
    chartResetHandle(flag) {
      this.chartResetLoaing = flag;
    },
    //图表下单
    tradeShow(item) {
      if (this.userInfo) {
        if (this.showSubmitOrder && item && item.symbol == this.tradeItem.symbol) { //用户点击了同一个品类列表的新订单按钮
          return false;
        }
        this.showUpdateOrder = false
        if (item) {
          this.showSubmitOrder = true
          this.symbolClick(item); //点击品类切换图表
        } else {
          this.showSubmitOrder = !this.showSubmitOrder
        }
        //
      } else {
        this.$refs.loginPop.show()
      }
    },
    //品种列表显示
    showSymbolList() {
      this.symbolListShow = !this.symbolListShow;
    },
    //点击品类切换
    symbolClick(item) {
      if (this.chartResetLoaing) { return false }
      if (item.symbol == this.tradeItem.symbol) { return false }
      this.showUpdateOrder = false
      if (this.sendFailHeart) { //图表内部接口异常直接重载
        this.resetChartApi();
      }
      if (this.chartLoaing) { return false }
      //订单传过来的item不是完整的item 需要去找到list中的该品类
      if (!item.minVolume) {
        item = this.showVarietyList.find(e => { return e.symbol == item.symbol })
      }
      this.tradeItem = item;
      this.varietyData = item;
      this.chartPeriod = 1; //初始化图表周期选项
      //如果显示下单切换品类重新刷新下单组件
      if (this.showSubmitOrder) {
        this.showSubmitOrder = false
        this.$nextTick(e => {
          this.showSubmitOrder = true
        })
      }
    },
    //修改订单
    updateOrder(item) {
      this.orderItem = item
      //订单传过来的item不是完整的item 需要去找到list中的该品类
      let tradeItem = this.showVarietyList.find(e => { return e.symbol == item.symbol })
      this.tradeItem = tradeItem;
      this.varietyData = tradeItem
      this.showSubmitOrder = false
      if (this.showUpdateOrder) {
        this.showUpdateOrder = false
        this.$nextTick(e => {
          this.showUpdateOrder = true
        })
      } else {
        this.showUpdateOrder = true
      }
    },
    //点击获取品种详情
    symbolInfo(row) {
      this.$refs.marketInfo.openDialog(row);
    },
    //点击收藏判断未登录
    goLogin() {
      this.$refs.loginPop.show();
    },
    //接口失效重载图表
    resetChartApi() {
      this.chartLoaing = true; //开启图表加载
      this.destoryChart(); //销毁图表
      this.showChart = false //重新渲染图表
      this.chartPeriod = 1; //初始化图表周期选项
      this.chartType = 1; //初始化图表类型
      this.$nextTick(e => {
        let language = this.$store.state.language;
        this.showChart = true;
        this.tradeWidth = this.reSetTradeWidth(language);
      })
    },
    //图表加载的接口失败后的回调
    chartFailSend(flag) {
      this.sendFailHeart = flag;
    },
    //切换语言
    changeLang(language) {
      if (!language) { return false };
      this.resetChartApi();
    },
    //切换语言重置顶部订单标题宽度
    reSetTradeWidth(language = 'zh') {
      let tradeWidth = { pWidth: 108, cWidth: 80 };
      switch (language) {
        case "zh-Hans":
          tradeWidth = { pWidth: 108, cWidth: 80 };
          break
        case "zh-Hant":
          tradeWidth = { pWidth: 108, cWidth: 80 };
          break
        case "en":
          tradeWidth = { pWidth: 124, cWidth: 96 };
          break
        case "ja":
          tradeWidth = { pWidth: 116, cWidth: 88 };
          break
        case "es":
          tradeWidth = { pWidth: 150, cWidth: 122 };
          break
        case "ru":
          tradeWidth = { pWidth: 148, cWidth: 120 };
          break
        case "fr":
          tradeWidth = { pWidth: 210, cWidth: 182 };
          break
        case "de":
          tradeWidth = { pWidth: 154, cWidth: 126 };
          break
        default:
          tradeWidth = { pWidth: 108, cWidth: 80 };
      }
      return tradeWidth;
    },
    //初始化
    appInit() {
      //获取品类
      this.getVarietyList()
      //获取交易商
      this.getTraders()
      //初始化头部订单宽度
      this.tradeWidth = this.reSetTradeWidth(this.$store.state.language);
      //判断是否已登录
      if (this.userInfo) {
        //已登录
        this.getUserWallet()
        //获取order接口，取出持有的品类
        this.getOrder()
        //是否是刚注册，显示注册信息

        let registerInfo = this.$global.userInfo
        if (registerInfo) {
          //弹窗显示注册信息
          this.$refs.registerInfoPop.show()
          this.$global.userInfo = null
        }
      } else {
        //未登录

      }
    },
    //获取品类
    getVarietyList() {
      this.$api.variety.getVarietyList().then((res) => {
        if (res && res.code == 200) {
          let symbolList = res.data
          //取出所有品类名称
          this.allSymbolNameArr = symbolList.map(item => (item.symbol))
          //获取品类最后一日数据
          let param = {
            "symbol": this.allSymbolNameArr
          }
          this.$api.historical.getDayList(param).then((res) => {
            let dayList = res.data

            //数据合并处理
            symbolList.forEach(item => {
              let symbol = dayList.find(e => { if (e) return e.symbol == item.symbol })
              this.asignObj(item, symbol)
            })

            this.$global.varietyList = symbolList
            this.varietyList = symbolList
            this.defaultShowList = this.varietyList
            this.setShowVarietyList()
            this.firstLoading = false //品类模块加载完毕
            this.loadOk = true
            this.getTradeItem(this.showVarietyList[0]);
          })
        }


      })
    },
    //获取图表品种的对象
    getTradeItem(item) {
      this.tradeItem = item;
    },
    //订阅
    subscribeMarketMsg() {
      let msgArr = []
      this.showVarietyList.forEach(e => {
        msgArr.push(e.symbol)
      })
      let msg = { "cmd": "subscript", "param": this.allSymbolNameArr }
      this.sendMsg = JSON.stringify(msg)
      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
      //链接ws
      this.initWs()

    },
    //更新数据
    upMsgs(arr) {
      this.showVarietyList = arr
      this.$global.symbols = arr
      // this.getTradeItem
      let findSymbol = this.tradeItem.symbol;
      let item = arr.find(item => { return item.symbol == findSymbol });
      if (item) {
        this.getTradeItem(item);
      }
      this.setMsgForOrder(arr)
      //uni.$emit('tradeKline',arr)
      //uni.$emit('tradeDatas', arr)
      //判断是否要保存行情数据

    },
    //获取日涨幅，日%
    getChg(item) {
      let chgObj = {
        chg: 0,
        chgRate: 0
      }
      let currentItem = this.items.find(e => { return e.symbol == item.symbol })
      if (currentItem) {
        if (!item.price) {
          return chgObj
        }
        //涨幅 = ( 当前价 * 10 的 品类小数长度 幂) - ( 昨天收盘价 * 10 的 品类小数长度 幂)
        chgObj.chg = this.getSpread(item.price, currentItem.closePrice, item.decimalPlaces)
        //涨幅比例 = (当前价 - 昨天收盘价) / 昨天收盘价 * 100 保留两位小数
        chgObj.chgRate = (((item.price - currentItem.closePrice) / currentItem.closePrice) * 100).toFixed(2)
      }
      return chgObj
    },
    //获取点差
    getSpread(ask, bid, decimalPlaces) {
      //点差 =( ask * 10 的 品类小数长度 幂) - ( bid * 10 的 品类小数长度 幂)
      return Math.round((parseFloat(ask) * Math.pow(10, decimalPlaces)) - (parseFloat(bid) * Math.pow(10, decimalPlaces)))
    },
    //合并数据
    asignObj,
    //深拷贝
    coppyArray,
    //初始化数据
    setShowVarietyList() {

      //判断是否已登录
      if (this.userInfo) {
        //已登录
        this.showVarietyList = this.defaultShowList
        this.getCollectList();
        this.subscribeMarketMsg()
      } else {
        //未登录
        //取VarietyList默认显示行情
        this.showVarietyList = this.defaultShowList
        this.showChart = true; //开启图表
        this.subscribeMarketMsg()
      }
    },
    //获取收藏列表
    getCollectList() {
      let that = this;
      readDb(this, this.userInfo, function (e) { //获取收藏列表
        if (e[0].collectList && e[0].collectList.length > 0) {
          that.collectList = e[0].collectList;
          let defaultSymbol = that.showVarietyList[0].symbol;
          if (that.collectList.indexOf(defaultSymbol) == -1) {
            let newCollects = that.showVarietyList.filter(item => {
              return that.collectList.indexOf(item.symbol) > -1
            });
            that.getTradeItem(newCollects[0]);
          }
        } else {
          let showList = [];
          showList = that.defaultShowList.slice(0, 11);
          let newUserInfo = JSON.parse(JSON.stringify(that.userInfo));
          that.collectList = showList.map(item => {
            return item.symbol;
          });
          newUserInfo.collectList = that.collectList;
          that.$store.commit("upUserInfo", newUserInfo);
          upUserData(that, newUserInfo);
        }
        that.showChart = true;
      })
    },
    //获取品类默认显示
    getDefaultShowVarietyList() {
      return this.varietyList.filter(e => {
        return e.isShow == 1
      })
    },
    //行情信息处理
    setMsgFn(msgs) {
      //websocket数据与api 数据合并
      if (!msgs || this.showVarietyList.length < 1) {
        return
      }
      let arr = this.coppyArray(this.showVarietyList)
      //遍历找到对应品类更新
      arr.forEach(e => {
        let symbols = msgs.filter(msg => { return e.symbol == msg.symbol })
        if (symbols && symbols.length > 0) {
          let msg = symbols[symbols.length - 1]
          //和上一次比较的涨跌
          let oldBid = e.bid
          let oldAsk = e.ask
          let newBid = msg.bid
          let newAsk = msg.ask
          e.changeBid = parseFloat(newBid) - parseFloat(oldBid)
          e.changeAsk = parseFloat(newAsk) - parseFloat(oldAsk)
          e.timeStamp = msg.time_stamp
          e.spread = this.getSpread(msg.ask, msg.bid, e.decimalPlaces)
          msg.decimalPlaces = e.decimalPlaces
          let chgObj = this.getChg(msg)
          e.chg = chgObj.chg
          e.chgRate = chgObj.chgRate
          //合并数据
          this.asignObj(e, msg);
        }
      })
      //更新数据
      this.upMsgs(arr);
    },
    initWs() {
      let wsUrl = ""

      if (this.userInfo) {
        wsUrl = appUrl.setHttpBase(this.userInfo.appService.url).wsHead
      } else {
        wsUrl = appUrl.setHttpBase(this.defaultService.url).wsHead
      }

      this.ws = new WebSocket(wsUrl)

      this.ws.onopen = () => {
        this.ws.send(this.sendMsg)
        if (this.userInfo) {
          //已登录ws绑定用户
          let bindMsg = { "cmd": "user_bind", "param": this.userInfo.token }
          this.ws.send(JSON.stringify(bindMsg))
        }
      }
      this.unHealthyNum = 0
      this.health = true
      //启动心跳
      this.startHeartbeat()

      this.ws.onmessage = (data) => {
        let msg = data.data
        if (msg == 'pong') {
          return
        }
        if (msg == 'update_trade') {
          //同步端需要更新的消息
          this.upAppInfo()
          return
        }
        try {
          let msgObj = JSON.parse(msg)
          if (msgObj && msgObj.type) {
            let type = msgObj.type
            if (type == 'trade_update') {
              //同步消息
              this.upAppInfo()
            } else if (type == 'market') {
              //行情消息
              let msgs = msgObj.data
              if (Array.isArray(msgs)) {
                //如果收到回执，健康状态改为正常
                this.health = true
                //接收行情消息
                this.showSymbols = msgs
                this.setMsgFn(msgs)
              }
            }
          }
        } catch (error) {
          //其他类型的消息
        }


      }
    },
    //重连ws
    reStartWs() {
      this.clearHeart()
      this.initWs()
    },
    //断开ws
    closeWs() {
      this.clearHeart()
      try {
        this.ws.close()
      } catch (error) {
        console.log(error)
      }
    },
    //清除ws心跳
    clearHeart() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval)
        this.heartbeatInterval = null
      }
    },
    //开启心跳检测
    startHeartbeat() {
      this.clearHeart()
      this.heartbeatInterval = setInterval(() => {
        //心跳周期，判断健康状态
        if (!this.health) {
          //不健康次数+1
          this.unHealthyNum++
          console.log('ws没收到行情消息' + this.unHealthyNum + "次")
          if (this.unHealthyNum >= 2) {
            console.log('ws没收到行情消息次数达最大值，需要进行重连')
            //如果不健康次数大于最大数 断开重连
            this.reStartWs()
          }
        } else {
          // console.log("ws:正常")
          //健康，重置不健康次数
          this.unHealthyNum = 0
        }
        //发心跳消息
        try {
          this.ws.send("ping")
          //发出心跳消息后将健康状态设为false
          this.health = false
        } catch (error) {
          console.log(error)
        }

      }, 5000)
    },

    //切换右侧导航	
    changeRightModel(val) {
      if (this.rightIndex == val) {
        this.isShowRight = !this.isShowRight
      } else {
        this.rightIndex = val
        this.isShowRight = true
      }
    },
    reFreshIndex() {
      this.$router.replace('/loading')
    },
    //图表销毁
    destoryChart() {
      this.$refs.klineChart.destoryChart()
    },
    //登录回调
    loginBack() {
      this.closeWs()
      this.destoryChart()
      //刷新页面
      this.reFreshIndex()
    },
    //退出回调
    logoutBack() {
      this.closeWs()
      this.destoryChart()
      //刷新页面
      this.reFreshIndex()
    },
    //获取订单
    getOrder() {
      this.$api.order.listOrder().then(res => {
        if (res && res.code == 200) {
          this.orders = res.data.orderList;
          this.hangOrders = res.data.orderHangList;
          this.klineOrders = res.data.orderList.map(item => {
            return {
              id: item.id,
              loss: item.loss,
              profit: item.profit,
              volume: item.volume,
              symbol: item.symbol,
              openingPrice: item.openingPrice,
              direction: item.direction
            }
          });
          this.klineHanders = res.data.orderHangList.map(item => {
            return {
              id: item.id,
              openingPrice: item.openingPrice,
              tradeType: item.tradeType,
              breakStatus: item.breakStatus,
              volume: item.volume,
              symbol: item.symbol
            }
          });
          //查找订单品类
          this.findOrderSymbol();
        }
      })
    },
    //行情信息处理
    setMsgForOrder(msgs) {

      //判断已登录且用户有持仓订单，计算盈利
      if (this.userInfo && this.orders.length > 0) {
        //根据最新行情进行计算
        let changeObj = appUtils.profitAndLossTotal(msgs, this.orders, this)
        //更新持仓订单
        this.orders = changeObj.orderDatas
        //更新图表持仓
        let klineOrdes = [];
        changeObj.orderDatas.forEach(item => {
          let obj = {};
          obj.id = item.id;
          obj.loss = item.loss;
          obj.profit = item.profit;
          obj.volume = item.volume;
          obj.symbol = item.symbol;
          obj.openingPrice = item.openingPrice;
          obj.direction = item.direction;
          klineOrdes.push(obj);
        })
        this.klineOrders = klineOrdes;

        //获取盈利
        this.profitAndLossTotal = changeObj.profitAndLossTotal
        this.profitTotal = changeObj.profitTotal
        this.orders.forEach(e => {
          if (this.orderItem && e.id == this.orderItem.id) {
            this.orderItem = e
          }
        })

      } else {
        //默认盈利为0
        this.profitAndLossTotal = 0
        this.profitTotal = 0
      }

      //判断已登录且用户有挂单订单
      if (this.userInfo && this.hangOrders.length > 0) {
        //根据最新行情进行计算
        let changeObj = appUtils.profitAndLossTotal(msgs, this.hangOrders, this, false)
        let klineHanders = [];
        //更新持仓订单
        this.hangOrders = changeObj.orderDatas
        //更新图表挂单
        changeObj.orderDatas.forEach(item => {
          let obj = {};
          obj.id = item.id;
          obj.openingPrice = item.openingPrice;
          obj.tradeType = item.tradeType;
          obj.breakStatus = item.breakStatus;
          obj.volume = item.volume;
          obj.symbol = item.symbol;
          klineHanders.push(obj);
        })
        this.klineHanders = klineHanders;
        this.hangOrders.forEach(e => {
          if (this.orderItem && e.id == this.orderItem.id) {
            this.orderItem = e
          }
        })
      }
    },
    //查找是否有购买的品类，做标识
    findOrderSymbol() {
      let orderArr = this.getOrderSymbolArr()
      //获取用户缓存
      this.getUserCache(orderArr)
    },
    //获取订单品类数据
    getOrderSymbolArr() {
      let arr = new Set()
      this.orders.forEach(e => {
        arr.add(e.symbol)
      })
      this.hangOrders.forEach(e => {
        arr.add(e.symbol)
      })
      let orderArr = Array.from(arr)
      this.$store.commit('upOrderArr', orderArr);
      return orderArr
    },
    //获取用户缓存
    getUserCache(orderSymbols = []) {
      //订阅
      // this.subscribeMarketMsg()
    },
    //关闭交易窗口
    closeBack() {
      this.showSubmitOrder = false
    },
    //关闭修改订单窗口
    closeUpdateBack() {
      this.showUpdateOrder = false
    },
    //提交持仓回调
    openOrderBack() {
      this.closeBack()
      this.changeFilterTabIndex(0)
    },
    //提交挂单回调
    openHangOrderBack() {
      this.closeBack()
      this.changeFilterTabIndex(1)
    },
    //修改持仓回调
    updateOrderBack(data) {
      //找到订单更新数据
      this.orders.forEach(e => {
        if (e.id == data.id) {
          e.loss = data.loss
          e.profit = data.profit
        }
      })
      this.closeUpdateBack()
    },
    //修改挂单回调
    updateHangOrderBack(data) {
      //找到订单更新数据
      this.hangOrders.forEach(e => {
        if (e.id == data.id) {
          e.loss = data.loss
          e.profit = data.profit
          e.breakPrice = data.breakPrice
          e.openingPrice = data.openingPrice
        }
      })
      this.closeUpdateBack()
    },
    changeFilterTabIndex(e) {
      this.FilterTabIndex = e
    },
    removeOrderById(id) {
      // this.orders = this.orders.filter(e => { return e.id != id })
      // this.klineOrders = this.klineOrders.filter(e => { return e.id != id })
      // this.getOrderSymbolArr()
      // this.getUserWallet()
    },
    removeHangOrderById(id) {
      // this.hangOrders = this.hangOrders.filter(e => { return e.id != id })
      // this.klineHanders = this.klineHanders.filter(e => { return e.id != id })
      // this.getOrderSymbolArr()
      // this.getUserWallet()
    },
    closeOrderFn(item) {
      if (!this.isSubmiting) {
        this.isSubmiting = true
        this.closeOrderItem = item
        let orderItem = item
        let currentPrice = orderItem.direction == 1 ? parseFloat(orderItem.currentBid) : parseFloat(orderItem
          .currentAsk)
        let param = {
          "orderNo": orderItem.orderNo,
          "currentPrice": currentPrice,
        }
        this.$api.order.closeOrder(param).then(res => {
          this.isSubmiting = false
          if (res && res.code == 200) {
            this.removeOrderById(orderItem.id)
          } else if (res && res.code == 412) {
            //价格差 提示用户是否以最新价格平仓
            this.$refs.closePosition.openDialogVis()
          }
        })
      }
    },
    //同意以最新价平仓
    okChange() {
      let orderItem = this.closeOrderItem
      let currentPrice = orderItem.direction == 1 ? parseFloat(orderItem.currentBid) : parseFloat(orderItem
        .currentAsk)
      let param = {
        "orderNo": orderItem.orderNo,
        "currentPrice": currentPrice
      }
      this.$api.order.closeOrderByMarketPrice(param).then(res => {
        if (res && res.code == 200) {
          this.removeOrderById(orderItem.id)
        }
      })
    },
    //取消订单
    restoredOrderFn(item) {
      if (!this.isSubmiting) {
        this.isSubmiting = true
        let param = {
          "id": item.id
        }
        this.$api.orderHang.orderRestored(param).then(res => {
          if (res && res.data) {
            this.removeHangOrderById(item.id)
          }
          this.isSubmiting = false
        })
      }
    },
    //更新数据
    upAppInfo() {
      this.getOrder()
      this.getUserWallet()
      // this.refreshHistory = false
      // this.$nextTick(e => {
      // 	this.refreshHistory = true
      // })
    },
    //获取用户钱包信息
    getUserWallet() {
      this.$api.userWallet.userWallet().then(res => {
        if (res && res.data) {
          //更新用户钱包信息
          this.userInfo.wallet = res.data;
          //刷新订单页面
          this.$forceUpdate()
        }
      })
    },
    //切换的历史订单标签
    changeTabsIndex(val) {
      this.historyTabIndex = val
    },
    getOrderTotal(val) {
      this.HistoryOrderTotal = val
    },
    //用户菜单
    userFn(e) {
      this.$refs.loginPop.show()
      if (e != 3) {
        this.$refs.loginPop.changType(e)
      }
    },
    //获取服务商
    getTraders() {
      this.$api.application.getTraders().then(res => {
        if (res && res.data) {
          this.traders = res.data
        }
      })
    },
    //历史订单筛选条件
    changeFilter(val) {
      this.filterParams.symbol = val.symbolValue
      this.filterParams.createTimeStart = val.closeTimeStart;
      this.filterParams.createTimeEnd = val.closeTimeSEnd;
      this.$refs.HistoryBalance.againSearch()
    },
  }
}
</script>
<style lang="scss">
.scroll-box {
  overflow-y: scroll;
}

.page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page-top {
  display: flex;
  align-items: center;
  padding: 0px 16px 0 10px;
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
  background-color: #BFD4FF;
}

.menu-left {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.chart-control {
  display: flex;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;

  .chart-item {
    cursor: pointer;
    align-items: center;
    border-right: 1px solid rgba(37, 104, 255, 0.1);
    width: 60px;
    display: flex;
    justify-content: center;

    .chart-item-bg {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 6px 6px;

      &:hover {
        background-color: #ADC5F5;
      }
    }

    .chart-init-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .left-icon {
        margin-right: 4px;
      }

      .right-icon {
        line-height: 1;
        text-align: left;
      }
    }

    .active {
      .right-icon {
        color: #2568FF;
      }
    }

    .chart-item-icon {
      width: 22px;
      height: 20px;
      cursor: pointer;
    }

    &:last-child {
      border-right: none;
    }
  }

  .chart-persiod {
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-right: 1px solid rgba(37, 104, 255, 0.1);

    .persiod-item {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      margin-right: 14px;
      cursor: pointer;
      border-radius: 6px 6px 6px 6px;

      &:hover {
        background-color: #ADC5F5;
      }
    }

    .active {
      color: #2568FF;
    }
  }
}

.chart-control-rt {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;

  .chart-item {
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(37, 104, 255, 0.1);
    width: 60px;
    justify-content: center;

    .chart-item-bg {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 6px 6px;

      &:hover {
        background-color: #ADC5F5;
      }
    }

    .chart-item-icon {
      width: 22px;
      height: 20px;
      cursor: pointer;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.page-main {
  height: 100%;
  flex: 1;
  background-color: #EAF0F6;
  display: flex;
  flex-direction: column;

  .page-main-center {
    display: flex;
    margin-top: 8px;
    height: 540px;
  }

  .page-main-bottom {
    height: 100%;
    flex: 1;
    margin-top: 8px;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    padding-bottom: 40px;
  }

  .chart-box {
    position: relative;
    flex: 1;
    background-color: #fff;
  }

  .trade-box,
  .login-box {
    width: 280px;
    margin-left: 8px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
  }

  .trade-box {
    width: 320px;
  }

  .symbols-box {
    box-sizing: border-box;
    width: 360px;
    // margin-left: 8px;
    background-color: #fff;
    border-radius: 8px 0px 0px 8px;
    padding: 14px 0px 0px 0px;
    overflow: hidden;
  }
}

.chart-loading-box {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 8px 8px 0;
}

.chart-loaing {
  width: 60px;
  height: 60px;
  border: 2px solid#2B64FF;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu-box {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #ADC5F5;
    border-radius: 6px;
  }

  .menu-icon {
    width: 22px;
    height: 20px;
  }

}

.rela {
  position: relative;
}

.flex {
  display: flex;
}
.splitpanes__pane{
  // background-color: #ffffff !important;
  display: flex;
  &:first-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:last-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
  

<template>
	<div class="topSidebar theme-box">
		
	</div>
</template>

<script>
	export default {
	  data() {
		return {

		}
	  },
	  methods: {

	  }
	}
</script>

<style lang="scss" scoped>
	.topSidebar{
		display: flex;
		align-items: center;
		padding: 0 16px;
		height: 40px;
		background-color: #BFD4FF;
	}
</style>
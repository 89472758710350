<template>
  <div style="height: 100%;width: 100%;">
    <div id="trade-view" class="trade-view" ref="trade_view"></div>
  </div>
</template>

<script>
import { widget as TvWidget } from '@/static/tradeview/charting_library/charting_library.min.js'
import klineConfig from '@/config/klineConfig.js'
// import socket from './datafeeds/socket.js'
import datafeeds from './datafeeds/datafees.js'
// import { dateFomat } from "@/utils/utils"
// import {mapState} from 'vuex'
export default {
  data() {
    return {
      callbackFlag: false,
      widget: null, //构造器
      kchart: null, //图表实例对象
      // socket: new socket(),
      datafeeds: new datafeeds(this),
      symbol: null,
      interval: null,
      cacheData: {},
      lastTime: null,
      getBarTimer: null,
      isLoading: true,
      kStartTime: 0,
      kStartArr: [],//储存当前的起始时间数组
      KlineWsLock: false,
      updateCallback: null,
      updateTick: '',
      decimalPlaces: 100,
      timeMax: 60,
      dragLock: true,
      firstFlag: true,
      useChart: false,
      klineOrders: [], //持仓订单展示的对象数组
      klineHanders: [], //挂单展示的对象数组
      tradeType: 'forex', //品种默认类型
      studys: { //创建图表默认给的指标
        MA: {
          5: '#8996B4',
          10: '#34C85A',
          20: '#FFC122'
        }
      },
      entityIds: [], // 对应的指标实体的id
      chartTimer: null, //图表切换加载的定时器
      chartResetFlag: true, //图表切换状态的锁开关
      sendFailHeart: false //接口请求发送心跳，心跳无回复则通知图表重载
    }
  },
  props: {
    tradeItem: {
      type: Object,
      default: () => { }
    },
    hangOrders: {
      type: Array,
      default: () => []
    },
    orders: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tradeItem: {
      //监听当前品种是否发生变化
      handler(newVal, oldVal) {
        // console.log(newVal.symbol, this.symbol,this.interval);
        if (newVal.symbol == this.symbol) {
          if (this.KlineWsLock) { //开始推送
            const ticker = `${this.symbol}-${this.interval}`;
            if (!this.callbackFlag) { return false }
            this.FormatKlineData(newVal, ticker);
          }
        } else {
          this.initChart(newVal);
          this.widget.setSymbol(this.symbol, this.interval);
          this.getKlineHistory(1445, 1); //初始化1分钟k线图
        }
      }
    },
    symbolOrdes: {
      handler(newVal, oldVal) {
        if (!this.useChart) { return false };
        let newValStr = JSON.stringify(newVal);
        let oldValStr = JSON.stringify(oldVal);
        if (newValStr == oldValStr) { return false };
        this.updateOrderHadle();
      }
    },
    symbolHanders: {
      handler(newVal, oldVal) {
        if (!this.useChart) { return false };
        let newValStr = JSON.stringify(newVal);
        let oldValStr = JSON.stringify(oldVal);
        if (newValStr == oldValStr) { return false };
        this.updateHanders();
      }
    }
  },
  created() {
  },
  mounted() {
    let that = this;
    if (this.tradeItem && JSON.stringify(this.tradeItem) != '{}') {
      this.decimalPlaces = Math.pow(10, this.tradeItem.decimalPlaces); //小数点位
      this.tradeType = this.tradeItem.tradeType;
      this.init(this.tradeItem.symbol, '1');
      this.getKlineHistory(1445, 1); //初始化1分钟k线图

      this.widget.onChartReady(function () {
        that.useChart = true; //可以使用图表方法
        that.$emit('changeUseChart', true);
        //头部新增按钮
        // var button = that.widget.createButton()[0];
        // button.textContent = '模拟生成交易订单';
        // button.addEventListener('click',that.tradeHandle);
        try {
          that.kchart = that.widget.chart();
          that.updateOrderHadle();
          that.updateHanders();
          that.iframeCss();
        } catch (error) {

        }

        // that.creatStudys(); //关闭不给系统默认指标
      });


    }
  },
  computed: {
    //对应品种的持仓
    symbolOrdes() {
      if (!this.symbol) { return null };
      let symbol = this.symbol;
      let ordersArr = this.orders.filter((item) => { return symbol == item.symbol });
      return ordersArr;
    },
    //对应品种的挂单
    symbolHanders() {
      if (!this.symbol) { return null };
      let symbol = this.symbol;
      let handersArr = this.hangOrders.filter((item) => { return symbol == item.symbol });
      return handersArr;
    }
  },
  methods: {
    //vuex语言转换格式
    languageFormat(str = "zh-Hans") {
      let strFormat = str;
      switch (str) {
        case "zh-Hans":
          strFormat = 'zh';
          break
        case "zh-Hant":
          strFormat = 'zh_TW';
          break
        case "en":
          strFormat = 'en';
          break
        case "ja":
          strFormat = 'ja';
          break
        case "es":
          strFormat = 'es';
          break
        case "ru":
          strFormat = 'ru';
          break
        case "fr":
          strFormat = 'fr';
          break
        case "de":
          strFormat = 'de';
          break
        default:
          strFormat = 'zh';
      }
      return strFormat;
    },
    //插入定制的css 
    iframeCss() {
      const iframeBox = document.getElementById('trade-view').children[0];
      const conWindow = iframeBox.contentWindow || iframeBox.contentDocument;
      const doc = conWindow.document;
      const head = doc.getElementsByTagName('head');
      const linkTag = document.createElement('link');
      linkTag.id = 'newstyle';
      // let timeCount = new Date().getTime();
      linkTag.href = '/static/tviframe.css'; //http://192.168.1.28/tviframe.css
      linkTag.setAttribute('rel', 'stylesheet');
      linkTag.setAttribute('type', 'text/css');
      head[0].append(linkTag);
      this.$emit('loadingChart', false);
    },
    //隐藏订单
    tradeOrderHide() {
      if (this.klineOrders.length > 0) {
        this.klineOrders.forEach(item => {
          item.item.remove();
        });
        this.klineOrders = [];
      }
      if (this.klineHanders.length > 0) {
        this.klineHanders.forEach(item => {
          item.item.remove();
        });
        this.klineHanders = [];
      }
    },
    //显示订单 
    orderHanderShow() {
      if (this.klineOrders.length == 0 && this.klineHanders.length == 0) {
        this.updateHanders();
        this.updateOrderHadle();
      }
    },
    //更新挂单
    updateHanders() {
      let that = this;
      if (!that.symbolHanders) { return false };
      this.klineHanders.forEach(item => {
        item.item.remove();
      });
      this.klineHanders = [];
      that.symbolHanders.forEach(item => {
        that.addtradeHandle(item, 2);
      })
    },
    //更新持仓订单
    updateOrderHadle() {
      let that = this;
      if (!that.symbolOrdes) { return false };
      this.klineOrders.forEach(item => {
        item.item.remove();
      })
      this.klineOrders = [];
      that.symbolOrdes.forEach(item => {
        that.addtradeHandle(item, 1);
      })
    },
    //图表生成交易订单
    addtradeHandle(item, type) {
      if (type == 1) { //持仓
        let str = 'buy';
        str = item.direction == -1 ? 'sell' : 'buy';
        let color = item.direction == -1 ? '#F23645' : '#2568ff';
        let orderItem = this.addOrderLine('#' + item.id + ' ' + str + ' ' + item.volume, item.openingPrice, color);
        let obj = {};
        obj.id = item.id;
        obj.item = orderItem;
        this.klineOrders.push(obj);
        if (item.loss) {
          obj = {};
          let lossItem = this.addOrderLine('#' + item.id + " SL", item.loss, '#F42147');
          obj.id = item.id;
          obj.item = lossItem;
          this.klineOrders.push(obj);
        }
        if (item.profit) {
          obj = {};
          let profitItem = this.addOrderLine('#' + item.id + " TP", item.profit, '#089981')
          obj.id = item.id;
          obj.item = profitItem;
          this.klineOrders.push(obj);
        }
      } else {
        let str = '#' + item.id;
        if (item.tradeType == 1) {
          str = str + ' buy limit ' + item.volume;
        } else if (item.tradeType == 2) {
          str = str + ' sell limit ' + item.volume;
        } else if (item.tradeType == 3) {
          str = str + ' buy stop ' + item.volume;
        } else if (item.tradeType == 4) {
          str = str + ' sell stop ' + item.volume;
        } else if (item.tradeType == 5) {
          str = item.breakStatus == 1 ? str + ' buy limit ' + item.volume : str + ' buy stop limit ' + item.volume
        } else if (item.tradeType == 6) {
          str = item.breakStatus == 1 ? str + ' sell limit ' + item.volume : str + ' sell stop limit ' + item.volume
        }
        let obj = {};
        obj.id = item.id;
        let handItem = this.addOrderLine(str, item.openingPrice, '#FF7800');
        obj.item = handItem;
        this.klineHanders.push(obj);
      }
    },
    addOrderLine(str, price, color) {
      let item = this.kchart.createPositionLine().setQuantity(0).setText(str).setPrice(price).setLineColor(color).setBodyBorderColor(color).setBodyBackgroundColor('rgba(255,255,255,0)').setBodyTextColor(color).setQuantityBorderColor(color).setQuantityBackgroundColor(color).setQuantityTextColor('#FFFFFF');
      return item;
    },
    //创建默认指标 
    creatStudys() {
      let that = this;
      let mas = this.studys.MA;
      for (let key in mas) {
        let entityItem = that.kchart.createStudy('Moving Average', false, false, [key, 'close', 0], null, { 'Plot.color': mas[key] });
        that.entityIds.push(entityItem);
      }
    },
    //删除创建的指定指标
    delStudys(entityId) {
      this.kchart.removeEntity(entityId);
    },
    //图表拍照
    ShotHandle() {
      console.log(this.widget, '拍照需导入新的图表库');
      console.log(this.kchart, '表的实体');
    },
    //图表全屏
    fullScreenHandle() {
      console.log(this.widget.startFullscreen, '全屏需导入新的图表库');
    },
    //图表设置
    settingHandle() {
      this.kchart.executeActionById('chartProperties');
    },
    //图表指标
    studyHandle() {
      this.kchart.executeActionById('insertIndicator');
    },
    //图表周期切换
    changeKlinePeriod(period) {
      this.kchart.setResolution(period);
    },
    //k线图类型切换
    changeKlineType(type) {
      this.kchart.setChartType(type);
    },
    //通知外部下单弹窗显示
    tradeShow() {
      this.$emit('tradeShow', true); //通知外部可以下单
    },
    init(symbol = 'EURUSD', interval = "1") {
      if (!this.widget) {
        let language = this.$store.state.language; //获取vuex的语言
        language = this.languageFormat(language);
        this.widget = new TvWidget({
          symbol: symbol, //品种
          interval: interval, //周期
          // fullscreen: true, //是否占用窗框所有可用的空间
          autosize: true,
          container_id: 'trade-view', //元素html的id
          datafeed: this.datafeeds, //jsApi数据处理
          library_path: '/charting_library/', //图表库
          // edit_buttons_in_legend 隐藏掉图表左上角的编辑按钮 legend_widget隐藏整个左上角 show_hide_button_in_legend左上角眼睛按钮 format_button_in_legend 左上角设置按钮
          disabled_features: ['show_hide_button_in_legend', 'timeframes_toolbar', 'pane_context_menu', 'use_localstorage_for_settings', 'header_widget', 'header_symbol_search', 'symbol_search_hot_key', 'go_to_date', 'header_compare', 'header_undo_redo', 'compare_symbol', 'countdown'], //禁用的工具栏
          enabled_features: [], //启用的工具栏
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, //时区
          locale: language, //多语言
          debug: false, //是否开启控制台
          time_frames: [], //可以设置底部跳转的时间范围
          toolbar_bg: '#FFFFFF', //工具栏颜色
          overrides: klineConfig,
          // studies_overrides:{
          //   'moving average.short:plot.color':"#FFFFFF"
          // }
          // height: 650,
          // width:'100%' 
        })
        this.symbol = symbol
        this.interval = interval
      }
    },
    //销毁图表
    destoryChart() {
      if (!this.widget) {
        return false;
      }
      this.widget.remove();
    },
    //初始化全部条件切换品种
    initChart(item) {
      this.firstFlag = true;
      this.dragLock = true;
      this.KlineWsLock = false;
      this.interval = "1";
      this.timeMax = 60;
      this.symbol = item.symbol;
      this.tradeType = item.tradeType;
      this.decimalPlaces = Math.pow(10, item.decimalPlaces);
      this.cacheData = {};
      this.lastTime = null;
      this.getBarTimer = null;
      this.isLoading = true;
      this.kStartTime = 0;
      this.kStartArr = [];//储存当前的起始时间数组
    },
    //周期切换
    initChangePeriod() {
      const ticker = `${this.symbol}-${this.interval}`
      this.cacheData[ticker] = null;
      this.firstFlag = true;
      this.dragLock = true;
      this.KlineWsLock = false;
      this.lastTime = null;
      this.getBarTimer = null;
      this.kStartTime = 0;
      this.kStartArr = [];//储存当前的起始时间数组
    },
    getSymbol() {
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let sessions = '0000-2400:23456';
      if (this.tradeType != 'forex') {
        sessions = '24x7'
      }
      let congfig = {
        'name': this.symbol,
        'timezone': timeZone,
        'minmov': 1,
        'minmov2': 0,
        'pointvalue': 1,
        'fractional': false,
        'session': sessions,
        'has_intraday': true,
        'has_weekly_and_monthly': true,//是否支持周和月周期切换
        'has_no_volume': true,
        'description': this.symbol,
        'pricescale': this.decimalPlaces,
        'ticker': this.symbol,
        'supported_resolutions': ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M']
      }
      // console.log(congfig);
      return congfig;
    },
    //判断是否是同一天
    isSameDay(t1, t2) {
      return new Date(t1).toDateString() === new Date(t2).toDateString();
    },
    //判断是否在同一周
    isSameWeek(timestamp1, timestamp2) {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);

      // 获取日期的星期几（0 表示周日，1 表示周一，...，6 表示周六）
      const dayOfWeek1 = date1.getDay();
      const dayOfWeek2 = date2.getDay();

      // 将星期一作为每周的起始日，将星期日的值由 0 改为 7
      const normalizedDayOfWeek1 = dayOfWeek1 === 0 ? 7 : dayOfWeek1;
      const normalizedDayOfWeek2 = dayOfWeek2 === 0 ? 7 : dayOfWeek2;

      // 获取日期的当周第一天（星期一）
      const firstDayOfWeek1 = new Date(date1);
      firstDayOfWeek1.setDate(date1.getDate() - (normalizedDayOfWeek1 - 1));
      firstDayOfWeek1.setHours(0, 0, 0, 0);

      const firstDayOfWeek2 = new Date(date2);
      firstDayOfWeek2.setDate(date2.getDate() - (normalizedDayOfWeek2 - 1));
      firstDayOfWeek2.setHours(0, 0, 0, 0);

      // 检查两个日期是否在同一周内
      return firstDayOfWeek1.getTime() === firstDayOfWeek2.getTime();
    },
    //判断是否在同一个月
    isSameMonth(timestamp1, timestamp2) {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);
      // 判断年份和月份是否相同
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
    },
    //获取分钟k线图时间间隔最大值
    getTimeMax(interval) {
      switch (interval) {
        case "1":
          this.timeMax = 60;
          break
        case "5":
          this.timeMax = 60 * 5;
          break
        case "15":
          this.timeMax = 60 * 15;
          break
        case "30":
          this.timeMax = 30 * 60;
          break
        case "60":
          this.timeMax = 60 * 60;
          break
        case "240":
          this.timeMax = 240 * 60;
          break
        default:
          this.timeMax = 60;
      }
    },
    //分钟数据格式化
    FormatKlineData(wsItem, ticker) {
      if (this.lastTime) { //查看缓存是否有数据
        //分钟
        if (this.interval != '1D' && this.interval != '1W' && this.interval != '1M') {
          // console.log('分钟线');
          let diffTime = parseInt((wsItem.time_stamp - this.lastTime) / 1000);
          let diffTimeMax = this.timeMax;
          if (diffTime < diffTimeMax) {
            this.diffKlineData(wsItem, ticker)
          } else {
            this.lastTime = wsItem.time_stamp;
            this.addKlineData(wsItem, ticker);
          }
          return false;
        }
        //日线
        if (this.interval == '1D') {
          // console.log('日线');
          let isSameDay = this.isSameDay(this.lastTime, wsItem.time_stamp);
          if (isSameDay) { //同一天
            this.diffKlineData(wsItem, ticker);
          } else { //不是同一天新增
            this.lastTime = wsItem.time_stamp;
            this.addKlineData(wsItem, ticker);
          }
          return false;
        }
        //周线
        if (this.interval == '1W') {
          // console.log('周线');
          let timestamp1 = new Date(this.lastTime);
          let timestamp2 = new Date(wsItem.time_stamp);
          let isSameWeek = this.isSameWeek(timestamp1, timestamp2);
          if (isSameWeek) { //同一周
            this.diffKlineData(wsItem, ticker);
          } else { //不是同一周新增
            this.lastTime = wsItem.time_stamp;
            this.addKlineData(wsItem, ticker);
          }
          return false;
        }
        //月线
        if (this.interval == '1M') {
          // console.log('月线');
          let timestamp1 = new Date(this.lastTime);
          let timestamp2 = new Date(wsItem.time_stamp);
          let isSameMonth = this.isSameMonth(timestamp1, timestamp2);
          if (isSameMonth) { //同一月
            this.diffKlineData(wsItem, ticker);
          } else { //不是同一月新增
            this.lastTime = wsItem.time_stamp;
            this.addKlineData(wsItem, ticker);
          }
          return false;
        }
      } else {
        this.lastTime = wsItem.time_stamp;
        this.addKlineData(wsItem, ticker);
      }
    },
    //ws推送与最新k线柱子比较
    diffKlineData(wsItem, ticker) {
      if (!this.cacheData[ticker]) { return false };
      let nowPrice = Number(wsItem.price); //推送的价格
      let lastItem = this.cacheData[ticker][this.cacheData[ticker].length - 1]; //最新的k线柱子
      let hPrice = Number(lastItem.high) //历史分钟K线图最新的一条数据的最高价格
      let lPrcice = Number(lastItem.low) //历史分钟k线图最新的一条数据的最低价格
      if (lastItem.close == nowPrice) {//检查是否ws推过来的数据发生变化
        // console.log('无变化');
        return false;
      }
      lastItem.close = nowPrice;
      if (nowPrice > hPrice) {
        lastItem.high = nowPrice;
      }
      if (nowPrice < lPrcice) {
        lastItem.low = nowPrice;
      }
      this.cacheData[ticker][this.cacheData[ticker].length - 1] = lastItem;
      if (typeof this.updateCallback === 'function') {
        this.updateCallback(lastItem);
      }

    },
    //新增k线柱子
    addKlineData(wsItem, ticker) {
      if (!this.cacheData[ticker]) { return false };
      // let lastItem = this.cacheData[ticker][this.cacheData[ticker].length-1];
      const barsData = {
        time: Number(wsItem.time_stamp),
        open: Number(wsItem.price),
        high: Number(wsItem.price),
        low: Number(wsItem.price),
        close: Number(wsItem.price),
        volume: 0
      }
      if (this.cacheData[ticker] && this.cacheData[ticker].length > 0) { //有缓存的数据
        let lastItem = this.cacheData[ticker][this.cacheData[ticker].length - 1]; //最新的k线柱子
        barsData.open = lastItem.close; //前一条的k线的收盘价拿来做开盘价 
      }
      this.cacheData[ticker].push(barsData); //全量数据新增k线柱子
      if (typeof this.updateCallback === 'function') {
        this.updateCallback(barsData);
      }
    },
    //获取k线的历史数据
    getKlineHistory(limit = 1440, type) {
      let _that = this;
      const ticker = `${this.symbol}-${this.interval}`
      let params = {
        type: type,
        symbol: this.symbol,
        limit: limit
      }
      _that.chartResetFlag = true;
      _that.sendFailHeart = true;
      _that.$emit('chartFailSend', _that.sendFailHeart); //通知外部加载的接口是否已经失效
      _that.$emit('chartResetHandle', _that.chartResetFlag);
      clearTimeout(_that.chartTimer);
      _that.chartTimer = setTimeout(() => { //防止接口崩了图表切换开关锁住
        if (_that.chartResetFlag) {
          _that.chartResetFlag = false;
          _that.$emit('chartResetHandle', _that.chartResetFlag);
        }
      }, 12000);
      this.$api.historical.getKlineHistory(params).then((res) => {
        _that.chartResetFlag = false;
        _that.$emit('chartResetHandle', _that.chartResetFlag);
        if (!res) { return false };
        _that.sendFailHeart = false;
        _that.$emit('chartFailSend', _that.sendFailHeart); //通知外部加载的接口是否已经失效
        if (res.code == 200) {
          let data = res.data;
          if (data.length < limit) {
            this.dragLock = false; //没有拖拽的数据了
          }
          let formatData = [];//前端处理数据
          data = data.filter(s => {
            return s !== null
          });
          if (data.length == 0) {
            this.cacheData[ticker] = [];
            _that.KlineWsLock = true;
            return false;
          }
          let dataClone = JSON.parse(JSON.stringify(data));
          formatData = JSON.parse(JSON.stringify(_that.minuteDataHandle(dataClone)));
          _that.lastTime = formatData[0].time; //最后一条的时间戳
          if (formatData.length != 1) {
            formatData.pop();
          }
          formatData.reverse();
          this.cacheData[ticker] = formatData;
          _that.KlineWsLock = true;
        }
      })
    },
    //翻页加载k线数据
    async addDragKlineData(limit, type) {
      let _that = this;
      let params = {
        type: type,
        symbol: this.symbol,
        limit: limit,
        endTime: _that.kStartTime
      }
      const ticker = `${this.symbol}-${this.interval}`
      _that.kStartArr.push(_that.kStartTime);
      _that.chartResetFlag = true;
      _that.sendFailHeart = true;
      _that.$emit('chartFailSend', _that.sendFailHeart); //通知外部加载的接口是否已经失效
      _that.$emit('chartResetHandle', _that.chartResetFlag);
      clearTimeout(_that.chartTimer);
      _that.chartTimer = setTimeout(() => { //防止接口崩了图表切换开关锁住
        if (_that.chartResetFlag) {
          _that.chartResetFlag = false;
          _that.$emit('chartResetHandle', _that.chartResetFlag);
        }
      }, 12000);
      await this.$api.historical.getKlineHistory(params).then((res) => {
        let tickerApi = `${_that.symbol}-${_that.interval}`
        _that.chartResetFlag = false;
        _that.$emit('chartResetHandle', _that.chartResetFlag);
        if (tickerApi == ticker) {
          if (!res) { return false };
          _that.sendFailHeart = false;
          _that.$emit('chartFailSend', _that.sendFailHeart); //通知外部加载的接口是否已经失效
          let data = res.data;
          if (res.code == 200) {
            if (data.length < limit) {
              this.dragLock = false; //没有拖拽的数据了
            }
            let formatData = [];//前端处理数据
            data = data.filter(s => {
              return s !== null
            });
            if (data.length == 0) {
              return false;
            }
            let dataClone = JSON.parse(JSON.stringify(data));
            formatData = JSON.parse(JSON.stringify(_that.minuteDataHandle(dataClone)));
            if (formatData.length != 1) {
              formatData.pop();
            }
            formatData.reverse();
            this.cacheData[ticker] = formatData.concat(this.cacheData[ticker]);
            // console.log(formatData,'拖拽的数据');
          } else {
            _that.kStartArr.pop();
          }
        }
      })
    },
    //k线图数据前端处理
    minuteDataHandle(data) {
      let formatData = [];
      for (let x = 0; x < data.length; x++) {
        let temp_data = {};
        let vol = 0;
        temp_data.time = Number(data[x].startTime);
        // temp_data.timeform = dateFomat(parseInt(temp_data.time/1000))
        if (x != data.length - 1) {
          temp_data.open = data[x].openPrice || data[x + 1].closePrice;
          temp_data.high = data[x].highPrice || data[x + 1].closePrice;
          temp_data.low = data[x].lowPrice || data[x + 1].closePrice;
          temp_data.close = data[x].closePrice || data[x + 1].closePrice;
        } else {
          temp_data.open = data[x].openPrice;
          temp_data.high = data[x].highPrice;
          temp_data.low = data[x].lowPrice;
          temp_data.close = data[x].closePrice;
        }

        //无需计算成交量与成交额
        // if(data[x].upVolume) {
        // 	vol = Number(data[x].upVolume) + Number(data[x].downVolume) + Number(data[x].unchangedVolume);
        // }else {
        // 	vol = 0;
        // }		 
        temp_data.volume = vol; //成交量默认为0
        formatData.push(temp_data);
      }
      formatData = formatData.filter(item => { //过滤掉整理数据中存在null的情况
        return (item.open != null && item.high != null && item.low != null && item.close != null);
      });
      return formatData;
    },
    //获取k线类型
    getKtype(interval) {
      if (interval == '1') { //分钟线
        return 1;
      }
      if (interval == '5') { //分钟线
        return 5;
      }
      if (interval == '15') { //分钟线
        return 7;
      }
      if (interval == '30') { //分钟线
        return 8;
      }
      if (interval == '1D') { //日线
        return 100
      }
      if (interval == '1W') { //日线
        return 107
      }
      if (interval == '1M') { //日线
        return 130
      }
      if (interval == '60') { //小时线
        return 10;
      }
      if (interval == '240') { //4小时线
        return 14;
      }
    },
    unSubscribe(interval) {

    },
    subscribe(uid, callback) {
      this.callbackFlag = true;
      this.updateCallback = callback;
      this.updateTick = uid;
    },
    //历史数据填充
    async getBars(symbolInfo, resolution, rangeStartDate, rangeEndDate, onLoadedCallback) {
      if (this.interval != resolution) { //切换周期
        this.interval = resolution;
        this.getTimeMax(this.interval);
        this.initChangePeriod();
        let ktype = this.getKtype(this.interval);
        this.getKlineHistory(1445, ktype);
      }
      const ticker = `${this.symbol}-${this.interval}`;
      // console.log(' >> :',dateFomat(rangeStartDate), dateFomat(rangeEndDate),resolution,'时间范围');
      if (!this.firstFlag && !this.dragLock) { //通知图表没数据可以加载了
        this.kStartTime = 0;
        onLoadedCallback([]);
        return false;
      }
      if (this.kStartTime && this.kStartArr[this.kStartArr.length - 1] != this.kStartTime) {
        let ktype = this.getKtype(this.interval);
        await this.addDragKlineData(500, ktype);
      }
      // this.isLoading = false;
      if (this.cacheData[ticker]) { //加载历史数据
        this.firstFlag = false; //已执行完历史数据加载
        const newBars = [];
        if (this.cacheData[ticker].length == 0) { //请求的数据为空
          onLoadedCallback(newBars);
          return false
        }
        // this.isLoading = false
        this.kStartTime = this.cacheData[ticker][0].time; //最早的时间戳
        // console.log(this.cacheData[ticker],'缓存的数据'); 
        this.cacheData[ticker].forEach((item, index) => {
          newBars.push(item);
        });
        onLoadedCallback(newBars)
      } else {
        const self = this
        this.getBarTimer = setTimeout(function () {
          self.getBars(symbolInfo, resolution, rangeStartDate, rangeEndDate, onLoadedCallback)
        }, 10)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.head-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-left: 56px;

  .head-left {
    display: flex;
    align-items: center;
  }

  .head-list {
    display: flex;
    align-items: center;
    padding-right: 4px;
    border-right: 1px solid #dadde0;
    padding-left: 14px;

    .head-item {
      padding: 4px 6px 4px;
      border: 1px solid #dadde0;
      cursor: pointer;
      font-size: 11px;
      margin-right: 10px;
    }
  }

  .head-list:first-child {
    padding-left: 0px;
  }

}

.trade-view {
  height: 100%;
}
</style>
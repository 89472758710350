<template>
    <div class="">
        <div class="form-title">{{type=='1'?$t('login.login')+'-':$t('login.register')+'-'}}{{$t('me.account.trader')}}</div>
        <div class="search-box">
            <el-input :placeholder="$t('order.enterName')" v-model="searchWord" @input="searchInputFn" prefix-icon="el-icon-search"
                class="search-dealers" clearable></el-input>
        </div>
        <div class="traders-box">
            <ul class="traders">
                <li class="trader-item" v-for="item in results" @click="selectTraderFn(item)">
                    <img class="trader-logo" src="@/assets/logo.png" />
                    <p class="trader-info">
                        <span class="trader-name">{{ item.name }}</span>
                        <span class="trader-desc">{{ item.des }}</span>
                    </p>
                    <el-popover placement="bottom" trigger="hover">
                        <ul class="trader-detail">
                            <li class="trader-detail-item"><span class="detail-label">{{$t('me.company')}}</span><span class="detail-value">{{
                                item.name }}</span></li>
                            <li class="trader-detail-item"><span class="detail-label">{{$t('me.registrationNumber')}}</span><span
                                    class="detail-value">{{ item.code }}</span></li>
                            <li class="trader-detail-item"><span class="detail-label">{{$t('me.registrationAddress')}}</span><span
                                    class="detail-value">{{ item.regAddress }}</span></li>
                            <li class="trader-detail-item"><span class="detail-label">{{$t('me.officeLocation')}}</span><span
                                    class="detail-value">{{ item.officeAddress }}</span></li>
                            <li class="trader-detail-item"><span class="detail-label">{{$t('me.website')}}</span><span class="detail-value">{{
                                item.website }}</span></li>
                        </ul>
                        <img class="trader-tip" slot="reference" src="@/assets/img/ico_tarder_content.png" />
                    </el-popover>

                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Traders',
    components: {

    },
    watch: {
    },
    props: {
        traders: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default:"1"
        }
    },
    data() {
        return {
            selectTrader: null,
            searchWord: '', //搜索内容
            results: [],//显示数据,
        }
    },
    mounted() {
        this.results = this.traders
    },
    methods: {
        //搜索
        searchInputFn(searchKey) {
            if (searchKey.trim() != '') {
                this.doSearch = true
                try {
                    this.results = this.traders.filter(e => {
                        return e.name.toUpperCase().includes(searchKey.toUpperCase())
                    })
                } catch (e) {
                    this.results = []
                }

            } else {

                this.results = this.traders
            }
        },
        //选择交易商
        selectTraderFn(item) {
            this.$emit('selectTraderBack',item)
        }
    }
}
</script>
<style lang="scss" scoped>
.search-box {
    margin-bottom: 12px;
}

.traders-box {
    height: 230px;
    overflow-y: scroll;
}

.traders {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
}

.trader-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 231px;
    height: 40px;
    border: 1px solid #ECEFFB;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;

    .trader-logo {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }

    .trader-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
    }

    .trader-tip {
        width: 20px;
        height: 20px;
    }
}

.form-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
}

.trader-detail {
	margin: 0 16px;
	
    .trader-detail-item {
        width: 240px;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #ECEFFB;

        &:last-child {
            border-bottom: none;
        }

        span {
            font-size: 12px;
            font-weight: 400;
            color: #000;
        }

    }
}
</style>
  
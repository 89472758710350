<template>
	<div class="table-body" @contextmenu.prevent = "openMenu">
		<div class="body-text" style="font-weight: bold;">{{orderItem.symbol}}</div>
		<div class="body-text" style="width: 160px;">#{{orderItem.id}}</div>
		<div v-if="tabsIndex != 1" class="body-text">{{orderItem.openingPrice}}</div>
		<div class="body-text" :class="orderItem.direction==-1? 'sell-color':'buy-color'">{{orderItem.direction==-1?'sell':'buy'}}</div>
		<div class="body-text">{{orderItem.volume}}</div>
		<div class="body-text">{{orderItem.transactionPrice ? orderItem.transactionPrice : orderItem.openingPrice}}</div>
		<div class="body-text">{{orderItem.loss?orderItem.loss:'-'}}</div>
		<div class="body-text">{{orderItem.profit?orderItem.profit:'-'}}</div>
		<div v-if="tabsIndex != 1" class="body-text">{{orderItem.storageFee?orderItem.storageFee:0}}</div>
		<div class="body-text">{{orderItem.symbol=='Balance'?'-':orderItem.openingFee?orderItem.openingFee:0}}</div>
		<div v-if="tabsIndex != 1" class="body-text" :class="orderItem.profitMoney < 0 ? 'sell-color':'buy-color'">{{orderItem.profitMoney ? parseFloat(orderItem.profitMoney).toFixed(2) : 0}}</div>
		<div class="body-text" style="width: 200px;">{{mixinDateFomat(orderItem.createTime)}}</div>
		<div v-if="tabsIndex == 0" class="body-text" style="width: 200px;">{{mixinDateFomat(orderItem.closeTime)}}</div>
	</div>
</template>

<script>
	import config from "@/config/config.js"
	export default{
		data() {
			return {
			}
		},
		props: {
			orderItem: {
				type: Object,
				default: () => {}
			},
			tabsIndex: {
				type: Number,
				default: 0
			}
		},
		methods:{
			//右键点击弹窗
			openMenu() {
			    let that = this;
			    this.$contextmenu({
			        items: [
			            {
			                label: this.$t('index.popup.newOrder'),
			                onClick: () => {
			                    that.$emit('tradeShow', this.orderItem);
			                }
			            },
			            {
			                label: this.$t('index.popup.chart'),
			                onClick: () => {
			                    that.$emit('symbolClick',this.orderItem);
			                }
			            }
			        ],
			        event,
			        customClass: "symbol_menu",
			        zIndex: 3,
			        minWidth: 88
			    });
			    return false;
			},
		}
	}
</script>
<style>
.symbol_menu  {
    padding: 0 0 !important;
}
.symbol_menu .menu_item {
    line-height: 39px !important;
}
</style>
<style lang="scss" scoped>
	.table-body{
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		position: relative;
		
		.warning-img{
			width: 5px;
			height: 32px;
			position: absolute;
			left: 0;
			top: 7px;
		}
		
		.body-text{
			text-align: left;
			width: 9.09%;
			font-size: 14px;
		}
		
		.buy-color{
			color: #2568FF;
		}
		
		.sell-color{
			color: #DD4F4F;
		}
	}
	
	.table-body:hover {
	  background-color: #F2F8FF; /* 鼠标移上去时改变的背景色 */
	}
</style>
<template>
  <div class="market-info">
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        :show-close="false"
        width="720px"
        >
        <div class="top-model">
            <span>{{headConfig.title}}</span>
            <img @dragstart.prevent @click="closeDialog()" class="close-img" src="@/static/image/closeWhite.png" />
        </div>
        <div class="body-model">
            <div class="body-list">
                <div class="body-item" v-for="(item,index) in infoBaseList" :key="index" v-if="item.isShow">
                    <span class="item-label">{{ item.text }}</span>
                    <span class="item-text">{{ item.value }}</span>
                </div>
            </div>
            <div class="body-time">
                <div class="time-title">{{$t('quotation.info.openTime')}}</div>
                <div class="time-list">
                    <div class="time-item" v-for="(item,index) in openItems" :key="index">
                        <div class="time-name">{{getWeekText(item.week)}}</div>
                        <div class="time-process" :class="{'time-process-active':item.data.trade.length > 0}"></div>
                        <div class="time-info" v-if="item.data.trade.length > 0">
                            <p class="time-text" v-for="(val,key) in item.data.trade" :key="key">{{ getShowTime(val) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</el-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return {
            dialogVisible:false,
            headConfig: {
                title: "",
            },
            data: {},
            infoBaseList: [],
            openItems: []
        }
    },
    mounted(){
    },
    methods:{
        closeDialog(){
            this.dialogVisible = false;
        },
        openDialog(row){
            this.getSymbolInfo(row);
            this.getValidityEnable();
            this.dialogVisible = true;
        },
        getSymbolInfo(row) {
				let data = row;
				this.data = data
				this.headConfig.title = data.symbol
				this.infoBaseList = [{
						key: 'name',
						text: this.$t('me.appellation'),
						isShow: false,
						value: data.name,
					},
					{
						key: 'contractSize',
						text: this.$t('quotation.volumePerLot'),
						isShow: true,
						value: data.contractSize,
					},
					{
						key: 'initialMargin',
						text: this.$t('quotation.initialMargin'),
						isShow: true,
						value: data.initialMargin,
					},
					{
						key: 'marginUnit', //保证金收取类别:1=固定值*手数,2=总价的百分比
						text: this.$t('quotation.MarginCategory'),
						isShow: true,
						value: data.marginUnit,
					},
					{
						key: 'maintenanceMargin',
						text: this.$t('quotation.Maintenance'),
						isShow: true,
						value: data.maintenanceMargin,
					},
					{
						key: 'hedging',
						text: this.$t('quotation.hedgeMargin'),
						isShow: true,
						value: data.hedging,
					},
					{
						key: 'quoteVolume',
						text: this.$t('quotation.quotation'),
						isShow: true,
						value: data.quoteVolume,
					},
					{
						key: 'qutePrice',
						text: this.$t('quotation.priceLevel'),
						isShow: true,
						value: data.qutePrice,
					},
					{
						key: 'percentage',
						text: this.$t('quotation.percentage'),
						isShow: true,
						value: data.percentage,
					},
					{
						key: 'forcedLockingModel', //0=否,1=是
						text: this.$t('quotation.lockdown'),
						isShow: true,
						value: data.forcedLockingModel,
					},
					{
						key: 'decimalPlaces',
						text: this.$t('quotation.decimalPlaces'),
						isShow: true,
						value: data.decimalPlaces,
					},
					{
						key: 'description',
						text: this.$t('quotation.illustrate'),
						isShow: false,
						value: data.description,
					},
					{
						key: 'tradeType', //forex=外汇,indexes=指标,CFD=差价合约,futures=期货,digital=数字货
						text: this.$t('quotation.transactionType'),
						isShow: true,
						value: data.tradeType,
					},
					{
						key: 'tradeModel', //limit_order=限价模式,market_order=市价模式
						text: this.$t('quotation.transactionMode'),
						isShow: false,
						value: data.tradeModel,
					},
					{
						key: 'orderSetting', //0=一直有效，1=当日有效
						text: this.$t('quotation.orderSetting'),
						isShow: false,
						value: data.orderSetting,
					},
					{
						key: 'isBuy', //0=否,1=是
						text: this.$t('quotation.isBuy'),
						isShow: true,
						value: data.isBuy,
					},
					{
						key: 'pendingLossSpread',
						text: this.$t('quotation.stopLossSpread'),
						isShow: true,
						value: data.pendingLossSpread,
					},
					{
						key: 'spreadBalance',
						text: this.$t('quotation.spreadBalance'),
						isShow: true,
						value: data.spreadBalance,
					},
					{
						key: 'interestType', //1=点差模式,2=金额模式,3=利息模式,4=保证金模式
						text: this.$t('quotation.interestType'),
						isShow: true,
						value: data.interestType,
					},
					{
						key: 'buyInterest',
						text: this.$t('order.buyInterest'),
						isShow: true,
						value: data.buyInterest,
					},
					{
						key: 'sellInterest',
						text: this.$t('quotation.sellInterest'),
						isShow: true,
						value: data.sellInterest,
					},
					{
						key: 'minVolume', //
						text: this.$t('quotation.minVolume'),
						isShow: true,
						value: data.minVolume,
					},
					{
						key: 'maxVolume', //
						text: this.$t('quotation.maxVolume'),
						isShow: true,
						value: data.maxVolume,
					},
					{
						key: 'volumeStepLength', //
						text: this.$t('quotation.volumeStepLength'),
						isShow: true,
						value: data.volumeStepLength,
					},
					{
						key: 'spreadType', //0:浮动 1：固定值
						text: this.$t('quotation.spreadType'),
						isShow: true,
						value: data.spreadType,
					},
					{
						key: 'tenantId',
						text: this.$t('quotation.tenantId'),
						isShow: true,
						value: data.tenantId,
					},
					{
						key: 'marginId',
						text: this.$t('quotation.marginId'),
						isShow: true,
						value: data.marginId,
					},
					{
						key: 'minDeviation',
						text: this.$t('quotation.minDeviation'),
						isShow: true,
						value: data.minDeviation,
					},
				]
			
        },
        getShowTime(item) {
            let time = item
            if(!time) {return ''};
            let text = ""
            if(time.timeEnd=="00:00:00"){
                time.timeEnd = "24:00:00"
            }
            text= time.timeBegin.substring(0,time.timeBegin.length - 3) + "-" + time.timeEnd.substring(0,time.timeEnd.length - 3);
            return text;
        },
        getWeekText(value) {
            let text = this.$t('week.0')
            switch (value) {
                case 1:
                    text = this.$t('week.1')
                    break;
                case 2:
                    text = this.$t('week.2')
                    break;
                case 3:
                    text = this.$t('week.3')
                    break;
                case 4:
                    text = this.$t('week.4')
                    break;
                case 5:
                    text = this.$t('week.5')
                    break;
                case 6:
                    text = this.$t('week.6')
                    break;
                default:
                    text = this.$t('week.0')
                    break;
            }
            return text
        },
        getValidityEnable() {
            let param = {
                "tradingVarietyId": this.data.id
            }
            let that = this;
            this.$api.tradeTime.getTradingOn(param).then(res=>{
                if (res.code == 200 && res.data) {
                    that.openItems = res.data;
                }
            })
        },
    }
    
}
</script>

<style lang="scss" scoped>
.body-model::-webkit-scrollbar {
    width: 6px;
}
.body-model::-webkit-scrollbar-thumb {
    background-color: #CFD5E4;
    border-radius: 8px;
}
.body-model::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
}
::v-deep .el-dialog{
    border-radius: 10px;
}

::v-deep .el-dialog__header{
    display: none;
}

::v-deep .el-dialog__body{
    padding: 0;
    color: #000;
}
.market-info {
    .top-model{
        height: 50px;
        background-color: #2568FF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        color: #fff;
        font-size: 16px;
        border-radius: 8px 8px 0px 0px;
    }
    
    .close-img{
        width: 12px;
        height: 12px;
        cursor: pointer;
    }
    .body-model{
        background-color: #fff;
        border-radius: 0px 0px 8px 8px;
        padding: 18px 16px 18px;
        height: 417px;
        overflow: auto;
        .body-list { 
            display: flex;
            align-items: center;
            flex-wrap: wrap; 
            justify-content: space-between;
            .body-item {
                border-bottom: 1px solid #ECEFFB;
                height: 40px;
                box-sizing: border-box;
                width: 48%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                .item-label {
                    font-size: 14px;
                    color: #8996B4;
                }
                .item-text {
                    font-size: 14px;
                    color: #000000;
                }
            }
        }
        .body-time {
            margin-top: 16px;
            .time-title {
                text-align: left;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 14px;
            }
            .time-list {
                display: flex;
                align-items: center;
                .time-item {
                    width: 86px;
                    height: 84px;
                    margin-right: 14px;
                    box-sizing: border-box;
                    text-align: left;
                    .time-name {
                        height: 24px;
                        line-height: 24px;
                        color: #8996B4;
                        font-size: 14px;
                        margin-bottom: 12px;
                        padding-left: 2px;
                    }
                    .time-process {
                        height: 6px;
                        background-color:  #CFD5E4;
                        border-radius: 8px;
                    }
                    .time-process-active {
                        background-color: #2568FF;
                    }
                    .time-info {
                        margin-top: 12px;
                        .time-text {
                           color: #000000;
                           margin-bottom: 4px;
                           font-size: 12px;
                           text-align: left;
                           &:last-child {
                                margin-bottom: 0px;
                           } 
                        }
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            } 
        }
    }
}
</style>
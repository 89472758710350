<template>
    <div class="market-tb">
        <div class="market-loading-box" v-if="firstLoading">
            <div class="market-loaing"></div>
        </div>
        <div class="market-search">
            <el-input
                :placeholder="$t('order.enterSymbol')"
                prefix-icon="el-icon-search"
                v-model.trim="keyword"
                v-show="!firstLoading"
                @focus="goSearch"
            >
            </el-input>
            <img @dragstart.prevent src="@/assets/search_ico.png" class="search-ico" :alt="$t('order.empty')" :title="$t('order.empty')" @click="golist" v-show="!searchFlag">
        </div>
        <div class="market-empty" v-show="list.length == 0 && !firstLoading">
            {{$t('order.noData')}}
        </div>
        <div class="market-con" style="margin-top: 6px;" @contextmenu.prevent = "defaultMenu" v-show="!firstLoading"> 
            <div class="tb-set" v-show="searchFlag" @click.stop="headShow = !headShow">
                <img @dragstart.prevent src="@/assets/tbhead.png" class="tb-set-icon" :alt="$t('order.headerSettings')" :title="$t('order.columnSettings')">
                <div class="tb-setlist" v-show="headShow">
                    <div class="list-item" v-for="item in getHeadList()" :key="item.key" @click.stop="selectHandle(item.key)">  
                        <img src="@/assets/select.png" alt="" v-show="selectList.indexOf(item.key) == -1">
                        <img src="@/assets/selected.png" alt="" v-show="selectList.indexOf(item.key) > -1">
                        <span class="">{{ item.label }}</span>
                    </div>
                </div>
            </div> 
            <MarketTable :setHeight="setHeight - 33" v-if="searchFlag" :list="setList" :firstLoading="firstLoading" :selectList="selectList" ref="marketTable" @tradeShow="tradeShow" @symbolClick="symbolClick" @symbolInfo="symbolInfo"></MarketTable>
            <MarketSearch v-if="!searchFlag" :allOrderSymbol="allOrderSymbol" :collectList="collectList" :keyword="keyword" :allList="list" @goChart="symbolClick" @goLogin="goLogin"></MarketSearch>
        </div>
    </div>
</template>
<script>
import MarketTable from '@/components/market/MarketTable.vue'
import MarketSearch from '@/components/market/MarketSearch.vue'
export default {
    name: 'MarketModule',
    components: {
        MarketTable,
        MarketSearch
    },
    computed: {
        setList() {
            if(this.list.length == 0) {
                return [];
            }
            if(!this.userInfo) {
                return this.list.slice(0,11)
            }
            let that = this;
            let showList = [];
            if(this.collectList.length > 0) {
                showList = this.list.filter(item=>{
                    return that.collectList.indexOf(item.symbol) > -1
                });
            }
            return showList;
        }
    },
    watch:{
    },
    props: {
        list: {
            type: Array,
            default: ()=>[]
        },
        firstLoading:{
            type:Boolean,
            default:true
        },
        setHeight:{
            type: Number,
            default: 510  
        },
        collectList:{
            type: Array,
            default: ()=>[]
        },
        allOrderSymbol: {
            type: Array,
            default: ()=>[]
        }
    },
    data() {
        return {
            searchFlag:true,
            keyword:'',
            selectList:[],
            headList:[
                {key:3,label:this.$t('quotation.highestPrice')},
                {key:4,label:this.$t('quotation.spread')},
                {key:5,label:this.$t('quotation.lowestPrice')},
                {key:6,label:this.$t('quotation.finalPrice')}
            ],
            headShow:false
        }
    },
    mounted() { 
        let setheadList = this.$storage.getCache('setheadList');
        if(setheadList && setheadList.length > 0) {
            this.selectList = setheadList;
        }
    },
    beforeUpdate() {   
    },
    methods: {
        getHeadList() {
            let headList = [
                {key:3,label:this.$t('quotation.highestPrice')},
                {key:4,label:this.$t('quotation.spread')},
                {key:5,label:this.$t('quotation.lowestPrice')},
                {key:6,label:this.$t('quotation.finalPrice')}
            ];
            return headList;
        },
        golist() {
            if(this.searchFlag) {return false};
            this.keyword = "";
            this.searchFlag = true;
            this.headShow = false;
        },
        closeHead() {
            this.headShow = false;
        },
        goSearch() {
            if(!this.searchFlag) {return false};
            this.searchFlag = false;
        },
        selectHandle(key) {
            let index = this.selectList.indexOf(key);
            if(index > -1) {
                this.selectList.splice(index,1);
                this.$storage.setCache('setheadList',this.selectList);
                this.$refs.marketTable.initTable();
                return false;
            }
            this.selectList.push(key);
            this.$storage.setCache('setheadList',this.selectList);
            this.$refs.marketTable.initTable();
        },
        defaultMenu() {
            return false;
        },
        tradeShow(row) {
            this.$emit('tradeShow', row);
        },
        symbolClick(row) {
            this.$emit('symbolClick',row);
        },
        symbolInfo(row) {
            this.$emit('symbolInfo',row);
        },
        goLogin(flag) {
            this.$emit('goLogin',flag);
        },
    }
}
</script>
<style lang="scss" scoped>
    .market-search {
        position: relative;
        padding: 0 16px;
        .search-ico {
            position: absolute;
            top: 50%;
            right: 28px;
            width: 12px;
            height: 12px;
            margin-top: -6px;
            cursor: pointer;
        }
    }
    .symbol-name {
        font-weight: 600;
        color: #000000;
    }
    .market-loading-box {
        width: 100%;
        height: 580px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .market-loaing {
    width: 60px;
    height: 60px;
    border: 2px solid#2B64FF;
    border-top-color: transparent;
    border-radius: 100%;
    animation: circle infinite 0.75s linear;
    }
    @keyframes circle {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .market-tb {
        position: relative;
    }
    .market-empty {
        position: absolute;
        left: 0;
        top: 95px;
        width: 100%;
        height: 502px;
        background-color: #FFFFFF;
        z-index: 16;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #8996B4;
    }
    .market-con {
        position: relative;
        .tb-set {
            position: absolute;
            right: 0px;
            top: 13px;
            z-index: 20;
            display: flex;
            width: 36px;
            height: 20px;
            padding-left: 4px;
            background-color: #FFFFFF;
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
            
            .tb-setlist {
                position: absolute;
                right: 16px;
                top: 37px;
                z-index: 11;
                border-radius: 6px 6px 6px 6px;
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 0px rgba(34,78,169,0.3);
                .list-item {
                    display: flex;
                    align-items: center;
                    width: 128px;
                    height: 40px;
                    padding-left: 12px;
                    line-height: 40px;
                    box-sizing: border-box;
                    cursor: pointer;
                    img {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 14px;
                        color: #000000;
                    }
                    &:hover {
                        background-color: #F2F8FF;
                    }
                }
            }
        }
    }
</style>
  
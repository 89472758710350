<template>
  <div class="optionalTab">
    <div class="optionalTop">
      <div>自选表</div>
      <div>添加</div>
      <div>图表</div>
      <div>设置</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.optionalTab {
	
	.optionalTop {
	   padding: 0 16px;
	   display: flex;
	   justify-content: space-between;
	   height: 40px;
	}
}
</style>

import Datastore from 'nedb'

let config = {
  autoload: true,
};

const nedb = new Datastore({
  ...config,
  filename: "data/trader.db",
});

let db = {
  //原生方法
  nedb,
  //插入
  insert(value) {
    return new Promise((resolve, reject) => {
      nedb.insert(
        value,
        (err, ret) => {
          if (err) {
            reject(err)
          }
          resolve(ret)
        }
      )
    })
  },
  //查找
  find(key) {
    return new Promise((resolve, reject) => {
      nedb.find(
        key,
        (err, ret) => {
          if (err) {
            reject(err)
          }
          if (ret.length == 0) {
            resolve(null)
          } else {
            resolve(ret)
          }
        }
      )
    })
  },
  //查找一个
  findOne(key) {
    return new Promise((resolve, reject) => {
      nedb.findOne(
        key,
        (err, ret) => {
          if (err) {
            reject(err)
          }
          resolve(ret)
        }
      )
    })
  },
  //直接替换更新
  update(key, value) {
    return new Promise((resolve, reject) => {
      nedb.update(
        key,
        { $set: value },
        {},
        (err, numReplaced) => {
          nedb.loadDatabase();
          if (err) {
            reject(err)
          }
          resolve(numReplaced)
        }
      )
    })
  },
  //更新现有字段
  updateMulti(key, value) {
    return new Promise((resolve, reject) => {
      nedb.update(
        key,
        { $set: value },
        { multi: true },
        (err, numReplaced) => {
          nedb.loadDatabase();
          if (err) {
            reject(err)
          }
          resolve(numReplaced)
        }
      )
    })
  },
  //自定义更新
  updateCustom(value) {
    return new Promise((resolve, reject) => {
      nedb.update(
        value,
        (err, numReplaced) => {
          nedb.loadDatabase();
          if (err) {
            reject(err)
          }
          resolve(numReplaced)
        }
      )
    })
  },
  //删除一条
  remove(key={}) {
    return new Promise((resolve, reject) => {
      nedb.remove(
        key,
        {},
        (err, numRemoved) => {
          nedb.loadDatabase();
          if (err) {
            reject(err)
          }
          resolve(numRemoved)
        }
      )
    })
  },
  //删除多条
  removeMulti(key={}) {
    let obj = Object.keys(key).length
    if(obj==0){
      //如果删除所有进行拦截
      return
    }
    return new Promise((resolve, reject) => {
      nedb.remove(
        key,
        { multi: true },
        (err, numRemoved) => {
          nedb.loadDatabase();
          if (err) {
            console.log(err)
            return
          }
        }
      )
    })
  },
  //删除所有 慎用
  // removeAll() {
  //   return new Promise((resolve, reject) => {
  //     nedb.remove(
  //       {},
  //       { multi: true },
  //       (err, numRemoved) => {
  //         nedb.loadDatabase();
  //         if (err) {
  //           console.log(err)
  //           return
  //         }
  //       }
  //     )
  //   })
  // },
}

export default db;

//开发环境
let developUrl = {
	defaultUrl: "http://192.168.1.27:8084/",
	termUrl: "https://www.sp-trader.com/privacy/agreement.html",
	agreementUrl: "https://www.sp-trader.com/privacy/",
	baseUrl: "",
	type: 0
}
//测试环境
let testUrl = {
	defaultUrl: "http://192.168.1.28:8084/",
	termUrl: "https://www.sp-trader.com/privacy/agreement.html",
	agreementUrl: "https://www.sp-trader.com/privacy/",
	baseUrl: "",
	type: 1
}
//正式环境
let releaseUrl = {
	defaultUrl: "https://app.spquotes.com/",
	termUrl: "https://www.sp-trader.com/privacy/agreement.html",
	agreementUrl: "https://www.sp-trader.com/privacy/",
	baseUrl: "",
	type: 2
}
// let releaseUrl = {
// 	defaultUrl: "https://app.spquotes.com/",
// 	termUrl: "https://www.spquotes.com/privacy/agreement.html",
// 	agreementUrl: "https://www.spquotes.com/privacy/",
// 	baseUrl: "",
// 	type: 2
// }
let useUrl = releaseUrl;

let appSet = {
	appName:'SP Quotes',
	profitAndLossBase: 1,   //100000
	earlyWarningValue: 10  //止盈止损预警值
}

export default {
	useUrl,
	testUrl,
	releaseUrl,
	developUrl,
	appSet
}
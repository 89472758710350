<!-- 账户弹窗 -->
<template>
	<div class="loginAndRegister">
		<el-dialog :close-on-click-modal="true" :visible.sync="dialogVisible" :show-close="false">
			<div class="custom-pop" v-if="refresh">
				<div class="top-model">
					<span>{{$t('order.transactionAccount')}}:</span>
					<img @dragstart.prevent class="close-img" src="@/assets/img/ico_close.png" @click="close()" />
				</div>
				<div class="body-model">
					<div class="menu-model">
						<div @click="changType(0)" class="menu-btn pointer" :class="isSelectType == 0 ? 'choose-btn' : ''">
							<img @dragstart.prevent class="menu-img" src="@/assets/img/ico_login.png" />
							<span>{{$t('login.loginAccount')}}</span>
						</div>
						<div @click="changType(1)" class="menu-btn pointer" :class="isSelectType == 1 ? 'choose-btn' : ''">
							<img @dragstart.prevent class="menu-img" src="@/assets/img/ico_register.png" />
							<span>{{$t('order.demoAccount')}}</span>
						</div>
						<div class="menu-btn account-menu" v-if="accounts && accounts.length > 0">
							<img @dragstart.prevent class="menu-img" src="@/assets/img/ico_account.png" />
							<span>{{$t('order.myAccount')}}</span>
							<!-- <img class="img-dot" src="@/assets/img/ico_account_dot.png" /> -->
						</div>
						<ul class="account-list">
							<li class="account-list-item" @click="clickAccount(item)"
								:class="{ 'item-hover': (isSelectType == 2 && selectId == item.id) }"
								v-for="item in accounts">
								<i class="dot" :class="{ 'current-dot': (userInfo && userInfo.id == item.id) }"></i>
								<span>{{ item.userAccount }}</span>
							</li>
						</ul>
					</div>

					<!-- 登录 -->
					<div v-if="isSelectType == 0">
						<Login :traders="traders" :loginInfo="loginInfo" @loginBack="loginBack" />
					</div>
					<!-- 注册 -->
					<div v-if="isSelectType == 1">
						<Register :traders="traders" @registerBack="registerBack" />
					</div>
					<!-- 账户列表 -->
					<div v-if="isSelectType == 2">
						<AccountInfo v-if="selectAccount" :selectAccount="selectAccount" @loginBack="loginBack" @loginErrBack="loginErrBack"  @delAccountBack="delAccountBack" @logoutBack="logoutBack" @upAccountPwdBack="upAccountPwdBack"></AccountInfo>
					</div>
				</div>
			</div>

		</el-dialog>
	</div>
</template>

<script>
import Login from "./Login.vue"
import Register from "./Register.vue"
import AccountInfo from "@/components/account/AccountInfo.vue"
import { getAccounts, upUserData } from "@/utils/userUtils"
export default {
	components: {
		Login,
		Register,
		AccountInfo
	},
	props: {
		traders: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			dialogVisible: false,
			isSelectType: 0,
			refresh: true,
			accounts: [],
			selectId: 0,
			selectAccount: null,
			isLoading: false,
			accountType:0,
			loginInfo:null
		}
	},
	watch: {
		dialogVisible(val) {
			if (!val) {
				this.refresh = false
				this.$nextTick(e => {
					this.isSelectType = 0
					this.refresh = true
				})
			} else {
				this.getAccounts()
			}

		}
	},
	mounted() {

	},
	methods: {
		//获取用户列表
		getAccounts() {
			getAccounts(this, res => {
				if(!res){
					res = []
				}
				this.accounts = res
				if (this.userInfo) {
					this.selectId = this.userInfo.id
					let currentUser = res.filter(e => { return (e.id == this.userInfo.id) })
					let otherUser = res.filter(e => { return (e.id != this.userInfo.id) })
					if(!otherUser){
						otherUser = []
					}
					if (currentUser.length >0 && otherUser.length >0) {
						this.accounts = currentUser.concat(otherUser)
					}
					this.selectAccount = currentUser[0]
				} else {
					
					if(this.accounts.length>0){
						this.selectAccount = this.accounts[0]
						this.selectId = this.accounts[0].id
					}
					
				}
			})
		},
		//显示
		show() {
			this.dialogVisible = true
		},
		//隐藏
		close() {
			this.dialogVisible = false
		},
		//切换
		changType(val) {
			this.isSelectType = val
			this.$forceUpdate()
		},
		//登录回调
		loginBack(data) {
			this.close()
			this.$message.success(this.$t('order.loginSuccessful'));
			this.setUserData(data)
		},
		//用户列表登录失败回调
		loginErrBack(data) {
			if(data && data.appService){
				this.loginInfo = data
			}
			this.isSelectType = 0
			this.$forceUpdate()
		},
		//注册回调
		registerBack(data) {
			this.close()
			this.$message.success(this.$t('order.registration'));
			this.setUserData(data)
		},
		//删除账户回调
		delAccountBack(){
			this.close()
			this.$message.success(this.$t('saveSuccess'));
			this.getAccounts()
		},
		//退出登录回调
		logoutBack(){
			this.close()
			this.restartApp()
		},
		//修改密码回调
		upAccountPwdBack(){
			this.close()
			this.$message.success(this.$t('saveSuccess'));
			this.getAccounts()
		},
		//点击账户
		clickAccount(item) {
			this.selectId = item.id
			this.isSelectType = 2
			this.selectAccount = null
			this.$nextTick(e=>{
				this.selectAccount = item
			})
		},
		//重新启动
		restartApp() {
			this.$router.replace('/loading')
		},
		//更新用户数据
		setUserData(userInfo, appService = null) {
			//更新cache cahe只放简单的信息
			let userCache = {
				id: userInfo.id,
				groupId: userInfo.groupId,
				token: userInfo.token,
				userAccount: userInfo.userAccount
			}
			this.$storage.setCache('userInfo', userCache)
			//服务器信息
			if (appService) {
				userInfo.appService = this.selectAccount.appService
			}
			//更新db
			upUserData(this, userInfo, () => {
				//更新vuex
				this.$store.commit('upUserInfo', userInfo)
				this.restartApp()
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.loginAndRegister {
	::v-deep .el-dialog {
		border-radius: 8px !important;
		box-shadow: 0px 4px 20px 0px rgba(34, 78, 169, 0.3) !important;
		background: #ffffff !important;
		margin-top: 25vh !important;
		width: 720px !important;
		height: 420px;
	}

	::v-deep .el-dialog__header {
		display: none;
	}



	::v-deep .el-dialog__body {
		padding: 0;
		// color: rgba(0,0,0,0.1);
	}

	.top-model {
		height: 50px;
		background-color: #2568FF;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px;
		color: #fff;
		font-size: 16px;
		border-radius: 8px 8px 0px 0px;
	}

	.close-img {
		width: 13px;
		height: 13px;
		background: none;
		cursor: pointer;
	}

	.body-model {
		background-color: #fff;
		display: flex;
		border-radius: 0px 0px 8px 8px;

		.menu-model {
			width: 159px;
			border-right: 1px solid #ECEFFB;
			height: 370px;

			.menu-btn {
				padding-left: 16px;
				display: flex;
				align-items: center;
				height: 48px;
				font-size: 14px;
				font-weight: 500;

				.menu-img {
					width: 20px;
					height: 20px;
					margin-right: 9px;
				}
			}

			.account-menu {
				border-bottom: 1px solid #ECEFFB;
				position: relative;
				z-index: 0;
				cursor: default;
				.img-dot {
					width: 15px;
					height: 6px;
					position: absolute;
					left: 62px;
					margin-left: 8px;
					bottom: -1px;
					z-index: 1;
				}
			}

			.choose-btn {
				background-color: #F2F8FF;
			}
		}


	}
}

.account-list {

	height: 190px;
	overflow-y: scroll;

	.account-list-item {
		height: 48px;
		line-height: 48px;
		display: flex;
		padding-left: 24px;
		flex-direction: row;
		align-items: center;
		cursor: pointer;

		.dot {
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 6px;
			background: #2568FF;
			margin-right: 15px;
			opacity: 0;
		}

		.current-dot {
			opacity: 1;
		}

		span {
			flex: 1;
			text-align: left;
			font-weight: 400;
			color: #000000;
		}
	}

	.item-hover {
		background: #F2F8FF;
	}
}


</style>
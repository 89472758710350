<template>
	<div class="balance-module">
	  <div class="balance-detail">
		  <div class="balance-text">{{$t('trade.balance')}}：{{getBalance().toFixed(2)}}</div>
		  <div class="balance-text">{{$t('trade.netWorth')}}：{{getProfitAndLossTotal()}}</div>
		  <div class="balance-text">{{$t('trade.prepayments')}}：{{getLockMargin()}}</div>
		  <div class="balance-text">{{$t('trade.prepaymentAvailable')}}：{{getCanUseLockMargin()}}</div>
		  <div class="balance-text">{{$t('trade.advance')}}：{{getLockMarginP()}}</div>
	  </div>
	  <div class="profit">
		  <span >{{parseFloat(profitTotal).toFixed(2)}}</span>
		  <span class="unit"> USD</span>
	  </div>
	</div>
</template>

<script>
	export default{
		props: {
			profitTotal: {
				type: String | Number,
				default: 0
			},
		},
		methods:{
			//结余
			getBalance() {
				if(!this.userInfo) return 0
				return parseFloat(this.userInfo.wallet.balanceAmount)
			},
			//净值
			getProfitAndLossTotal() {
				if(!this.userInfo) return 0
				return (this.getBalance() + parseFloat(this.profitTotal)).toFixed(2)
			},
			//可用预付款
			getCanUseLockMargin() {
				if(!this.userInfo) return 0
				return (this.getProfitAndLossTotal() - this.userInfo.wallet.lockMargin).toFixed(2)
			},
			//预付款比率
			getLockMarginP() {
				if(!this.userInfo) return 0
				
				let value = (this.userInfo.wallet.lockMargin > 0 ? ((this.getProfitAndLossTotal() / this.userInfo.wallet
					.lockMargin) * 100) : 0).toFixed(2)
				//如果低于这个值属于爆仓要刷新 marginClose
				let marginClose = this.userInfo.userGroup.marginClose
				if(marginClose && marginClose>0 && value!=0 && value<marginClose){
					console.log('监听到爆仓')
					let item = {
						id:new Date().getTime()
					}
					// this.$store.commit('setUpDataMsg', new Date().getTime());
					// setTimeout(e=>{
					// 	this.$store.commit('setUpDataMsg', new Date().getTime());
					// },1000)
					this.$appTask.addTask(item)
				}
				return value
			},
			//预付款
			getLockMargin() {
				if(!this.userInfo) return 0
				return this.userInfo.wallet.lockMargin
			}
		}
	}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.balance-module{
		width: 100%;
		height: 48px;
		position: fixed;
		bottom: 0px;
		background: #F2F5F9;
		z-index: 999;
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		.balance-detail{
			display: flex;
		}
		
		.balance-text{
			font-size: 14px;
			margin-right: 45px;
		}
		
		.profit{
			font-weight: bold;
			font-size: 14px;
		}
		
		.unit{
			color: #2568FF;
		}
	}
</style>
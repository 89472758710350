// 计算说明：
// 盈亏   买卖 * (当前价格 - 开仓价格) * 手数 * 杠杆位数
// 结余   余额
// 净值  余额 + 盈亏
// 可用预付款  余额 - 预付款
// 预付款比例 净值 / 预付款 
// 预付款  保证金
const appUtils = {
	//计算总盈亏:
	profitAndLossTotal(socketMsg = [], orderDatas = [], pageObj, isSendMs = true) {
		if (!orderDatas) {
			return 0
		}
		let total = 0
		let profitTotal = 0
		let registrationTotal = 0
		let arr = JSON.parse(JSON.stringify(orderDatas))
		arr.forEach(data => {
			
			socketMsg.forEach(symbolInfo => {
				if (symbolInfo.symbol == data.symbol) {
					let change =0
					let profit =0
					if(isSendMs){
						//盈亏
						change = appUtils.profitAndLossItem(symbolInfo, data,pageObj)
					}
					//利润：盈亏 + 库存费金额 + 手续费金额
					profit = change + parseFloat(data.storageFee) + parseFloat(data.openingFee) 
					data.currentPrice = symbolInfo.price
					data.currentBid = symbolInfo.bid
					data.currentAsk = symbolInfo.ask
					data.change = change
					data.profitValue = profit
					//总盈亏
					total = total + change
					//总利润
					profitTotal = profitTotal + profit
				}
			})
		})
		let changeMsg = {
			orderDatas: arr,
			profitAndLossTotal: total.toFixed(4),
			registrationChangeTotal: registrationTotal.toFixed(4),
			profitTotal:profitTotal.toFixed(4)
		}

		return changeMsg
	},
	//计算盈亏: ( 买卖方向（1买或者-1卖）*（当前市价-开仓价格）*手数*100000（手数单价）)-手续费 * || / 汇率
	profitAndLossItem(symbolInfo = {}, data = {},pageObj) {
		let userInfo = pageObj.userInfo
		if (!userInfo) {
			return 0
		}
		if (!data) {
			return 0
		}
		//买卖方向
		let way = parseFloat(data.direction)
		//console.log("买卖方向：",way)
		//当前市价
		let currentPrice = way==1?parseFloat(symbolInfo.bid):parseFloat(symbolInfo.ask)
		//console.log("当前市价：",currentPrice)
		//开仓价格
		let openPrice = parseFloat(data.openingPrice)
		//console.log("开仓格式：",openPrice)
		//手数
		let lots = parseFloat(data.volume)
		//console.log("手数：",lots)
		//杠桿倍数 
		let base = userInfo.userGroup.leverage
		//console.log("杠桿倍数：",base)
		//手续费
		let fee = data.openingFee ? data.openingFee : 0
		// console.log("手续费：",fee)
		//合约量
		let contractSize = data.contractSize ? data.contractSize : 1
		//console.log("合约量：",contractSize)

		
		//盈亏
		let profitAndLoss = way * (currentPrice - openPrice) * lots  * contractSize
		
		//汇率
		let exchange = 1
		
		if(symbolInfo.exchangeType!=0){
			//要算汇率
			let exchangeSymbol = symbolInfo.exchangeSymbol
			exchange = appUtils.getExchange(exchangeSymbol,pageObj)
			//获取汇率
			if(symbolInfo.exchangeType==1){
				//乘汇率
				profitAndLoss = profitAndLoss * exchange
			}else if(symbolInfo.exchangeType==2){
				//除汇率
				profitAndLoss = profitAndLoss / exchange
			}
		}
		
		data.profitAndLoss = profitAndLoss.toFixed(data.decimalPlaces)
		return profitAndLoss
	},
	//获取汇率品类
	getExchange(symbol,pageObj){
		let symbols = pageObj.$global.symbols
		if(symbols){
			let sysmbolInfo = pageObj.$global.symbols.find(e=>{return e.symbol==symbol})
			if(sysmbolInfo){
				let bid = parseFloat(sysmbolInfo.bid)
				return bid
			}
		}
		return 1
	},
	//计算净值: 余额+盈亏
	netWorth(data) {
		let balance = 0
		let value = balance + appUtils.profitAndLossTotal(data)
		return value
	},
	//可用预付款: 余额 - 所有保证金（1手保证金x手数）的和
	availablePrepayment(data = [], order = []) {
		let balance = 0
		let totalBond = 0
		//每个订单的保证金
		order.forEach(e => {
			totalBond = totalBond + e.bond * e.lots
		})
		let value = balance - totalBond
		return value
	},
	//计算止盈止损
	getPendingLossSpread(price, decimalPlaces, pendingOrderAndStopLossSpread, way) {
		let count = (price * Math.pow(10, decimalPlaces) + (pendingOrderAndStopLossSpread * way)) / Math.pow(10,
			decimalPlaces)
		return count
	},
	//计算预付款
	getMarginValue(data, price, leverage, value) {

		let resultValue = 0
		//合约量
		let contractSize = data.contractSize ? data.contractSize : 1
		//计算预付款类型
		let marginUnit = data.marginUnit
		// 1、第一种情况：若保证金类别是固定值，初始保证金是2000，如果采购了5手，那预
		// 付款就是2000*5=10000
		// 2、第二种情况：若保证金类别是百分比，初始保证金是3，总金额（订单开仓价格2000*
		// 手数5*每手合约量*初始保证金*杠杆倍数）是10000,那么预付款就是10000*3%=300
		if (marginUnit == 1) {
			//固定值
			resultValue = data.initialMargin * value
		} else {
			//百分比
			resultValue = (price * value * contractSize * data.initialMargin * leverage) * (data.initialMargin / 100)
		}
		return resultValue.toFixed(2)
	},
	//计算手续费
	getFee(userInfo,volume,price,data,leverage){
		let fee = 0
		let userGroup = userInfo.userGroup
		
		//手续费单位  1固定值  2百分比
		let feeUnit = userGroup.feeUnit
		//手续费类型  1//每手  2每处理
		let feeType = userGroup.feeType
		//手续费数值
		let feeValue = userGroup.feeValue
		//计算手续费说明
		//固定值
		//手续费类型  
		//按每手   手续费 * 手数
		//按每次处理    手续费
		//百分比 总金额（订单开仓价格2000*手数5*每手合约量10 * 杠杆）*3%
		if (feeUnit == 1) {
			//固定值
			if (feeType == 1) {
				//每手
				fee = volume * feeValue
			} else {
				//每处理
				fee = feeValue
			}
		} else {
			//百分比
			//合约量
			let contractSize = data.contractSize ? data.contractSize : 1
			fee = (price * volume * contractSize * leverage) * (feeValue / 100)
		}
		return fee.toFixed(2)
	},
	getStatusText(status){
		let text = ""
		switch (status) {
			case -1:
				text = "Filled"
				break;
			case 0:
				text = "Pending"
				break;
			case 1:
				text = "Placed"
				break;
			default:
				break;
		}
		return text
	},
	//判断数字是否有小数点后几位
	//num代表需要检测的数字，digit代表检测的位数
	determineDecimals(num,digit){
		if(String(num).indexOf(".") !=-1){
			var x = String(num).indexOf(".")+1;//得到小数点的位置
			var y = String(num).length - x;//小数点的位数
			if (y > digit) {
				return true
			} else {
				return false
			}
		}else{
			return false
		}
	},
	// 返回数字整数部分
	getInteger(number) {
		let numberString = number.toString(); // 将数字转换为字符串
		let integer = 0;
		if(numberString.split('.').length > 0){
			integer = parseInt(numberString.split('.')[0])
		}
		
		return isNaN(integer) ? 0 : integer // 如果无法转换为数值，则将整数部分设为 0
	},
	// 返回数字小数部分
	getDecimal(number) {
		let numberString = number.toString(); // 将数字转换为字符串
		let decimal = '';
		if(numberString.split('.').length > 1){
			decimal = numberString.split('.')[1]
		}
		return decimal ? decimal : 0 // 如果无法转换为数值，则将整数部分设为 0
	}
}

export { appUtils }
import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"

let serviceName = "orderHistory/"

const orderHistory = {
    //订单列表
    getHistoryOrders(data) {
        let url = appUrl.getBaseUrl() + serviceName + "listFrontPage"
        return http.post(url,data);
    },
	
	//订单详情
	detailFront(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "detailFront"
	    return http.post(url,data);
	},
	
	//历史价位列表
	getHistoricalPriceList(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listHistoryPage"
	    return http.post(url,data);
	},
	
	//历史订单列表
	getHistoricalOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listDealPage"
	    return http.post(url,data);
	},
	
	//历史成交列表
	getDealList(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listRecordPage"
	    return http.post(url,data);
	},
	
	//获取价位模块头部金额
	getListHistoryMoney(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listHistoryMoney"
	    return http.post(url,data);
	},
	
	//获取成交模块头部金额
	getListRecordMoney(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listRecordMoney"
	    return http.post(url,data);
	}
}

export default orderHistory;

<template>
	<div class="historyOrder-page">
		<div class="flex-sp">
			<div class="flex">
				<div class="tab-btn" :class="tabsIndex == 0 ? 'is-tab':''" @click="changeTabs(0)">{{$t('history.price')}}</div>
				<div class="tab-btn" :class="tabsIndex == 1 ? 'is-tab':''" @click="changeTabs(1)">{{$t('tab.order')}}</div>
				<div class="tab-btn" :class="tabsIndex == 2 ? 'is-tab':''" @click="changeTabs(2)">{{$t('history.makeDeal')}}</div>
			</div>
			<img @dragstart.prevent @click="openFilterDialog()" class="filter-img" src="@/static/image/filter.png"/>
		</div>
		<el-table
		    :data="datas"
			empty-text="No Data"
		    style="width: 100%;padding: 0 10px;"
			height="100%"
			ref="orderTable"
			v-if="showTable"
			@row-contextmenu = "openMenu"
			@sort-change="sortChange"
		    >
			<el-table-column
			  prop="symbol"
			  :label="$t('history.variety')"
			  sortable="custom">
			  <template slot-scope="scope">
			      <div class="table-value bold-value">{{ scope.row.symbol }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="id"
			  :label="$t('trade.orderNumber')"
			  width="120"
			  :sortable="tabsIndex == 1 ? 'custom':false"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">#{{ scope.row.id }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  v-if="tabsIndex != 1"
			  prop="openingPrice"
			  :label="$t('history.quotation')"
			  :sortable="tabsIndex == 0 ? 'custom':false">
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.openingPrice }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="direction"
			  :label="$t('history.type')"
			  sortable="custom">
			  <template slot-scope="scope">
			      <div v-if="tabsIndex === 0 && scope.row.symbol != 'Balance'" class="table-value" :class="scope.row.direction==-1? 'sell-color':'buy-color'">{{ scope.row.direction==-1?'sell':'buy' }}</div>
				  <div v-else-if="tabsIndex === 1 && scope.row.symbol != 'Balance' && scope.row.profitMoney == null">
					  <div v-if="scope.row.tradeType ==0" class="table-value" :class="scope.row.direction == 1 ? 'buy-color' : 'sell-color'">{{scope.row.direction == 1 ? "buy":"sell"}}</div>
					  <div v-if="scope.row.tradeType ==1" class="table-value buy-color">buy limit</div>
					  <div v-if="scope.row.tradeType ==2" class="table-value sell-color">sell limit</div>
					  <div v-if="scope.row.tradeType ==3" class="table-value buy-color">buy stop</div>
					  <div v-if="scope.row.tradeType ==4" class="table-value sell-color">sell stop</div>
					  <div v-if="scope.row.tradeType ==5" class="table-value buy-color">buy limit</div>
					  <div v-if="scope.row.tradeType ==6" class="table-value sell-color">sell limit</div>
				  </div>
				  <div v-else-if="tabsIndex === 1 && scope.row.symbol != 'Balance' && scope.row.profitMoney != null">
					  <div :class="scope.row.direction == 1 ? 'buy-color' : 'sell-color'" class="table-value">{{scope.row.direction == 1 ? "buy":"sell"}}</div>
				  </div>
				  <div v-else-if="tabsIndex === 2">
				  	<div v-if="scope.row.dealType ==0" class="table-value buy-color">buy in</div>
				  	<div v-if="scope.row.dealType ==1" class="table-value sell-color">sell in</div>
				  	<div v-if="scope.row.dealType ==2" class="table-value sell-color">sell out</div>
				  	<div v-if="scope.row.dealType ==3" class="table-value buy-color">buy out</div>
				  </div>
			  </template>
			</el-table-column>
			<el-table-column
				width="70"
			  prop="symbol"
			  :label="$t('order.tradingVolume')"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.volume }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="symbol"
			  :label="$t('trade.price')"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.transactionPrice ? scope.row.transactionPrice : scope.row.openingPrice }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="symbol"
			  label="S/L"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.loss?scope.row.loss:'-' }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="symbol"
			  label="T/P"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.profit?scope.row.profit:'-' }}</div>
			  </template>
			</el-table-column>
			<el-table-column
				width="68"
			  v-if="tabsIndex != 1"
			  prop="symbol"
			  :label="$t('index.popup.inventory')"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.storageFee?scope.row.storageFee:0 }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  prop="symbol"
			  :label="$t('index.popup.handling')"
			  >
			  <template slot-scope="scope">
			      <div class="table-value">{{ scope.row.symbol=='Balance'?'-':scope.row.openingFee?scope.row.openingFee:0 }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  v-if="tabsIndex != 1"
			  prop="profitMoney"
			  :label="$t('trade.profit')"
			  sortable="custom">
			  <template slot-scope="scope">
			      <div class="table-value" :class="scope.row.profitMoney < 0 ? 'sell-color':'buy-color'">{{ scope.row.profitMoney ? parseFloat(scope.row.profitMoney).toFixed(2) : 0 }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  width="160"
			  prop="openTime"
			  :label="$t('trade.openingTime')"
			  sortable="custom">
			  <template slot-scope="scope">
			      <div class="table-value">{{ mixinDateFomat(scope.row.createTime) }}</div>
			  </template>
			</el-table-column>
			<el-table-column
			  v-if="tabsIndex == 0"
			  width="160"
			  prop="closeTime"
			  :label="$t('trade.closingTime')"
			  sortable="custom">
			  <template slot-scope="scope">
			      <div class="table-value">{{ mixinDateFomat(scope.row.closeTime) }}</div>
			  </template>
			</el-table-column>
		</el-table>
		<!-- <div class="table-head">
			<div class="head-text">交易品种</div>
			<div class="head-text" style="width: 160px;">订单号</div>
			<div v-if="tabsIndex != 1" class="head-text">报价</div>
			<div class="head-text">类型</div>
			<div class="head-text">交易量</div>
			<div class="head-text">价格</div>
			<div class="head-text">S/L</div>
			<div class="head-text">T/P</div>
			<div v-if="tabsIndex != 1" class="head-text">库存费</div>
			<div class="head-text">手续费</div>
			<div v-if="tabsIndex != 1" class="head-text">利润</div>
			<div class="head-text" style="width: 200px;">开仓时间</div>
			<div v-if="tabsIndex == 0" class="head-text" style="width: 200px;">平仓时间</div>
		</div>
		<div ref="HistoryOrder" class="body-back">
			<HistoryOrderItem :tabsIndex="tabsIndex" @tradeShow="tradeShow" @symbolClick="symbolClick" :orderItem="item" v-for="item in datas"></HistoryOrderItem>
		</div> -->
		<div v-if="showDialog" class="dia-model">
			<div class="model-center">
				<div @click="chooseSymbolEv(item.symbol)" v-for="item in filteredBreedList" class="breed-name">{{item.symbol}}</div>
			</div>
		</div>
		<FilterDialog @changeFilter="changeFilter" ref="FilterDialog"></FilterDialog>
	</div>
</template>

<script>
	import HistoryOrderItem from '@/components/historyOrder/historyOrderItem.vue';
	import FilterDialog from '@/components/historyOrder/filterDialog.vue';
	export default{
		components: {
			HistoryOrderItem,
			FilterDialog
		},
		data() {
			return{
				loadIndex:0,
				userWalletData:{},
				showDialog:false,
				tabsIndex:0,
				datas:[],
				isSearch:false,
				isOver:false,
				showTable:true
			}
		},
		created() {
		},
		watch:{
			loadIndex(data) {
				this.filterParams.num ++ 
				this.getData()
			},
		},
		props:{
			filterParams: {
				type: Object,
				default: () => {}
			},
		},
		mounted() {
			this.filterParams.num = 1
			this.getData()
			this.lazyLoading()
		},
		methods:{
			//打开交易品种和时间筛选
			openFilterDialog(){
				this.$refs.FilterDialog.openDialog()
			},
			lazyLoading() {
			  this.$refs.orderTable.bodyWrapper.addEventListener('scroll', (res) => {
			    const height = res.target
			    const clientHeight = height.clientHeight
			    const scrollTop = height.scrollTop
			    const scrollHeight = height.scrollHeight
			   
			    if (clientHeight + scrollTop === scrollHeight) {
					this.loadIndex++
			    }
			  }, true)	
			},
			changeTabs(index){
				this.showTable = false
				this.tabsIndex = index
				this.filterParams.num = 1
				this.datas = []
				this.isOver = false
				this.getData()
				this.$emit('tabsIndex',index)
				this.$nextTick(()=>{
					this.showTable = true
				})
			},
			//获取数据
			getData() {
				this.isSearch = true
				let paramsData = {
					"symbol": this.filterParams.symbol == this.$t('history.allVariety')? '' : this.filterParams.symbol,
					"searchSort": this.filterParams.searchSort,
					"searchType": this.filterParams.searchType,
					"pageNo": this.filterParams.num,
					"pageSize": this.filterParams.size
				}
				
				if (this.filterParams.createTimeStart != 0) {
				  paramsData.createTimeStart = this.filterParams.createTimeStart;
				}
				
				if (this.filterParams.createTimeEnd != 0) {
				  paramsData.createTimeEnd = this.filterParams.createTimeEnd;
				}
				
				if(this.tabsIndex === 0){
					this.$api.orderHistory.getHistoricalPriceList(paramsData).then(res => {
						if (res && res.code == 200) {
							if(this.filterParams.num === 1){
								this.datas = res.data.records
							}else{
								this.datas = this.datas.concat(res.data.records)
							}
							
							if(res.data.records.length > 0){
								this.isOver = false
							}else{
								this.isOver = true
							}
							
							this.isSearch = false
						}
					})
				}else if(this.tabsIndex === 1){
					this.$api.orderHistory.getHistoricalOrder(paramsData).then(res => {
						if (res && res.code == 200) {

							if(this.filterParams.num === 1){
								this.datas = res.data.records
							}else{
								this.datas = this.datas.concat(res.data.records)
							}
							this.$emit('orderTotal', res.data.total);
							
							if(res.data.records.length > 0){
								this.isOver = false
							}else{
								this.isOver = true
							}
							
							this.isSearch = false
						}
					})
				}else if(this.tabsIndex === 2){
					this.$api.orderHistory.getDealList(paramsData).then(res => {
						if (res && res.code == 200) {

							if(this.filterParams.num === 1){
								this.datas = res.data.records
							}else{
								this.datas = this.datas.concat(res.data.records)
							}
							
							if(res.data.records.length > 0){
								this.isOver = false
							}else{
								this.isOver = true
							}
							
							this.isSearch = false
						}
					})
				}
			},
			chooseSymbolEv(symbol){

				this.filterParams.symbol = symbol
				this.showDialog = false
				this.filterParams.num = 1
				this.getData()
			},
			//接收子组件传递过来的筛选条件
			changeFilter(val){
				this.filterParams.symbol = val.symbolValue
				this.filterParams.createTimeStart = val.closeTimeStart;
				this.filterParams.createTimeEnd = val.closeTimeSEnd;
				this.filterParams.num = 1
				this.getData()
				this.$emit('changeFilter',val)
			},
			//子组件回调 调用新订单方法
			tradeShow(item){
				this.$emit('tradeShow', item);
			},
			//子组件回调 调用图表方法
			symbolClick(item){
				this.$emit('symbolClick', item);
			},
			//历史订单列表排序
			sortChange(e){
				if(e.order){
					this.filterParams.searchType = e.prop
					if(e.order == "ascending"){
						this.filterParams.searchSort = "asc"
					}else if(e.order == "descending"){
						this.filterParams.searchSort = "desc"
					}
				}else{
					this.filterParams.searchType = ''
					this.filterParams.searchSort = ''
				}
				this.filterParams.num = 1
				this.getData()
			},
			//右键点击弹窗
			openMenu(row, column, event) {
				if(row.dealType==4){
					//充值不显示菜单
					return false;
				}
				event.preventDefault()
			    let that = this;
			    this.$contextmenu({
			        items: [
			            {
			                label: this.$t('index.popup.newOrder'),
			                onClick: () => {
			                    that.$emit('tradeShow', row);
			                }
			            },
			            {
			                label: this.$t('index.popup.chart'),
			                onClick: () => {
			                    that.$emit('symbolClick',row);
			                }
			            }
			        ],
			        event,
			        customClass: "symbol_menu",
			        zIndex: 999,
			        minWidth: 88,
			    });
			    return false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.historyOrder-page{
		height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		
		.flex-sp{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-right: 16px;
		}
		
		.filter-img{
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
		
		.flex{
			display: flex;
			padding: 0 16px;
		}
		
		.tab-btn{
			margin-top: 8px;
			margin-right: 10px;
			padding: 4px 10px;
			font-size: 14px;
			line-height: 24px;
			border-radius: 8px;
			cursor: pointer;
		}
		
		.is-tab{
			font-weight: bold;
			background-color: #F2F8FF;
		}
		
		.table-head{
			margin: 0 16px;
			height: 48px;
			border-bottom: 1px solid #ECEFFB;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.head-text{
				text-align: left;
				width: 9.09%;
				font-size: 13px;
				color: #8996B4;
			}
		}
		
		.body-back{
			height: 100%;
			flex: 1;
			overflow-y: auto;
		}
	}
</style>
<style lang="scss" scoped>
.bold-value{
	font-weight: bold;
}	
.table-value{
	font-size: 14px;
}
.buy-color{
	color: #2568FF;
}
.sell-color{
	color: #DD4F4F;
}
	
::v-deep .el-table th.el-table__cell>.cell {
    display: flex;
    align-items: center;
}
::v-deep .el-table .cell{
  height:23px!important;
}
/* 给固定列设置下边距 */
::v-deep .el-table .el-table__fixed {    
        height: auto !important;
        bottom: 8px !important; /* 具体值是多少根据你横向滚动条的高度进行设置 */
    }
    /* 去掉固定列下方的横线 */
    ::v-deep .el-table__fixed::before, .el-table__fixed-right::before {     
        display:none;
    }
    ::v-deep .el-table__fixed .el-table__fixed-body-wrapper .el-table__body {
        padding-bottom: 8px !important;
    }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    height: 8px; 
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }
  ::v-deep .el-table--border, .el-table--group {
    border:none
  }
  ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
    background-color: #FFFFFF !important;
  }
  ::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: none;
  }
  ::v-deep .el-table__header .cell {
    color: #8996B4;
    font-size: 13px;
  }
  ::v-deep .el-table__body .cell {
    color:#000000
  }
  ::v-deep .hover-row td{
    background-color: #F2F8FF !important;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #F2F8FF !important;
  }
  ::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  } 
</style>
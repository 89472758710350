<template>
  <div class="filterList">
	<div class="table-head">
		<div class="head-text">{{$t('history.variety')}}</div>
		<div class="head-text">{{$t('history.quotation')}}</div>
		<div class="head-text" style="width: 200px;">{{$t('quotation.time')}}</div>
		<div class="head-text">{{$t('history.type')}}</div>
		<div class="head-text">{{$t('order.tradingVolume')}}</div>
		<div class="head-text">{{$t('trade.price')}}</div>
		<div class="head-text">S/L</div>
		<div class="head-text">T/P</div>
		<div class="head-text">{{$t('history.price')}}</div>
		<div class="head-text">{{$t('index.popup.inventory')}}</div>
		<div class="head-text"></div>
	</div>  
	<div class="body-back">
		<PositionItem @restoredOrderFn="restoredOrderFn" @tradeShow="tradeShow" @symbolClick="symbolClick" @updateHangOrder="updateHangOrder" :orderItem="item" v-for="(item,index) in hangOrders" :key="index"></PositionItem>
	</div>
  </div>
</template>

<script>
	import PositionItem from '@/components/filterList/positionItem.vue';
export default {
	components: {
		PositionItem
	},
  props:{
	  hangOrders: {
	  	type: Array,
	  	default: []
	  }
  },
  mounted() {
  },
  methods: {
	//取消订单
	restoredOrderFn(item) {
		this.$emit('restoredOrderFn',item)
	},
	//子组件回调 调用新订单方法
	tradeShow(item){
		this.$emit('tradeShow', item);
	},
	//子组件回调 调用图表方法
	symbolClick(item){
		this.$emit('symbolClick', item);
	},
	//子组件回调 修改订单
	updateHangOrder(item){
		this.$emit('updateHangOrder', item);
	},
  }
}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.filterList {
		height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		
		.table-head{
			margin: 0 16px;
			height: 48px;
			border-bottom: 1px solid #ECEFFB;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.head-text{
				text-align: left;
				width: 9.09%;
				font-size: 13px;
				color: #8996B4;
			}
		}
		
		.body-back{
			height: 100%;
			flex: 1;
			overflow-y: auto;
		}
		
	.optionalModel {
	   padding-bottom: 60px;
	}
	
	.table-body:hover {
	  background-color: #F2F8FF; /* 鼠标移上去时改变的背景色 */
	}
}
</style>

<!-- 启动页 -->
<template>
  <div :style="themeStyle">
    <div class="loading"></div>
    <div class="loading-child"></div>
  </div>
</template>
<script>
import { readDb ,upUserData} from "@/utils/userUtils"
import { appUrl } from "@/utils/appUrls";
import {decrypt} from '@/utils/utils'
export default {
  name: 'LoadingView',
  data() {
    return {
      serviceUrl: null
    }
  },
  mounted() {

    //获取跳转参数
    let query = this.$route.query
    if(query && query.data){
      let dataStr =  query.data
      try {
        //解密
        let data = JSON.parse(decodeURI(decrypt(dataStr)))
        //是否是crm跳转进来
        if(data && data.userInfo && data.appService){
          //设置非首次打开
          this.$storage.setCache("isShowAppTip", 1)
          //获取用户信息
          //临时更新vuex(userInfo)
          this.$store.commit('upUserInfo', data.userInfo)
          this.$storage.setCache('userInfo',data.userInfo)
          
          let appService = data.appService
          let url = appService.url
          //获取用户信息
          this.$api.user.getUserInfoByUrl(url).then(res=>{
            if(res && res.data){
              
              let userInfo = res.data
              userInfo.token = data.userInfo.token
              userInfo.appService = data.appService
              //更新db
              upUserData(this, userInfo, () => {
                //更新vuex
                this.$store.commit('upUserInfo', userInfo)
                this.setApiBaseUrl(url)
                this.getAppDefault(false)
              })
            }else{
              this.getAppDefault()
            }
          })
        }else{
          this.getAppDefault()
        }
      } catch (error) {
        this.getAppDefault()
      }

    } else {

      //设置userInfo
      let userInfo = this.$storage.getCache('userInfo')
      if (userInfo) {
        //已登录
        //取数据
        readDb(this, userInfo, res => {
          if (res && res.length > 0) {
            let data = res[0]
            //更新vuex
            this.$store.commit('upUserInfo', data)
            let appService = data.appService
            if (appService) {
              this.setApiBaseUrl(appService.url)
              this.getAppDefault(false)
            } else {
              //没有appService 需要去取默认
              this.getAppDefault()
            }

          } else {
            this.$storage.setCache('userInfo', null)
            this.$store.commit('upUserInfo', null)
            this.getAppDefault()
          }
        })
      } else {
        this.getAppDefault()
      }
    }
  },
  methods: {
    //获取默认服务商
    getAppDefault(isSetUrl = true) {
      this.$api.application.getDefault().then((res) => {
        if (res && res.data) {
          let data = res.data[0]
          //更新默认服务器vuex
          this.$store.commit('setDefaultService', data)
          this.serviceUrl = data.url
          if (isSetUrl) {
            //设置接口 baseUrl
            this.setApiBaseUrl(this.serviceUrl)
          }
          //进入主应用
          this.goIndex()
        }
      })
    },
    //设置BaseUrl
    setApiBaseUrl(data) {
      let baseUrl = appUrl.getUrlForUrls(data)
      appUrl.setBaseUrl(baseUrl)
    },
    //进入主应用
    goIndex() {
      setTimeout(() => {
        this.$router.replace('/index')
      }, 100);

    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 80px;
  height: 80px;
  top: 45%;
  left: 50%;
  border: 2px solid#2B64FF;
  border-top-color: transparent;
  border-radius: 100%;
  margin-left: -36px;
  margin-top: -40px;
  animation: turn1 infinite 1s linear;
}

.loading-child {
  position: fixed;
  width: 60px;
  height: 60px;
  top: 45%;
  left: 50%;
  border: 2px solid#2B64FF;
  border-top-color: transparent;
  border-radius: 100%;
  margin-left: -27px;
  margin-top: -30px;
  animation: turn2 infinite 1.2s linear;
}

@keyframes turn2 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes turn1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>
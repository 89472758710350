<template>
	<div>
		<el-popover placement="bottom-start" width="280" trigger="click" v-model="indePopover" @show="getCurrentLanguage">
			<div class="login-popover">
				<div v-if="userInfo" class="popover-top">
					<div class="top-title no-select">SP Quotes Web {{ userInfo.mockStatus == 2 ? 'Demo' : '' }}</div>
					<div class="top-title2 no-select">{{ userInfo.userAccount }}</div>
				</div>
				<div @click="userFn(0)" v-else class="popover-top">
					<div class="top-title no-select">{{ $t('login.loginAccount') }}</div>
					<div class="top-title2 no-select">{{ $t('order.welcome') }}</div>
				</div>
				<img @dragstart.prevent v-if="userInfo && userInfo.mockStatus == 2" class="demo-img" src="@/assets/demologo.png" />
				<el-popover placement="left-start" width="114" trigger="click" class="popover-tabs-back" v-model="showLogin"
					@show="loginShow">
					<div class="popover-tabs" slot="reference">
						<div class="flex-al">
							<img @dragstart.prevent class="tabs-img" src="@/assets/accounts.png" />
							<span class="tabs-name">{{ $t('order.transactionAccount') }}</span>
						</div>
						<div class="flex-al">
							<img @dragstart.prevent class="arrow-img" src="@/assets/arrow.png" />
						</div>
					</div>
					<div @click="userFn(0)" class="tabs-back">
						{{ $t('login.loginAccount') }}
					</div>
					<div @click="userFn(1)" class="tabs-back">
						{{ $t('index.popup.openDemo') }}
					</div>
					<div v-if="userInfo" @click="userFn(2)" class="tabs-back">
						{{ $t('order.myAccount') }}
					</div>
				</el-popover>
				<el-popover placement="left-start" width="114" trigger="click" class="popover-tabs-back"
					v-model="showLangue" @show="langueShow">
					<div class="popover-tabs" slot="reference">
						<div class="flex-al">
							<img @dragstart.prevent class="tabs-img" src="@/assets/langue.png" />
							<span class="tabs-name">{{ $t('index.language') }}</span>
						</div>
						<div class="flex-al">
							<div class="langue-name">{{ appLanguage }}</div>
							<img @dragstart.prevent class="arrow-img" src="@/assets/arrow.png" />
						</div>
					</div>
					<div v-for="item in locales" @click="onLocaleChange(item)" class="tabs-back">
						{{ item.text }}
					</div>
				</el-popover>
			</div>
			<div slot="reference" class="menu-box">
				<img @dragstart.prevent class="menu-icon" src="@/assets/menu.png" :alt="$t('order.menu')"
					:title="$t('order.menu')">
			</div>
		</el-popover>
	</div>
</template>

<script>
export default {
	data() {
		return {
			appLanguage: '',
			indePopover: false,
			showLogin: false,
			showLangue: false,
			locales: [{
				text: "English",
				code: 'en'
			},
			{
				text: "简体中文",
				code: 'zh-Hans'
			},
			{
				text: "繁體中文",
				code: 'zh-Hant'
			},
			{
				text: "日本",
				code: 'ja'
			},
			{
				text: "Español",
				code: 'es'
			},
			{
				text: "Русский",
				code: 'ru'
			},
			{
				text: "Français",
				code: 'fr'
			},
			{
				text: "Deutsch",
				code: 'de'
			}
			]
		}
	},
	mounted() {
		this.$store.commit('setLanguage', this.$i18n.locale); //更新vuex
		this.getCurrentLanguage()
	},
	methods: {
		//获取当前语言
		getCurrentLanguage() {
			let localLangue = localStorage.getItem('appLanguage')
			if (localLangue && localLangue != '') {
				switch (localLangue) {
					case "zh-Hans":
						this.appLanguage = "简体中文"
						break;
					case "zh-Hant":
						this.appLanguage = "繁体"
						break;
					case "ja":
						this.appLanguage = "日本"
						break;
					case "es":
						this.appLanguage = "Español"
						break;
					case "ru":
						this.appLanguage = "Русский"
						break;
					case "fr":
						this.appLanguage = "Français"
						break;
					case "de":
						this.appLanguage = "Deutsch"
						break;
					default:
						this.appLanguage = "English"
						break;
				}
			} else {
				this.appLanguage = "简体中文"
			}
		},
		//弹出登录窗口
		userFn(index) {
			this.$emit('userFn', index)
			this.indePopover = false
			this.showLogin = false
		},
		//当el-popover打开时关闭另一个
		langueShow() {
			this.showLogin = false
		},
		//当el-popover打开时关闭另一个
		loginShow() {
			this.showLangue = false
		},
		//切换语言
		onLocaleChange(e) {
			let localLangue = localStorage.getItem('appLanguage');
			if (localLangue && localLangue == e.code) {//重复点击不缓存 
				this.showLangue = false;
				this.indePopover = false;
				return false;
			}
			localStorage.setItem('appLanguage', e.code);
			this.$store.commit('setLanguage', e.code);
			this.$i18n.locale = e.code;
			this.$emit('changeLanguage', e.code);
			this.showLangue = false;
			this.indePopover = false;
		}
	}
}
</script>

<style >
.el-popper .popper__arrow {
	display: none !important;
}

.el-popover {
	padding: 0px !important;
	margin-left: 0px !important;
}
</style>
<style lang="scss" scoped>
.login-popover {
	position: relative;
	margin-top: -4px;

	.demo-img {
		width: 58px;
		height: 58px;
		position: absolute;
		right: 0;
		top: 0;
	}

	.popover-top {
		margin: 0 16px;
		padding: 14px 0;
		border-bottom: 1px solid #EEF1FC;
		cursor: pointer;

		.top-title {
			font-size: 16px;
			font-weight: bold;
			line-height: 27px;
			color: #000;
		}

		.top-title2 {
			font-size: 13px;
			color: #8996B4;
			line-height: 15px;
		}
	}

	.popover-tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
		cursor: pointer;
		padding: 0 16px;

		.flex-al {
			display: flex;
			align-items: center;
		}

		.tabs-img {
			width: 24px;
			height: 24px;
			margin-right: 10px;
		}

		.tabs-name {
			font-size: 14px;
			color: #000;
			line-height: 18px;
		}

		.langue-name {
			font-size: 14px;
			color: #8996B4;
			line-height: 18px;
			margin-right: 2px;
		}

		.arrow-img {
			width: 18px;
			height: 18px;
		}
	}
}

.tabs-back {
	width: 100%;
	height: 40px;
	font-size: 14px;
	color: #000;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
}

.popover-tabs:hover {
	background-color: #F2F8FF;
}
</style>
//工具类
const userUtils = {
    //更新用户数据
    async upUserData(pageObj, userInfo, callBack = null) {
        let userId = userInfo.id
        let groupId = userInfo.groupId
        let key = {
            id: userId,
            groupId: groupId
        }
        //先查找是否已存在数据 组合查找
        let userDbData = await pageObj.$db.find(key)
        let res = null
        if (userDbData) {
            //有数据，更新数据 
            res = await pageObj.$db.update(key, userInfo)
        } else {
            //无数据，新增数据
            res = await pageObj.$db.insert(userInfo)
        }
        if (callBack) {
            callBack(res)
        }
    },
    //查用户
    readDb(pageObj, userInfo, callBack = null) {
        let userId = userInfo.id
        let groupId = userInfo.groupId
        let key = {
            id: userId,
            groupId: groupId
        }
        pageObj.$db.find(key).then(res => {
            if (callBack) {
                callBack(res)
            }
        })

    },
    async insertDb(pageObj, userInfo) {
        let res = await pageObj.$db.insert(userInfo)
        return res
    },
    //所有本地账户
    getAccounts(pageObj, callBack = null) {
        let key = {}
        pageObj.$db.find(key).then(res => {
            if (callBack) {
                callBack(res)
            }
        })
    },
    //删除本地账户
    delAccount(pageObj, accountInfo, callBack = null) {
        let key = {
            id: accountInfo.id,
            groupId: accountInfo.groupId
        }
        pageObj.$db.remove(key).then(() => {
            if (callBack) {
                callBack(true)
            }
        })
    }
}

module.exports = {
    upUserData: userUtils.upUserData,
    readDb: userUtils.readDb,
    getAccounts: userUtils.getAccounts,
    delAccount: userUtils.delAccount,
}
<template>
	<div class="form">
		<div class="top-back">
			<el-select class="trade-type"  v-model="tradeType" :placeholder="$t('history.choose')">
			   <el-option
				 
			      v-for="(item,index) in submitWays"
			      :label="item.name"
			      :value="item.type"
				  :key="index">
			    </el-option>
			</el-select>
			<div class="close-back" @click="closeSubmit()">
				<img @dragstart.prevent class="close-img"  src="@/assets/close.png"/>
			</div>
		</div>
		<submit-now v-if="tradeType == 0" :tradeItem="tradeItem" @openOrderBack="openOrderBack"></submit-now>
		<submit-expected v-if="tradeType > 0" :tradeItem="tradeItem" :tradeType="Number(tradeType)"  @openHangOrderBack="openHangOrderBack"></submit-expected>
	</div>
</template>

<script>
import submitNow from "./submitNow.vue"
import submitExpected from "./submitExpected.vue"
export default {
	components: {
		submitNow,
		submitExpected
	},
	data() {
		return {
			tradeType: 0,
		}
	},
	computed: {
		submitWays() {
			return [{
				type: 0,
				name: this.$t('trade.instant'),
			},
			{
				type: 1,
				name: this.$t('trade.buyLimit'),
			},
			{
				type: 2,
				name: this.$t('trade.sellLimit'),
			},
			{
				type: 3,
				name: this.$t('trade.buyStop'),
			},
			{
				type: 4,
				name: this.$t('trade.sellStop'),
			},
			{
				type: 5,
				name: this.$t('trade.buyStopLimit'),
			},
			{
				type: 6,
				name: this.$t('trade.sell​​StopLimit'),
			},
			]
		}
	},
	props: {
		tradeItem: {
			type: Object,
			default: () => { }
		},
	},
	mounted() {

	},
	methods: {
		openOrderBack(data) {
			this.$emit('openOrderBack', data)
		},
		openHangOrderBack(data) {
			this.$emit('openHangOrderBack', data)
		},
		closeSubmit(){
			this.$emit('closeBack',true)
		}
	}
}
</script>

<style lang="scss" scoped>
	::v-deep .el-input__inner{
		height: 44px;
	}
	
	::v-deep .el-select .el-input__inner{
		height: 48px;
	}
	
	::v-deep .el-select-dropdown__item{
		width: 288px;
	}
	
	.top-back{
		display: flex;
		padding: 16px;
		border-bottom: 1px solid #ECEFFB;
		justify-content: space-between;
		
		.trade-type{
			width: 234px;
			
		}
		
		.close-back{
			box-sizing: border-box;
			cursor: pointer;
			width: 48px;
			height: 48px;
			border: 1px solid #ECEFFB;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			
			.close-img{
				width: 12px;
				height: 12px;
			}
		}
	}
</style>
const Buffer = require("./buffer") 
//工具类
const utils = {
	//去重
	setReduce(arr, name) {
		var hash = {};
		return arr.reduce(function (item, next) {
			hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
			return item;
		}, []);
	},
	//数组排序
	sortBykey(ary, key) {
		return ary.sort(function (a, b) {
			let x = a[key]
			let y = b[key]
			return ((x < y) ? -1 : (x > y) ? 1 : 0)
		})
	},
	/** 
	 * 修改时间格式
	 * @params(date)  date  待处理日期
	 * @params(string)	format	格式
	 */
	dateFomat(time, format) {
		if (!format) {
			//默认格式
			format = 'yyyy.mm.dd HH:MM:SS'
		}
		let date = new Date(time * 1000)
		const opt = {
			'y+': date.getFullYear().toString(), // 年
			'm+': (date.getMonth() + 1).toString(), // 月
			'd+': date.getDate().toString(), // 日
			'H+': date.getHours().toString(), // 时
			'M+': date.getMinutes().toString(), // 分
			'S+': date.getSeconds().toString() // 秒
			// 有其他格式化字符需求可以继续添加，必须转化成字符串
		}
		if (/(y+)/.test(format)) {
			format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
		}

		for (var k in opt) {
			if (new RegExp('(' + k + ')').test(format)) {
				format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? opt[k] : ('00' + opt[k]).substr(('' +
					opt[k]).length))
			}
		}
		return format
	},

	//获取最近一周的起始结束的时间戳
	lastWeekTime() {
		let end = new Date();
		let year = end.getFullYear();
		let month = end.getMonth() + 1;
		let day = end.getDate();
		let dateObj = {};
		dateObj.end = year + '/' + month + '/' + day;
		if (day - 7 <= 0) {   //如果在当月7日之前
			let startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
			if (month - 1 <= 0) { //如果在当年的1月份
				dateObj.start = (year - 1) + '/' + 12 + '/' + (31 - (7 - day));
			} else {
				dateObj.start = year + '/' + (month - 1) + '/' + (startMonthDay - (7 - day));
			}
		} else {
			dateObj.start = year + '/' + month + '/' + (day - 7);
		}
		let startime = parseInt(new Date(dateObj.start + ' 00:00:00').getTime() / 1000);
		let endtime = parseInt(new Date(dateObj.end + ' 23:59:59').getTime() / 1000);
		return [startime, endtime];
	},
	//最近一个月
	lastMonthTime() {
		let end = new Date();
		let year = end.getFullYear();
		let month = end.getMonth() + 1;//0-11表示1-12月
		let day = end.getDate();
		let dateObj = {};
		dateObj.end = year + '/' + month + '/' + day;
		let endMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if (month - 1 <= 0) { //如果是1月，年数往前推一年　
			dateObj.start = (year - 1) + '/' + 12 + '/' + day;
		} else {
			let startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
			if (startMonthDay < day) {    //1个月前所在月的总天数小于现在的天日期
				if (day < endMonthDay) {        //当前天日期小于当前月总天数
					dateObj.start = year + '/' + (month - 1) + '/' + (startMonthDay - (endMonthDay - day));
				} else {
					dateObj.start = year + '/' + (month - 1) + '/' + startMonthDay;
				}
			} else {
				dateObj.start = year + '/' + (month - 1) + '/' + day;
			}
		}
		let startime = parseInt(new Date(dateObj.start + ' 00:00:00').getTime() / 1000);
		let endtime = parseInt(new Date(dateObj.end + ' 23:59:59').getTime() / 1000);
		return [startime, endtime];
	},
	//最近n个月
	beforeThreeMonth(n = 3) {
		let end = new Date();
		let year = end.getFullYear();
		let month = end.getMonth() + 1;//0-11表示1-12月
		let day = end.getDate();
		let dateObj = {};
		dateObj.end = year + '/' + month + '/' + day;
		let endMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if (month - n <= 0) { //如果是1、2、3月，年数往前推一年
			let start3MonthDay = new Date((year - 1), (12 - (n - parseInt(month))), 0).getDate();    //3个月前所在月的总天数
			if (start3MonthDay < day) {    //3个月前所在月的总天数小于现在的天日期
				dateObj.start = (year - 1) + '/' + (12 - (n - month)) + '/' + start3MonthDay;
			} else {
				dateObj.start = (year - 1) + '/' + (12 - (n - month)) + '/' + day;
			}
		} else {
			let start3MonthDay = new Date(year, (parseInt(month) - n), 0).getDate();    //3个月前所在月的总天数
			if (start3MonthDay < day) {
				if (day < endMonthDay) {
					dateObj.start = year + '/' + (month - n) + '/' + (start3MonthDay - (endMonthDay - day));
				} else {
					dateObj.start = year + '/' + (month - n) + '/' + start3MonthDay;
				}
			} else {
				dateObj.start = year + '/' + (month - n) + '/' + day;
			}
		}
		let startime = parseInt(new Date(dateObj.start + ' 00:00:00').getTime() / 1000);
		let endtime = parseInt(new Date(dateObj.end + ' 23:59:59').getTime() / 1000);
		return [startime, endtime]
	},
	//合并数据
	asignObj(obj1, obj2) {
		if (!(obj2 instanceof Object)) {
			return false
		};
		for (let x in obj2) {
			obj1[x] = obj2[x];
		}
	},
	//深拷贝
	coppyArray(arr) {
		return arr.map((e) => {
			if (typeof e === 'object') {
				return Object.assign({}, e);
			} else {
				return e;
			}
		})
	},
	//解密
	decrypt(text){
		let value = Buffer.from(text,'base64').toString('utf8')  
		return value
	},
	//加密
	encryption(text){
		let value =  Buffer.from(text).toString('base64')
		return value
	},
}

module.exports = {
	coppyArray: utils.coppyArray,
	asignObj: utils.asignObj,
	dateFomat:utils.dateFomat,
	lastMonthTime: utils.lastMonthTime,
	lastWeekTime: utils.lastWeekTime,
	beforeThreeMonth: utils.beforeThreeMonth,
	encryption: utils.encryption,
	decrypt: utils.decrypt
}
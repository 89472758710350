<template>
  <div class="optionalTab">
    <div class="optionalTop">
		<div>
			<div class="tab-text" :class="FilterTabIndex == 0 ? 'is-choose':''" @click="changeTabIndex(0)">{{$t("tab.trade")}}</div>
			<div v-if="FilterTabIndex == 0" class="tab-line"></div>
		</div>
		<div class="split"></div>
		<div>
			<div class="tab-text" :class="FilterTabIndex == 1 ? 'is-choose':''" @click="changeTabIndex(1)">{{$t("tab.order")}}</div>
			<div v-if="FilterTabIndex == 1" class="tab-line"></div>
		</div>
		<div class="split"></div>
		<div>
			<div class="tab-text" :class="FilterTabIndex == 2 ? 'is-choose':''" @click="changeTabIndex(2)">{{$t("tab.history")}}</div>
			<div v-if="FilterTabIndex == 2" class="tab-line"></div>
		</div>
    </div>
	<div class="back-line"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props:{
	  FilterTabIndex: {
	  	type: Number,
	  	default: 0
	  },
  },
  methods: {
		changeTabIndex(index){
			this.$emit("changeFilterTabIndex",index)
		}
  }
}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.optionalTab {
		border-radius: 8px 8px 0px 0px;
	.optionalTop {
	   padding: 0 16px;
	   display: flex;
	   background-color: white;
	   height: 50px;
	   border-radius: 8px 8px 0px 0px;
	}
	
	.is-choose{
		font-weight: bold;
	}
	
	.tab-text{
		font-size: 16px;
		line-height: 50px;
		cursor: pointer;
	}
	
	.split{
		width: 56px;
	}
	
	.tab-line{
		height: 3px;
		background: #2568FF;
		border-radius: 5px;
	}
	
	.back-line{
		margin: 0 16px;
		height: 3px;
		background: #ECEFFB;
		border-radius: 5px;
	}
}
</style>

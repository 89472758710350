<template>
	<div class="balance-module">
	  <div v-if="historyTabIndex == 0 || historyTabIndex == 2" class="balance-detail">
		  <div class="balance-text">{{$t('trade.balance')}}：{{userWalletData.balance ? Number(userWalletData.balance).toFixed(2) : 0}}</div>
		  <div class="balance-text">{{$t('trade.profit')}}：{{userWalletData.profit ? Number(userWalletData.profit).toFixed(2) : 0}}</div>
		  <div class="balance-text">{{$t('index.popup.inventory')}}：{{userWalletData.storageFee ? Number(userWalletData.storageFee).toFixed(2) : 0}}</div>
		  <div class="balance-text">{{$t('index.popup.handling')}}：{{userWalletData.openingFee ? Number(userWalletData.openingFee).toFixed(2) : 0}}</div>
		  <div class="balance-text">{{$t('trade.deposit')}}：{{userWalletData.cashDeposit ? Number(userWalletData.cashDeposit).toFixed(2) : 0}}</div>
	  </div>
	  <div v-if="historyTabIndex == 1" class="balance-detail">
		  <div class="balance-text">{{$t('history.makeDeal')}}：{{HistoryOrderTotal}}</div>
		  <div class="balance-text">{{$t('trade.canceled')}}：0</div>
		  <div class="balance-text">{{$t('trade.all')}}：{{HistoryOrderTotal}}</div>
	  </div>
	</div>
</template>

<script>
	import { lastMonthTime } from "@/utils/utils"
	export default{
		data(){
			return{
				userWalletData:{}
			}
		},
		created() {
			this.historyGetLevel()
		},
		props: {
			filterParams: {
				type: Object,
				default: () => {}
			},
			historyTabIndex: {
				type: Number,
				default: 0
			},
			HistoryOrderTotal: {
				type: Number,
				default: 0
			},
		},
		methods:{
			//筛选条件变换之后重新查询
			againSearch(){
				this.historyGetLevel()
			},
			historyGetLevel(){
				
				let paramsData = {
					"symbol": this.filterParams.symbol == this.$t('history.allVariety')? '' : this.filterParams.symbol
				}
				
				if (this.filterParams.createTimeStart != 0) {
				  paramsData.createTimeStart = this.filterParams.createTimeStart;
				}else{
					 paramsData.createTimeStart = lastMonthTime()[0]
				}
				
				if (this.filterParams.createTimeEnd != 0) {
				  paramsData.createTimeEnd = this.filterParams.createTimeEnd;
				}else{
					paramsData.createTimeEnd = lastMonthTime()[1]
				}
				
				this.$api.orderHistory.getListHistoryMoney(paramsData).then(res => {
					if (res && res.code == 200) {
						this.userWalletData = res.data
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	*div{
		box-sizing: border-box;
	}
	
	.balance-module{
		width: 100%;
		height: 48px;
		position: fixed;
		bottom: 0px;
		background: #F2F5F9;
		z-index: 999;
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		.balance-detail{
			display: flex;
		}
		
		.balance-text{
			font-size: 14px;
			margin-right: 45px;
		}
		
		.profit{
			font-weight: bold;
			font-size: 14px;
		}
		
		.unit{
			color: #2568FF;
		}
	}
</style>
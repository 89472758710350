import { render, staticRenderFns } from "./LoadingView.vue?vue&type=template&id=496d0ee9&scoped=true"
import script from "./LoadingView.vue?vue&type=script&lang=js"
export * from "./LoadingView.vue?vue&type=script&lang=js"
import style0 from "./LoadingView.vue?vue&type=style&index=0&id=496d0ee9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496d0ee9",
  null
  
)

export default component.exports
import Vue from 'vue'
import VueRouter from 'vue-router'
import LoadingView from '../views/LoadingView.vue'
import IndexView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView,
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView,
    meta: {
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router

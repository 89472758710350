//防抖节流
let timeout, result;
const debounce = function (func) {
    let args = arguments;
    if (timeout) {
        clearTimeout(timeout)
    }
    let callNow = !timeout
    timeout = setTimeout(() => {
        timeout = null;
    }, 1000)
    if (callNow) {
        // result = func.apply(this, args) //如this指向有问题再开启 并注释下一行
        result = func(...args)
    }
    return result
}

export default {
      debounce 
}
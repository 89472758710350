<template>
  <div class="market-type">
    <div class="type-head" @click="goBack">
        <img src="@/assets/back_arrow.png" alt="" class="type-arrow">
        <span class="type-text">{{ typeItem }}</span>
    </div>
    <div class="symbol-list">
        <div class="symbol-item" v-for="item in typeList" :key="item" @click="goChart(item)">
            <span class="symbol-text">{{ item }}</span>
            <div class="symbol-set" @click.stop="addSymbol(item)" v-if="newCollectList.indexOf(item) == -1">
                <img src="@/assets/add.png" alt="" class="symbol-add">
            </div>
            <div class="symbol-set" @click.stop="delSymbol(item)" v-else-if="newCollectList.indexOf(item) > -1 && allOrderSymbol.indexOf(item) == -1 &&  newCollectList.length != 1">
                <img src="@/assets/del.png" alt="" class="symbol-del">
            </div>
            <div class="symbol-set" v-else @click.stop="(e)=>{return false}">
                <img src="@/assets/select_disabled.png" alt="" class="symbol-del">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    props: {
        typeItem:{
            type: String,
            default: ''  
        },
        allList:{
            type: Array,
            default: ()=>[]  
        },
        newCollectList:{
            type: Array,
            default: ()=>[] 
        },
        allOrderSymbol: {
            type: Array,
            default: ()=>[]
        }
    },
    mounted(){
        
    },
    methods:{
        goBack() {
            this.$emit('goBack',true);
        },
        addSymbol(item) {
            this.$emit('addSymbolHandle',item);
        },
        delSymbol(item){
            this.$emit('delSymbolHandle',item);
        },
        goChart(item) {
            this.$emit('goChartHandle',item);
        }
    },
    computed: { 
        typeList() {
            let that = this;
            let result = [];
            if(!this.typeItem) {
                return result;
            }
            this.allList.forEach(item =>{
                if(item.tradeType == that.typeItem) {
                    result.push(item.symbol)
                }
            })
            return result;
        }
    }   
}
</script>

<style lang="scss" scoped>
.market-type {
    .type-head {
        height: 48px;
        display: flex;
        align-items: center;
        background-color: #F2F8FF;
        cursor: pointer;
        padding: 0 16px;
        .type-arrow {
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }
        .type-text {
            font-size: 14px;
            color: #2568FF;
        }
    }
}
.symbol-list {
    margin-top: 8px;
    padding: 0 16px;
    .symbol-item {
        display: flex;
        align-items: center;
        height: 48px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 8px;
        border-bottom: 1px solid #EBF1FF;
        cursor: default;
        .symbol-text {
            font-size: 14px;
            color: #111214;
        }
        .symbol-set {
            width: 36px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
        }
        .symbol-add {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .symbol-del {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        &:last-child {
            margin-bottom:0;
        }
        // &:hover {
        //     background-color: #F2F8FF;
        // }
    }
}
</style>
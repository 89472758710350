//kline主题颜色全部配置文件
let klineConfig = {
    "mainSeriesProperties.style": 1, //k线图初始化的类型

    //图表主题配置
    "paneProperties.background":"#FFFFFF", //图表内部也就是K线展示部分的背景颜色
    "paneProperties.vertGridProperties.color": "#E6E6E6", //x轴网格线的颜色
    "paneProperties.horzGridProperties.color": "#E6E6E6", //y轴网格线的颜色
    "paneProperties.crossHairProperties.color": "#989898", //鼠标十字坐标的线颜色
    "paneProperties.crossHairProperties.width": 1, //鼠标十字坐标的线宽度
    "scalesProperties.lineColor": "#46587b", //坐标边框的颜色
    "scalesProperties.textColor": "#000000", //坐标轴刻度的颜色
    // #202FE7
    //最新价格线颜色配置
    "mainSeriesProperties.showPriceLine":true, //是否显示最新价格线
    "mainSeriesProperties.priceLineWidth": 1, //最新价格线宽度
    "mainSeriesProperties.priceLineColor": '', //最新价格线的颜色

    // K线图样式
    "mainSeriesProperties.candleStyle.upColor": "#089981", //上升的样式
    "mainSeriesProperties.candleStyle.downColor": "#F23645", //下降的样式
    "mainSeriesProperties.candleStyle.drawWick": true, //是否展示k线图顶部底部线头
    "mainSeriesProperties.candleStyle.drawBorder": false, //是否展示单个k线的边框
    "mainSeriesProperties.candleStyle.borderColor": "#FFFFFF",
    "mainSeriesProperties.candleStyle.borderUpColor": "#089981",
    "mainSeriesProperties.candleStyle.borderDownColor": "#F23645",
    "mainSeriesProperties.candleStyle.wickUpColor": "rgba( 115, 115, 117, 1)", //k线图上升的顶部线颜色
    "mainSeriesProperties.candleStyle.wickDownColor": "rgba( 115, 115, 117, 1)",//k线图下降的顶部线颜色
    "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,

    //柱状图样式
    "mainSeriesProperties.barStyle.upColor": "#089981", //上升颜色
    "mainSeriesProperties.barStyle.downColor": "#F23645", //下降颜色
   
    //面积线样式
    "mainSeriesProperties.areaStyle.color1": "rgba(0,175,239,0.1)", //顶部渐变色
    "mainSeriesProperties.areaStyle.color2": "rgba(0,175,239,0.1)", //底部渐变色
    "mainSeriesProperties.areaStyle.linecolor": "rgb(0,175,239)", //线条颜色
    "mainSeriesProperties.areaStyle.linewidth": 1, //线条宽度
    "mainSeriesProperties.areaStyle.priceSource": "close" //闭合

}

export default klineConfig;
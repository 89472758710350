<!-- 登录 -->
<template>
    <div class="form-model">

        <!-- 选择交易商 -->
        <Traders type="1" v-if="loginType == 0" :traders="traders" @selectTraderBack="selectTraderBack"></Traders>

        <!-- 登录 -->
        <div class="login-type" v-if="loginType == 1">

            <div class="login-box-header">
                <h3 class="form-title">{{$t('login.loginAccount')}}</h3>
                <span class="link-back" @click="loginType = 0">{{$t('order.goBack')}}</span>
            </div>
            <li class="select-trader">
                <img class="trader-logo" src="@/assets/logo.png" />
                <p class="trader-info">
                    <span class="trader-name">{{ selectTrader.name }}</span>
                    <span class="trader-desc">{{ selectTrader.des }}</span>
                </p>
            </li>
            <el-form :model="loginParam" :rules="rules" ref="loginParam">
                <el-form-item prop="userAccount" >
                    <div class="input-back">
                        <div class="form-name">{{$t('order.loginName')}}</div>
                        <el-input class="form-value" clearable :placeholder="$t('setting.pleasEnter')"
                            v-model="loginParam.userAccount"></el-input>
                    </div>

                </el-form-item>
                <el-form-item prop="password" >
                    <div class="input-back">
                        <div class="form-name">{{$t('login.password')}}</div>
                        <el-input class="form-value" clearable :placeholder="$t('setting.pleasEnter')" v-model="loginParam.password"></el-input>
                    </div>
                </el-form-item>
                <div class="input-back">
                    <div class="form-name">{{$t('login.savePassword')}}</div>
                    <el-switch :width="44" v-model="savePassword" active-color="#34C85A" inactive-color="#E8EBF2">
                    </el-switch>
                </div>
                <el-button class="login-btn pointer" :loading="isLoading" @click="login()">{{ isLoading ? '' : $t('login.login')
                }}</el-button>

            </el-form>
        </div>
    </div>
</template>
<script>
import { upUserData } from "@/utils/userUtils";
import Traders from "./Traders.vue"
export default {
    name: 'Login',
    components: {
        Traders
    },
    watch: {
    },
    props: {
        traders: {
            type: Array,
            default: () => []
        },
        loginInfo: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            savePassword: true,
            loginType: 0,
            loginParam: {
                userAccount: '',
                password: '',
            },
            rules: {
                userAccount: [
                    { required: true, message: this.$t('tip.userName-empty'), trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('tip.password-empty'), trigger: 'blur' }
                ],
            },
            selectTrader: null,
            isLoading: false
        }
    },
    mounted() {
        if(this.loginInfo){
            this.selectTraderBack(this.loginInfo.appService)
            this.loginParam.userAccount = this.loginInfo.userAccount
        }
    },
    methods: {
        //更新用户数据
        setUserData(res) {
            let userInfo = res.data
            //更新cache cahe只放简单的信息
            let userCache = {
                id: userInfo.id,
                groupId: userInfo.groupId,
                token: userInfo.token,
                userAccount: userInfo.userAccount
            }
            this.$storage.setCache('userInfo', userCache)
            //服务器信息
            userInfo.appService = this.selectTrader
            //更新db
            upUserData(this, userInfo, () => {
                //更新vuex
                this.$store.commit('upUserInfo', userInfo)
                //回调
                this.$emit('loginBack', userInfo)
            })
        },

        //登录
        login() {
            let userAccount = this.loginParam.userAccount;
            let password = this.loginParam.password;
            let urls = this.selectTrader.url

            this.$refs.loginParam.validate((valid) => {
                if (valid) {
                    let param = {}
                    param.userAccount = userAccount
                    param.password = password
                    this.isLoading = true
                    this.$api.user.login(urls, param).then(res => {
                        this.isLoading = false
                        if (res && res.data) {
                            //回调
                            let data = res.data
                            data.appService = this.selectTrader
                            this.$emit('loginBack', data)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        //选择交易商
        selectTraderBack(item) {
            this.selectTrader = item
            this.loginType = 1
        }
    }
}
</script>

<style lang="scss" scoped>
.select-trader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 232px;
    height: 36px;
    border-radius: 8px;
    margin-bottom: 12px;

    .trader-logo {
        width: 36px;
        height: 36px;
        margin-right: 8px;
    }

    .trader-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
    }
}

.login-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.form-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
}

.form-model {
    padding: 16px;
    width: calc(560px - 32px);

    .form-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
    }

    .input-back {
        display: flex;
        align-items: center;
        height: 40px;

        .form-name {
            width: 70px;
            font-size: 14px;
            text-align: left;
        }

        ::v-deep .el-input__inner {
            border-radius: 10px;
            border-color: #ECEFFB;
        }

        .form-value {
            width: 222px;
            height: 40px;
        }
    }

    .login-btn {
        padding: 0;
        width: 138px;
        height: 40px;
        background-color: #2568FF;
        border-radius: 10px;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        float: right;
        border:none
    }

    .search-dealers {
        width: 528px;
        height: 48px;
        margin-top: 12px;

        ::v-deep .el-input__inner {
            height: 48px;
        }

        ::v-deep .el-input__icon {
            line-height: 48px;
            font-size: 16px;
        }
    }

    .dealers-back {
        display: flex;
        flex-wrap: wrap;

    }
}
.link-back {
    color: #2568FF;
    cursor: pointer;
}
</style>
  
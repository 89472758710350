import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"
let serviceName = "application/"
const application = {
    //获取默认配置
    getDefault(data = {}) {
        let url = appUrl.getDefaultUrl() + serviceName + "getDefault"
        return http.post(url, data);
    },

    //获取服务商列表
    getTraders(data = {}) {
        let url = appUrl.getDefaultUrl() + serviceName + "listNoPage"
        return http.post(url, data);
    }
}
export default application;
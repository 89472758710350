import Vue from "vue"
import VueI18n from 'vue-i18n'
import en from './en.json'
import zhHans from './zh-Hans.json'
import de from './de'
import es from './es.json'
import fr from './fr.json'
import ja from './ja.json'
import ru from './ru.json'
import zhHant from './zh-Hant.json'

Vue.use(VueI18n)
const messages = {
	en,
	'zh-Hans': zhHans,
	'zh-Hant': zhHant,
	ja,
	es,
	ru,
	fr,
	de
}

const i18n = new VueI18n({
	locale: localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : 'zh-Hans',
	messages,
})

export default i18n
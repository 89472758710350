<template>
	<div class="form">
		<div class="top-back">
			<div class="trade-type">
				<span class="symbol-name">{{ orderItem.symbol }}</span>
			</div>
			<div class="close-back" @click="closeSubmit()">
				<img @dragstart.prevent class="close-img"  src="@/assets/close.png"/>
			</div>
		</div>
		<update-now v-if="!isUpHangOrder"  :tradeItem="tradeItem" :orderItem="orderItem" @updateOrderBack="updateOrderBack"></update-now>
		<update-expected v-else   :tradeItem="tradeItem" :orderItem="orderItem" :tradeType="Number(orderItem.tradeType)" @updateHangOrderBack="updateHangOrderBack"></update-expected>
	</div>
</template>

<script>
import updateNow from "./updateNow.vue"
import updateExpected from "./updateExpected.vue"
export default {
	components: {
		updateNow,
		updateExpected
	},
	data() {
		return {
			tradeType: 0,
			isUpHangOrder:false
		}
	},
	props: {
		tradeItem: {
			type: Object,
			default: () => { }
		},
		orderItem: {
			type: Object,
			default: () => { }
		}
	},
	mounted() {
		if(this.orderItem){
			this.tradeType = this.orderItem.tradeType
			this.isUpHangOrder = this.orderItem.tradeType >0 && (this.orderItem.status || this.orderItem.status==0)
		}
	},
	methods: {
		closeSubmit(){
			this.$emit('closeUpdateBack',true)
		},
		updateOrderBack(data){
			this.$emit('updateOrderBack',data)
		},
		updateHangOrderBack(data){
			this.$emit('updateHangOrderBack',data)
		}
	}
}
</script>

<style lang="scss" scoped>
	
	
	.top-back{
		display: flex;
		padding: 16px;
		border-bottom: 1px solid #ECEFFB;
		justify-content: space-between;
		align-items: center;
		.trade-type{
			width: 234px;
			text-align: left;
			.symbol-name{
				font-size: 16px;
				font-weight: 700;
				color: #000;
			}
		}
		
		.close-back{
			box-sizing: border-box;
			cursor: pointer;
			width: 48px;
			height: 48px;
			border: 1px solid #ECEFFB;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			
			.close-img{
				width: 12px;
				height: 12px;
			}
		}
	}
</style>
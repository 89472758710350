<template>
  <div class="search-list">
    <div class="classify-main">
        <div v-if="typeShow && typeFlag" class="classify-list">
            <div class="classify-item" v-for="(item,index) in classifys" :key="index" @click="classifysHandle(item)">
                <span class="classify-text">{{ item }}</span>
                <img src="@/assets/arrow.png" alt="" class="classify-arrow">
            </div>
        </div>
        <MarketType v-if="typeShow && !typeFlag" :allOrderSymbol="allOrderSymbol" :allList="allList" :typeItem="typeItem" :newCollectList="newCollectList" @goBack="goBack" @goChartHandle="goChart" @addSymbolHandle="addSymbol" @delSymbolHandle="delSymbol"></MarketType>
        <div class="symbol-list">
            <div class="symbol-item" v-for="item in searchList" :key="item" @click="goChart(item)">
                <span class="symbol-text">{{ item }}</span>
                <div class="symbol-set" @click.stop="addSymbol(item)" v-if="newCollectList.indexOf(item) == -1">
                    <img src="@/assets/add.png" alt="" class="symbol-add">
                </div>
                <div class="symbol-set" @click.stop="delSymbol(item)" v-else-if="newCollectList.indexOf(item) > -1 && allOrderSymbol.indexOf(item) == -1 && newCollectList.length != 1">
                    <img src="@/assets/del.png" alt="" class="symbol-del">
                </div>
                <div class="symbol-set" v-else @click.stop="(e)=>{return false}">
                    <img src="@/assets/select_disabled.png" alt="" class="symbol-del">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import MarketType from '@/components/market/MarketType.vue'
import { upUserData } from "@/utils/userUtils"
export default {
    name:'MarketSearch',
    components: {
        MarketType
    },
    data() {
        return {
            typeFlag:true,
            classifys:[], 
            newCollectList:[],
            timer:null, // 设置定时器的句柄，用来缓存的
            typeItem:'',
        }
    },
    methods:{ 
        //节流函数 delay:延时多少执行 duration:间隔的时间
        fnThrottle (method, delay, duration) {
            var that = this;
            var timer = this.timer;
            var begin = new Date().getTime();
            return function(){
                var context = that;
                var args = arguments;
                var current = new Date().getTime();
                clearTimeout(timer);
                if(current-begin>=duration){
                    method.apply(context,args);
                    begin=current;
                }else{
                    that.timer=setTimeout(function(){
                        method.apply(context,args);
                    },delay);
                }
            }
        },
        updateCollect() {
            let newUserInfo = JSON.parse(JSON.stringify(this.userInfo));
            newUserInfo.collectList = JSON.parse(JSON.stringify(this.newCollectList));
            this.$store.commit("upUserInfo",newUserInfo); //更新vuex
            upUserData(this,newUserInfo); //更新数据库
            this.$store.commit('setCollectList', JSON.parse(JSON.stringify(this.newCollectList))); //通知首页收藏列表发生变更
            // console.log(this.newCollectList,'更新缓存');
        },  
        getClassifys(){
            let classifys = []
            this.allList.forEach(e=>{
                if(!classifys.includes(e.tradeType)){
                    classifys.push(e.tradeType)
                }
            })
            this.classifys = classifys;
        },
        classifysHandle(type) {
            this.typeItem = type;
            this.typeFlag = false;
        },
        goBack(flag) {
            this.typeItem = '';
            this.typeFlag = flag;
        },
        goChart(item) {
            let tradeItem = this.allList.find(val=>{
                return val.symbol == item;
            });
            this.$emit('goChart',tradeItem);
        },
        addSymbol(item){
            if(!this.userInfo) {
                this.$emit('goLogin',true);
                return false;
            }
            if(this.newCollectList.indexOf(item) > -1) {
                return false;
            }
            this.newCollectList.push(item);
            this.fnThrottle(this.updateCollect, 1500, 1500)()
        },
        delSymbol(item) {
            if(!this.userInfo) {
                this.$emit('goLogin',true);
                return false;
            }
            if(this.newCollectList.indexOf(item) == -1) {
                return false;
            }
            if(this.newCollectList.length > 1) {
                this.newCollectList.splice(this.newCollectList.indexOf(item),1);
            }
            this.fnThrottle(this.updateCollect, 1500, 1500)()
        }
    },
    watch:{
        keyword(v){
            if(this.typeFlag) {return false}
            this.typeItem = '';
            this.typeFlag = true;
        }
    },
    computed: { 
        searchList() {
            let result = [];
            if(!this.keyword) {
                return result;
            }
            let tempList = this.allList.map(item=>{return item.symbol})
            result = tempList.filter(item=>{
               return item.toUpperCase().indexOf(this.keyword.toUpperCase()) > -1
            });
            return result;
        },
        typeShow(){
            let flag = true;
            if(this.keyword) {
                flag = false;
            }
            return flag;
        }
    },
    props: {
        allList:{
            type: Array,
            default: ()=>[]  
        },
        keyword:{
            type: String,
            default: "" 
        },
        setHeight:{
            type: Number,
            default: 550  
        },
        collectList:{
            type: Array,
            default: ()=>[]  
        },
        allOrderSymbol: {
            type: Array,
            default: ()=>[]
        }
    },
    mounted() {
       this.newCollectList = this.collectList; //获取用户缓存的收藏列表
       this.getClassifys();
    }
}
</script>

<style lang="scss" scoped>
.search-list {
    overflow: auto;
    height: 550px;
}
.classify-main {
    .classify-list {
        padding: 0 16px;
    }
    .classify-item {
        height: 48px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 1px solid #ECEFFB;
        cursor: pointer;
        .classify-text {
            font-size: 14px;
            color: #000000;
            font-weight: bold;
        }
        .classify-arrow {
            width: 18px;
            height: 18px;
        }
    }
}
.symbol-list {
    margin-top: 8px;
    padding: 0 16px;
    .symbol-item {
        display: flex;
        align-items: center;
        height: 48px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 8px;
        border-bottom: 1px solid #EBF1FF;
        cursor: default;
        .symbol-text {
            font-size: 14px;
            color: #111214;
        }
        .symbol-set {
            width: 36px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
        }
        .symbol-add {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .symbol-del {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        &:last-child {
            margin-bottom:0;
        }
        // &:hover {
        //     background-color: #F2F8FF;
        // }
    }
}
</style>
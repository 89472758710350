import { http } from "@/service/service.js";
import { appUrl } from "@/utils/appUrls.js"

let serviceName = "orderHang/"

const orderHang = {
	//新增挂单
	openHangOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "openHangOrder"
	    return http.post(url,data);
	},
	//挂单订单列表
	getHangOrderList(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "listFrontPage"
	    return http.post(url,data);
	},
	//挂单还原
	orderRestored(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "orderRestored"
	    return http.post(url,data);
	},
	//持仓订单修改
	updateHangOrder(data) {
	    let url = appUrl.getBaseUrl() + serviceName + "updateHangOrder"
	    return http.post(url,data);
	}
}

export default orderHang;

<!-- 进步器（手数） -->
<template>
	<el-input-number class="number-back" v-model="volume" @change="volumeChange" :step="0.01" :min="minVolume"
		:max="maxVolume"></el-input-number>
</template>

<script>
export default {
	data() {
		return {
			volume: 0.01,
			minVolume: 0.01,
			maxVolume: 1000,
		}
	},
	props: {
		data: {
			type: Object,
			default: {}
		},
		disable: {
			type: Boolean,
			default: false
		},
		value: {
			type: Number,
			default: 0
		}
	},
	watch: {

	},
	mounted() {
		this.minVolume = this.data.minVolume
		this.volume = this.data.minVolume
		this.maxVolume = this.data.maxVolume
	},
	methods: {
		//获取手数
		getValue() {
			return this.volume
		},
		//输入框更新手数
		volumeChange(val) {
			if (!val) {
				this.volume = this.minVolume
			} else {
				if (val < this.minVolume) {
					val = this.minVolume
				}
				if (val > this.maxVolume) {
					val = this.maxVolume
				}
				this.volume = val
			}

		},
		//+ - 改变
		changeVolume(num) {
			this.volume = parseFloat(parseFloat(this.volume) + num)
			this.volume = this.volume.toFixed(2)
		},
	}
}
</script>

<style lang="scss" scoped>
.volume-box {
	.input-volume {
		width: 40px;
	}
}
</style>